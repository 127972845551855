import React from "react";
import { LoadingDiamonds } from "../../commonComponents";
/*
ernest
*/
const LoadableComp = ({
  loading,
  reRender = true,
  children,
  LoaderComp = LoadingDiamonds,
  style = {},
  className = "",
  componentTestId = "",
}) =>
  reRender ? (
    loading ? (
      <LoaderComp {...{ style, className, componentTestId }} />
    ) : (
      children
    )
  ) : (
    <>
      {loading && (
        <LoaderComp absolute {...{ style, className, componentTestId }} />
      )}
      {children}
    </>
  );

export default LoadableComp;
