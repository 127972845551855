function parseRowData({ actualTripsForTruck, fleet }) {
  let tmpRows = [];

  for (const fleetId in actualTripsForTruck) {
    let vehicle = fleet?.find(({ fleetId: f }) => f === fleetId);
    if (!vehicle) {
      continue;
    }

    for (const activity of actualTripsForTruck[fleetId]["truckActivities"]) {
      let fenceExit = activity[0];
      let fenceEnter = activity[activity?.length - 1];

      tmpRows.push({
        fleetName: vehicle?.["fleetName"],
        fleetId: vehicle?.["fleetId"],
        vinNumber: vehicle?.["vinNumber"],
        fenceExit: fenceExit?.["fenceName"],
        fenceEnter: fenceEnter?.["fenceName"],
        exitTime: fenceExit?.["alertDateTime"],
        enterTime: fenceEnter?.["alertDateTime"],
      });
    }
  }

  tmpRows.sort(
    (a, b) =>
      a?.fleetName?.toLowerCase()?.localeCompare(b?.fleetName?.toLowerCase()) ||
      a?.exitTime - b?.exitTime
  );
  return tmpRows;
}

export default parseRowData;
