import { Modal } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  fetchAllData,
  filterTables,
} from "../../../../../../../../../../../../../../utils";
import { useRedux } from "../../../../../../../../../../../../hooks";
import { Space, Table, Tag } from "antd";
const { Column, ColumnGroup } = Table;
import "./ProgressType.scss";
function ProgressType({
  elevationData = "",
  params = "",
  selectedService = "",
}) {
  const [open, setOpen] = useState(true);
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [tableData, setTableData] = useState([]);

  console.log("ProgressType", fakeRentals);

  const getProgressItems = async () => {
    await filterTables(
      "progressItems",
      "projectId",
      fakeRentals.projectId
    ).then((res) => {
      let tempData = res
        .filter(
          (item) =>
            item.elevationId === elevationData.elevationId &&
            item.serviceName === selectedService &&
            item?.estimationId === fakeRentals?.estimationId
        )
        .sort((a, b) => a.pli - b.pli);
      setTableData(tempData);
      console.log("tempData", tempData);
      console.log("progressItems", res);
    });
  };

  useEffect(() => {
    getProgressItems();
  }, [fakeRentals]);

  return (
    <Modal
      className="dark-mode-progress-type"
      title="Progress Info From Scheduling"
      open={open}
      onCancel={() => setOpen(false)}
      onOk={() => setOpen(false)}
      width={1900}
      footer={null}
    >
      <Table dataSource={tableData}>
        <ColumnGroup title={selectedService}>
          <Column title="Elevation" dataIndex="elevation" key="elevation" />
          <Column title="PLI" dataIndex="pli" key="pli" />
        </ColumnGroup>
        <Column
          title="Schedule Name"
          dataIndex="scheduleName"
          key="scheduleName"
        />
        {/* <Column
            title="Type of Work"
            dataIndex="typeOfWork"
            key="typeOfWork"
          /> */}
        <Column
          title="PLI Progress Percentage"
          dataIndex="pliProgressPercentage"
          key="pliProgressPercentage"
          render={(text) => {
            return <Tag color="blue">{text}%</Tag>;
          }}
        />
        <Column
          title="Type of Work"
          dataIndex="typeOfWork"
          key="typeOfWork"
          render={(tag) => {
            let color = tag === "Installation" ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          }}
        />
      </Table>
    </Modal>
  );
}

export default ProgressType;
