import { message } from "antd";
import {
  addDrafts,
  deleteDraftById,
  updateDraftById,
  updateDrafts,
} from "../../../../../../actions/communicationActions";
import { callGmailAPI, getLength, updateTable } from "../../../functions";
import { lazyFetch } from "../../../../../../utils";
import { uniqBy } from "lodash";
import { blobToBase64 } from "../../../../utils";
import { updateDraftsInTable } from "./save-drafts-to-record-helpers";
import { Buffer } from "buffer";

export const saveAsDraft = async (
  form,
  draftId,
  email,
  editorContent,
  selectedEmailTemplate,
  authData,
  signature,
  uploadedFiles,
  setDraftId,
  dispatch,
  drafts
) => {
  const emailToSend = {
    ...form.getFieldValue(),
    draftId,
    from: email,
    body: editorContent,
    snippet: editorContent,
    category: window.location.pathname,
    template: selectedEmailTemplate?.id,
    authData,
    signature,
    attachments: uploadedFiles,
  };

  const response = await callGmailAPI("saveAsDraft", emailToSend);

  if (response) {
    setDraftId(response.data.draftId);
    const emailExists = drafts?.some(
      (draft) => draft.draftId === response.data.draftId
    );

    if (emailExists) {
      dispatch(updateDraftById(response.data));
    } else if (drafts.length !== 0) {
      dispatch(updateDrafts(response.data));
    } else {
      dispatch(addDrafts(response.data));
    }

    return response;
  }
};

export const removeDraft = (
  draftId,
  authData,
  rowData,
  recordDetails,
  recordID,
  dispatch,
  onClose
) => {
  callGmailAPI("removeDraft", {
    id: draftId,
    authData,
  }).then(async (e) => {
    if (e.data) {
      const {
        status,
        data: { id },
      } = e;
      let drafts;
      if (Array.isArray(rowData)) {
        drafts = rowData.find((obj) => obj.hasOwnProperty("drafts"));
      } else {
        drafts = rowData?.drafts;
      }
      status === 200 &&
        updateTable(
          recordDetails?.categoryType === "permitdrawings"
            ? "permitDrawings"
            : recordDetails?.categoryType,
          recordID || recordDetails?.recordId,
          "drafts",
          drafts?.filter(({ draftId: id }) => id !== draftId)
        ).then(() => {
          dispatch(deleteDraftById(draftId));
          message.info("Draft deleted");
          onClose(true);
        });
    } else {
      message.warning("An error occurred during communication");
    }
  });
};

export const onHeaderClick = (e, setMaximaze, setFullSize) => {
  e.stopPropagation();
  setMaximaze((prevState) => !prevState);
  setFullSize(false);
  return false;
};

export const fetchRolesForId = async (id, type) => {
  const filterKey = type === "project" ? "accountId" : "leadId";
  const filterValue = type === "project" ? id : "";

  const contacts = await lazyFetch({
    tableName: "contacts",
    listOfKeys: ["contactRole", "contactEmail"],
    filterKey,
    filterValue,
  });

  return contacts.map(({ contactRole, contactEmail }) => ({
    role: contactRole,
    email: contactEmail,
  }));
};

export const getRoles = async (rowData) => {
  let roleslist = [];

  if (rowData?.accountId || rowData?.leadId) {
    const type = rowData?.accountId ? "account" : "lead";
    const id = rowData?.accountId || rowData?.leadId;
    roleslist = await fetchRolesForId(id, type);
  } else if (rowData?.projectId) {
    const projects = await lazyFetch({
      tableName: "projects",
      listOfKeys: ["accountId"],
      filterKey: "projectId",
      filterValue: rowData.projectId,
    });

    if (projects.length > 0) {
      const project = projects[0];
      roleslist = await fetchRolesForId(project.accountId, "project");
    }
  }

  return roleslist;
};

export const getEmailsOfRoles = async (rolesList, programFields, rowData) => {
  try {
    let emailsList = [];

    const leadRolesField = programFields.find(
      ({ fieldName }) => fieldName === "Lead Roles"
    );

    if (!leadRolesField) {
      throw new Error("Lead Roles field not found.");
    }

    const leadRoles = leadRolesField.fieldOptions;

    for (const role of rolesList) {
      const roleInLeadRoles = leadRoles.includes(role);

      if (roleInLeadRoles) {
        const filterKey = rowData?.accountId === "" ? "leadId" : "accountId";
        const filterValue =
          rowData?.accountId === "" ? rowData?.leadId : rowData?.accountId;

        const contacts = await lazyFetch({
          tableName: "contacts",
          listOfKeys: ["contactRole", "accountId", "leadId", "contactEmail"],
          filterKey,
          filterValue,
        });

        contacts.forEach(({ contactRole, contactEmail }) => {
          if (contactRole.includes(role)) {
            emailsList.push(contactEmail);
          }
        });
      } else {
        emailsList.push(role);
      }
    }

    return emailsList;
  } catch (error) {
    console.error("Error in getEmailsOfRoles:", error);
    return []; // Return empty array in case of error
  }
};

export const getDraft = async (selectedDraft, authData) => {
  try {
    const response = await callGmailAPI("getDraft", {
      id: selectedDraft,
      authData,
    });

    return response;
  } catch (error) {
    console.error("Error fetching draft:", error);
    throw error; // You can handle the error further if needed
  }
};

const handleDraftGeneralInformation = (draft, fieldName) => {
  return draft[fieldName]?.split(",")?.length === 1 &&
    draft[fieldName]?.split(",").includes("")
    ? []
    : draft[fieldName]?.split(",");
};

export const handleSetFormFields = (form, draft) => {
  const { subject } = draft;
  form.setFieldsValue({
    to: handleDraftGeneralInformation(draft, "to"),
    cc: handleDraftGeneralInformation(draft, "cc"),
    bcc: handleDraftGeneralInformation(draft, "bcc"),
    subject: subject,
  });
};

export const handleEditorContent = (
  setEditorContent,
  setSelectedEmailTemplate,
  body,
  template
) => {
  setEditorContent(body);

  if (template) {
    setSelectedEmailTemplate({ id: template, customBody: body });
  } else {
    setEditorContent(body);
  }
};

export const fetchSignature = (authData, setSignatureState) => {
  callGmailAPI("getSignature", { authData }).then((res) => {
    if (res && res.data) {
      const sendAsObj = res.data[0];
      setSignatureState(sendAsObj.signature);
    } else {
      message.warning("An error occured when getting Signature");
    }
  });
};

export const getUndoTimerValue = (
  authToken,
  userConfiguration,
  coreStorageSignature
) => {
  return (
    (authToken
      ? userConfiguration?.undoTimer
      : coreStorageSignature.undoTimer) || 5
  );
};

export const getDriveItems = async (folderId, folderName, driveRequest) => {
  const res = await driveRequest.getFilesByFolderId(folderId);
  const data = await res.json();

  if (data?.error) {
    return null;
  }

  const itemsList = [];
  const uniqueItems = new Set();

  for (const { mimeType, id, name } of data?.files) {
    if (mimeType === "application/vnd.google-apps.folder") {
      const folderItems = await getDriveItems(id, name, driveRequest);
      itemsList.push(...folderItems);
    } else {
      const item = {
        folder: folderName,
        folderId,
        id,
        name,
        type: "Item",
      };

      if (!uniqueItems.has(id)) {
        itemsList.push(item);
        uniqueItems.add(id);

        const blobResponse = await driveRequest.getDriveItem(id, {
          alt: "media",
        });
        const blobData = await blobResponse.blob();
        const blob = await blobToBase64(blobData);

        itemsList.push({
          name: item.name,
          blob,
          size: Buffer.from(blob.substring(blob.indexOf(",") + 1))?.length,
          type: blobData.type,
          id: item.id,
          status: 400,
        });
      }
    }
  }

  return itemsList.filter(({ blob }) => blob);
};

// functions to get documents items

export const getDocItems = async (attachmentsToFetch, driveRequest) => {
  let finalElements = await fetchBlobData(attachmentsToFetch, driveRequest);

  return finalElements;
};

export const fetchDocuments = async (docFolders, allDocTypes, driveRequest) => {
  let documents = [];
  let unexistDocs = [];

  for await (let parent of docFolders) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    let items = [];

    if (allDocTypes.find(({ docType }) => docType === parent)) {
      await getDriveItems(
        allDocTypes.find(({ docType }) => docType === parent).folderId,
        parent,
        items,
        driveRequest
      ).then((list) => delay(1300).then(() => Promise.all(list)));

      documents.push(items);
    } else {
      unexistDocs.push(parent);
    }
  }

  return { documents, unexistDocs };
};

export const filterAndSetSelectedItems = (docItems, documents) => {
  docItems.forEach((e) => {
    if (
      uniqBy(documents.flat(1), "id")
        .filter(({ type }) => type === "Item")
        .find(({ id }) => e.id === id)
    ) {
      e.selected = true;
    } else {
      e.selected = false;
    }
  });
};

export const fetchBlobData = async (elements, driveRequest) => {
  return Promise.all(
    elements.map(async (el) => {
      const { id, name } = el;
      let blob;
      return driveRequest.getDriveItem(id, { alt: "media" }).then((r) =>
        r.blob().then(async (e) => {
          const { type } = e;
          await blobToBase64(e).then((res) => (blob = res));
          return {
            name,
            blob,
            size: Buffer.from(blob.substring(blob.indexOf(",") + 1)).length,
            type,
            id,
            status: 400,
          };
        })
      );
    })
  );
};

export const handleSetSelectedDocTypes = (
  finalElements,
  setSelectedDocTypes
) => {
  setSelectedDocTypes(finalElements.filter(({ type }) => type !== "Folder"));
};

export const saveDraftsToRecord = async (drafts, recordName, recordId) => {
  try {
    await updateDraftsInTable(recordName, recordId, drafts);
  } catch (error) {
    console.error(error);
    message.error(error);
  }
};

export function formatTitle(title) {
  let words = title.match(/[A-Za-z][a-z]*/g);

  // Add null check for words
  if (!words) {
    return ""; // Return empty string if no words found
  }

  let formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  let formattedTitle = formattedWords.join(" ");

  return formattedTitle;
}

export const getTemplate = (emailTemplates, selectedEmailTemplate) => {
  if (!emailTemplates || !selectedEmailTemplate) return null;

  return emailTemplates?.find(
    ({ templateId }) => templateId === selectedEmailTemplate?.id
  );
};

export const getUploadedFiles = async (
  templateAttachments,
  isRedirected,
  driveRequest
) => {
  try {
    const staticDocuments = await Promise.all(
      [...templateAttachments, ...(isRedirected || [])].map(async (el) => {
        try {
          const r = await driveRequest.getDriveItem(el, {});
          const e = await r.json();
          const { id, mimeType, name } = e;

          // Validate the API response
          if (!id || !mimeType || !name) {
            console.error(`Invalid document data for ${el}:`, e);
            return null;
          }

          return {
            id,
            uid: id,
            name,
            type: mimeType,
            status: 400,
          };
        } catch (error) {
          console.error(`Failed to get drive item for ${el}:`, error);
          return null;
        }
      })
    );

    const filteredDocuments = staticDocuments.filter(
      (doc) => doc !== null && doc.id && doc.name
    );

    if (filteredDocuments.length > 0) {
      const uploadedFilesResult = await Promise.all(
        filteredDocuments.map(async (e) => {
          try {
            const r = await driveRequest.getDriveItem(e.id, { alt: "media" });
            if (!r.ok) {
              throw new Error(`Failed to fetch media for item ${e.id}`);
            }
            const el = await r.blob();
            const blob = await blobToBase64(el);
            return {
              ...e,
              blob,
              size: getLength(
                Buffer.from(blob?.substring(blob?.indexOf(",") + 1))
              ),
            };
          } catch (error) {
            console.error(`Failed to process file ${e.id}:`, error);
            return null;
          }
        })
      );

      return uploadedFilesResult.filter((file) => file !== null);
    }

    return [];
  } catch (error) {
    console.error("Failed to get uploaded files:", error);
    return [];
  }
};

export const processTemplateRoles = async (
  templateRoles,
  programFields,
  rowData,
  form
) => {
  const rolesToUpdate = ["to", "cc", "bcc"];
  await Promise.all(
    rolesToUpdate.map(async (role) => {
      if (templateRoles[role]) {
        const res = await getEmailsOfRoles(
          templateRoles[role],
          programFields,
          rowData
        );
        form.setFieldValue(role, res);
      } else {
        templateRoles[role] = null;
      }
    })
  );
};
