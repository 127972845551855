import { Checkbox, Button } from "antd";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

function noSelection(text) {
  return <span style={{ color: "#ff4d4f" }}>{text}</span>;
}

const columnDefs = ({ selectedRoute, onChange, allRoutes, requestData }) => [
  {
    headerName: "",
    minWidth: allRoutes ? 180 : 50,
    maxWidth: allRoutes ? 180 : 50,
    cellStyle: { justifyContent: "center" },
    cellRenderer({ data, node }) {
      let disabled =
        !!selectedRoute && selectedRoute?.activityId !== data?.activityId;
      let checked =
        !!selectedRoute && selectedRoute?.activityId === data?.activityId;
      if (!allRoutes) {
        return (
          <Checkbox
            {...{
              disabled,
              checked,
              ["data-testid"]: `request-check-${node?.rowIndex}`,
              onChange(checked) {
                onChange(checked.target.checked, data);
              },
            }}
          />
        );
      } else {
        return (
          <Button
            type="primary"
            data-testid={`switch-route-confirm-btn-${node?.rowIndex}`}
            disabled={disabled}
            onClick={() => {
              if (!!selectedRoute) {
                onChange(false, data);
              } else {
                onChange(true, data);
              }
            }}
            style={{
              backgroundColor: disabled
                ? "#F5F5F5"
                : checked
                ? "#F34213"
                : "#71CF48",
              borderRadius: "5px",
            }}
          >
            {checked ? "Remove Route" : "Add Route After"}
          </Button>
        );
      }
    },
  },
  {
    headerName: "Route Name",
    field: "routeName",
    hide: requestData,
  },
  {
    headerName: "Pickup",
    field: "pickupLocation",
    hide: requestData,
  },
  {
    headerName: "Drop Off",
    field: "dropOffLocation",
  },
  {
    headerName: "Cargo",
    field: "cargoStatus",
    hide: !requestData,
  },
  {
    headerName: "Priority",
    field: "priority",
    hide: !requestData,
    cellStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
    },
    cellRenderer({ data }) {
      return (
        <div className="dispatchPriorityCell">
          <span className={data?.priority || "Low"}>
            {data?.priority?.toUpperCase() || "LOW"}
          </span>
        </div>
      );
    },
  },
  {
    headerName: "Truck",
    field: "fleetName",
    cellRenderer({ data }) {
      if (!data?.fleetName) {
        return noSelection("No Selected Vehicle");
      } else {
        return data?.fleetName;
      }
    },
  },
  {
    headerName: "Driver",
    field: "driverName",
    hide: !requestData,
    cellRenderer({ data }) {
      if (!data?.driverName) {
        return noSelection("No Selected Driver");
      } else {
        return data?.driverName;
      }
    },
  },
  {
    headerName: "Depart",
    field: "firstExit",
    hide: requestData,
    cellRenderer({ data }) {
      if (!!data?.firstExit) {
        return dayjsNY(data?.firstExit).format("hh:mm a");
      } else {
        return noSelection("No Time Selected");
      }
    },
    sort: "asc",
  },
  {
    headerName: "Arrive",
    field: "lastEntry",
    cellRenderer({ data }) {
      if (!!data?.lastEntry) {
        return dayjsNY(data?.lastEntry).format("hh:mm a");
      } else {
        return noSelection("No Time Selected");
      }
    },
  },
  {
    headerName: "Notes",
    field: "routeNotes",
    hide: !requestData,
  },
];

export default columnDefs;
