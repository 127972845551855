import languageTable from "./languageTable";

function translateSentenceFn(sentence, lang, splitter = " ") {
  const words = sentence.split(splitter);

  const translatedWords = words.map((word) => {
    if (word.includes("-")) {
      return translateSentenceFn(word, lang, "-");
    }
    if (languageTable[lang] && languageTable[lang][word]) {
      return languageTable[lang][word];
    }
    return word;
  });

  const translatedSentence = translatedWords.join(splitter);

  return translatedSentence;
}

export default translateSentenceFn;
