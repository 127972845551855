import React from "react";
import styles from "./ServiceGroupItem.module.scss";
import EyeCheckBox from "../EyeCheckBox/EyeCheckBox";

const ServiceGroupItem = ({
  index,
  label,
  length,
  subLabel = "",
  onClick,
  checked,
}) => {
  return (
    <div
      className={styles.serviceGroupItem}
      onClick={onClick}
      data-testid={`group-item-${index}`}
    >
      <EyeCheckBox checked={checked} />
      <div className={styles.serviceContainer}>
        <span className={styles.groupItemLabel}>{label}</span>
        <span
          className={styles.groupItemSubLabel}
          data-testid="selected-items-count"
        >
          {length} item{length > 1 ? "s" : ""} selected
        </span>
        <span
          className={styles.groupItemSubLabel}
          data-testid="selected-items-total"
        >
          {subLabel}
        </span>
      </div>
    </div>
  );
};

export default ServiceGroupItem;
