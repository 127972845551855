import {
  setApplicationHandler,
  cellEditingStarted,
} from "./serviceContentFunctions";
import {
  useStateContext,
  useDispatchContext,
} from "../../../../Context/Context";
import { defaultColDef, columnDefs, frameworkComponents } from "../../GridData";
import { getRowNodeId, onCellKeyPress } from "../../GridData/GridUtils";
import { cellValueChanged } from "./GridData/GridUtils";
import "./service-content.scss";
import "../../../../../../../../../../components/SidebarPages/Documentation/DocumentationPage.scss";

import { AgGridReact } from "ag-grid-react";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import AgGridMathful from "./AgGridSum/AgGridMathful";
import { useEffect, useRef, useState } from "react";

const ServiceContent = ({ service, color }) => {
  const { isLoading } = useSelector((state) => state.applications);
  const [selected, setSelected] = useState(false); // Context state & dispatch
  const { fakeApplication, isWritable, darkMode } = useStateContext();
  const dispatchContext = useDispatchContext();
  const gridApi = useRef(null);

  ///this saves the new application object on state context when is called
  const setApplication = (newApplication) =>
    setApplicationHandler({ newApplication, dispatchContext });

  //change the class name dynamically based on the darkMode
  const dynamicStyle = darkMode
    ? "dark-ag-theme documentationsTable"
    : "grey-ag-theme documentationsTable";

  //clone rowData so it will not be the same instance
  const rowData = cloneDeep(service?.amounts);

  const onRangeSelectionChanged = (event) => {
    // console.log("hoist selection changed", event);
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };

  const fieldChanger = (data) => {
    console.log("my data", data);
  };

  return (
    // <ResizableBox
    //   className="applicationsBodyResizable"
    //   minConstraints={[0, 0]}
    //   maxConstraints={[0, 1500]}
    //   height={270}
    //   width={1800}
    //   axis="y"
    //   handle={
    //     <div className="applicationBodyResizableGripContainer">
    //       <div className="leftSize" style={{ background: color }} />
    //       <div className="grabberContainer" style={{ background: color }}>
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //         <div className="gripLines" />
    //       </div>
    //       <div className="rightSize" style={{ background: color }} />
    //     </div>
    //   }
    // >
    <div
      className={`applicationAgGridContainer ${dynamicStyle} ag-theme-alpine`}
    >
      {selected && <AgGridMathful darkMode={darkMode} event={selected} />}
      <AgGridReact
        ref={gridApi}
        immutableData={true}
        domLayout="autoHeight"
        headerHeight={90}
        undoRedoCellEditing={true}
        enableRangeSelection={true}
        rowSelection="single"
        enableCellChangeFlash={true}
        undoRedoCellEditingLimit={50}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs({
          service,
          color,
          fieldChanger,
        })}
        rowClassRules={{
          "ag-grid-this-period-req-row": (params) => params.data.thisPeriod > 0,
          "ag-grid-has-previous-req-row": (params) =>
            params.data.fromPrevious > 0,
        }}
        frameworkComponents={frameworkComponents}
        getRowNodeId={getRowNodeId}
        onCellKeyPress={onCellKeyPress}
        stopEditingWhenGridLosesFocus={true}
        suppressDragLeaveHidesColumns={true}
        allowDragFromColumnsToolPanel={true}
        onRangeSelectionChanged={onRangeSelectionChanged}
        onCellEditingStarted={(params) =>
          cellEditingStarted({ params, isWritable, isLoading })
        }
        onCellValueChanged={(params) => {
          cellValueChanged(fakeApplication, setApplication, service, params);
        }}
        onFirstDataRendered={(params) => {
          let columnApi = params.columnApi;
          const allColumnIds = [];

          columnApi.getColumns()?.forEach((column) => {
            const colId = column?.getId();
            allColumnIds.push(colId);
          });
          try {
            columnApi.autoSizeColumns(allColumnIds, false);
          } catch (e) {
            console.log(e);
          }
        }}
        // onComponentStateChanged={(params) => {
        //   let columnApi = params.columnApi;
        //   const allColumnIds = [];

        //   columnApi.getColumns()?.forEach((column) => {
        //     const colId = column?.getId();
        //     allColumnIds.push(colId);
        //   });
        //   try {
        //     columnApi.autoSizeColumns(allColumnIds, false);
        //   } catch (e) {
        //     console.log(e);
        //   }
        // }}
      />
    </div>
    // </ResizableBox>
  );
};

export default ServiceContent;
