import { message } from "antd";
import dayjs from "dayjs";
import { LogoutIcon } from "../../../../../../../pages/Settings/settingsComponents/AllUsers/assets";

const columnDefs = ({ logoutUserSession, editable }) => [
  {
    headerName: "OS",
    field: "os",
    valueGetter: ({ data }) => `${data?.osName} ${data?.osVersion}`,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Browser",
    field: "browser",
    valueGetter: ({ data }) => `${data?.browserName} ${data?.browserVersion}`,
    filter: "agTextColumnFilter",
  },
  {
    headerName: "IPv4",
    field: "ipv4",
    filter: "agTextColumnFilter",
  },
  {
    headerName: "Logged In",
    field: "loggedIn",
    cellRenderer: ({ value }) => dayjs(value).format("MM/DD/YYYY - hh:mm a"),
    filter: "agTextColumnFilter",
    minWidth: 170,
  },
  {
    headerName: "Logout Status",
    field: "logout",
    cellRenderer: ({ value }) => (!!value ? "Waiting to logout..." : ""),
  },
  {
    headerName: "Action",
    cellRendererFramework({ data }) {
      return (
        <div
          className="cardLogoutActionButton"
          onClick={() => {
            if (!editable) {
              message.warning(
                "You don't have access to make actions on this card"
              );
            } else {
              logoutUserSession(data);
            }
          }}
        >
          <LogoutIcon height={13} width={13} />
        </div>
      );
    },
    cellStyle: { display: "grid", placeItems: "center" },
  },
];

export default columnDefs;
