import { Radio } from "antd";
import { yesNoOptions } from "../../helpers/constants";

export const generateVehicleDamageInputFields = ({ drivers }) => [
  {
    label: "Incident Address",
    onContextMenu: () => false,
    name: "incidentAddress",
    type: "placesautocomplete",
    placeholder: "Incident Address...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Reported By",
    onContextMenu: () => false,
    name: "incidentReportedBy",
    type: "text",
    placeholder: "Reported By...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Date",
    onContextMenu: () => false,
    name: "incidentDate",
    type: "datepicker",
    placeholder: "Date...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Time",
    onContextMenu: () => false,
    name: "incidentTime",
    type: "timepicker",
    placeholder: "Time...",
    className: "inputForm",
    required: true,
  },
  {
    label: "Driver",
    onContextMenu: () => false,
    name: "incidentDriver",
    type: "select",
    placeholder: "Driver...",
    className: "inputForm",
    required: true,
    customOptions: drivers,
  },
  {
    label: "Was there any injured person",
    onContextMenu: () => false,
    name: "incidentInjuredPerson",
    type: "radio",
    placeholder: "Injured Person...",
    className: "inputForm",
    required: true,
    options: (
      <>
        {yesNoOptions.map((option) => (
          <Radio value={option}>{option}</Radio>
        ))}
      </>
    ),
  },
  {
    label: "Was incident witnessed",
    onContextMenu: () => false,
    name: "incidentWitnessed",
    type: "radio",
    placeholder: "Incident witnessed...",
    className: "inputForm",
    required: true,
    options: (
      <>
        {yesNoOptions.map((option) => (
          <Radio value={option}>{option}</Radio>
        ))}
      </>
    ),
  },
];
