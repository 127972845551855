import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

//in this function we calculate the full amount applied on this rental
export const appliedAmountGenerator = ({ fakeRentals }) => {
  return (
    fakeRentals?.services
      // here we extract all services
      ?.flatMap(({ serviceOptions = [] }) =>
        serviceOptions?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ rentalDetails }) =>
            rentalDetails?.details?.flatMap(({ days }) =>
              days?.map(({ dailyAmount = 0 }) => dailyAmount)
            )
          )
        )
      )
      //here we add all extracted weekAmounts together
      ?.reduce((acc, curr) => acc + curr, 0) || 0
  );
};

/**
 * Function that calculates the total to be shown in the new charge grid.
 * Used when selecting a rental to be charged
 * @param {Object} fakeRentals
 */
const getChargeableTotalForRental = ({ fakeRentals }) => {
  return (
    fakeRentals?.services
      // here we extract all services
      ?.flatMap(({ serviceOptions }) =>
        serviceOptions?.flatMap(({ items }) =>
          // from the extracted services we extract all weeks and take the amount from those weeks
          items?.flatMap(({ applied, rentalDetails }) => {
            if (!!applied) {
              return rentalDetails?.details?.flatMap(({ days }) =>
                days?.map(({ dailyAmount = 0 }) => dailyAmount)
              );
            } else {
              return 0;
            }
          })
        )
      )
      //here we add all extracted weekAmounts together
      ?.reduce((acc, curr) => acc + curr, 0) || 0
  );
};

export const totalitiesTotalTaxGen = ({
  fakeRentals,
  forChargeDisplay = false,
}) => {
  //we differentiate the functions in order to display the total chargeable amount
  //for rentals in the new charge modal (we calculate only the applied items)
  let totalCalculator = !forChargeDisplay
    ? appliedAmountGenerator
    : getChargeableTotalForRental;
  console.log({ fakeRentals });
  let carriedProjectTax = fakeRentals?.taxRate || 0;
  let result = {
    totalTax: 0,
    totalPrice: totalCalculator({ fakeRentals }) || 0,
  };
  console.log({ carriedProjectTax });
  if (carriedProjectTax > 0) {
    result.totalTax = result.totalPrice * carriedProjectTax;
    result.totalPrice = result.totalPrice + result.totalTax;
  }
  result.retainageAmount = fakeRentals?.services?.reduce((acc, curr) => {
    let currentService = totalCalculator({
      fakeRentals: { ...fakeRentals, services: [curr] },
    });

    let tempRet = curr?.retainage || 0;
    if (tempRet > 0) {
      return acc + currentService * (tempRet / 100) || 0;
    } else return acc + 0;
  }, 0);

  if (carriedProjectTax > 0) {
    result.retainageAmount += result.retainageAmount * carriedProjectTax;
  }

  result.appliedAmount =
    forceToNumber(result?.totalPrice) - forceToNumber(result?.totalTax);

  console.log({ result });
  return result;
};
