import { notification } from "antd";
import { ExclamationCircleFilled, WarningFilled } from "@ant-design/icons";
import { InfoIcon } from "../../../../../Inspections/assets";
import { XIcon } from "../../../../../Communication/assets";

export function getErrorMessage(index = null) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <ExclamationCircleFilled style={{ color: "red" }} />{" "}
      <span style={{ color: "red", fontWeight: "550" }}>
        {`Error on ${index === null ? "return route" : `route ${index + 1}`}`}
      </span>
    </div>
  );
}

export function getInfoMessage(title) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <InfoIcon /> <span style={{ fontWeight: "550" }}>{title}</span>
    </div>
  );
}

export function getCloseIcon(handler = () => {}) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handler}
      data-testid="dispatch-notification-decline"
    >
      <XIcon height={10} width={10} fill="black" />
    </div>
  );
}

/**
 * Function that displays the "Click here" phrase in the notifications
 * @param {Function} handler The click handler
 * @param {String | undefined} customText What to put after "Click here", defaults to "if you want to proceed"
 */
export function getClickPhrase({
  handler = () => {},
  text = "if you want to proceed",
}) {
  return (
    <strong>
      Click&nbsp;
      <span
        style={{ color: "#1065A4", cursor: "pointer" }}
        onClick={handler}
        data-testid="dispatch-notification-confirm"
      >
        here&nbsp;
      </span>
      {text}
    </strong>
  );
}

export const warningNotificationTitle = (index, generalWarning = false) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "1rem",
    }}
  >
    <WarningFilled style={{ color: "#fad753" }} />
    <span style={{ color: "#B18E0E", fontWeight: "550" }}>
      Warning{" "}
      {generalWarning
        ? ""
        : `on ${index === null ? "return route" : `route ${index + 1}`}`}
    </span>
  </div>
);

/**
 * Returns a route switch suggestion on driver select (when possible)
 */
export const switchSuggestionDescription = (driver, handler) => (
  <>
    <strong>{driver}&nbsp;</strong>
    <span>has a route to the same pickup location.</span>
    <br />
    {getClickPhrase({ handler, text: "to switch drivers" })}
  </>
);

/**
 * Function that renders the notification to make the user create
 * a mandatory route for a driver to a location
 */
export function getMandatoryNotification({
  index,
  form,
  prevDriver,
  thisDriver,
  prev,
  selectHelper,
  complementaryRoutes,
  listOfLabels,
  setComplementaryRoutes,
  thisDriverId,
  schedulesForDate,
  allProjects,
  switchDriversHandler,
  prevEditBlock = -1,
  setEditBlock = () => {},
  routeLength,
}) {
  let baseSelector = index === null ? "Return" : "";
  let indexSelector = index === null ? "" : index;
  return notification.warning({
    message: warningNotificationTitle(index),
    key: "driverSelectNotification",
    closeIcon: getCloseIcon(() => {
      notification.destroy("driverSelectNotification");
      form.setFieldValue(`${baseSelector}Driver#${indexSelector}`, prevDriver);
      setEditBlock(prevEditBlock);
    }),
    description: (
      <>
        {index !== 0 && (
          <p>
            Careful, if you choose <strong>{thisDriver}</strong>, you will be
            required to create a dispatch sending them to the location.
          </p>
        )}
        <p>
          <strong>{thisDriver}</strong> will take over for{" "}
          <strong>{prev}</strong> in all their routes.
        </p>
        {getClickPhrase({
          handler() {
            selectHelper(index);
            // let t = structuredClone(complementaryRoutes || []);
            let t = [...complementaryRoutes];
            let scheduleId = "";
            let projectId = "";
            let label = null;
            if (!!index) {
              label = JSON.stringify(listOfLabels[index - 1]);
            } else if (index === null) {
              label = JSON.stringify(listOfLabels[routeLength - 1]);
            }
            if (label?.includes("(Schedule)")) {
              scheduleId = schedulesForDate?.find(
                (schedule) =>
                  schedule?.scheduleAddress ===
                  form.getFieldValue(
                    `${baseSelector}PickupLocation#${indexSelector}`
                  )
              )?.scheduleId;
            } else if (label?.includes("Project (off schedule)")) {
              projectId = allProjects?.find(
                (project) =>
                  project?.projectName ===
                  form.getFieldValue(
                    `${baseSelector}PickupLocation#${indexSelector}`
                  )
              )?.projectId;
            }
            t.push({
              location: form.getFieldValue(
                `${baseSelector}PickupLocation#${indexSelector}`
              ),
              arrive: form.getFieldValue(
                `${baseSelector}DepartAt#${indexSelector}`
              ),
              scheduleId,
              projectId,
              startDriver: prevDriver,
              returnDriver: form.getFieldValue(
                `${baseSelector}Driver#${indexSelector}`
              ),
            });
            if (index !== 0) {
              setComplementaryRoutes(t);
            }
            switchDriversHandler({
              outerDriver: thisDriverId,
              outerIndex: index === 0 ? 0 : !index ? null : index,
              outerHandler() {},
            });
            notification.destroy("driverSelectNotification");
            form.setFieldValue(
              `${baseSelector}Driver#${indexSelector}`,
              thisDriverId
            );
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

/**
 * Returns the appropriate description on time conflict
 */
export const driverErrorDescription = (
  switchCondition,
  driver,
  handler,
  canBeSelected,
  index
) => {
  return (
    <>
      <span>
        <strong>
          {driver}
          &nbsp;
        </strong>
        {canBeSelected
          ? "is not available for the selected time!"
          : "is occupied in another dispatch at this time!"}
      </span>
      <br />
      {switchCondition ? (
        <>
          {getClickPhrase({
            handler,
            text: "to make a driver switch",
          })}
        </>
      ) : (
        <span>
          Please select another time
          {canBeSelected || index === 0 ? (
            "."
          ) : (
            <>
              <span>
                &nbsp;or add a route that sends the driver to the location.
              </span>
              <br />
              {getClickPhrase({ handler })}
            </>
          )}
        </span>
      )}
    </>
  );
};

export function getDriverErrorNotification({
  index,
  switchCondition = false,
  drivers,
  driver,
  setSwitchDriver = () => {},
  selector = "driver",
  canBeSelected,
  setEditBlock,
  prevEditBlock,
}) {
  let c = index === null;
  notification.error({
    key: "errorPotentialSwitch",
    message: getErrorMessage(index),
    closeIcon: getCloseIcon(() => {
      notification.destroy("errorPotentialSwitch");
      setEditBlock(prevEditBlock);
    }),
    description: (
      <>
        {driverErrorDescription(
          switchCondition,
          drivers?.find((el) => el?.driverId === driver)?.driverName,
          () => {
            notification.destroy("errorPotentialSwitch");
            if (!canBeSelected && !switchCondition) {
              setSwitchDriver({
                driverToSwitch: driver,
                routeIndex: index,
                selector,
                allRoutes: true,
              });
            } else {
              setSwitchDriver({
                driverToSwitch: driver,
                routeIndex: index,
                selector,
              });
            }
          },
          canBeSelected,
          index
        )}
      </>
    ),
    duration: switchCondition ? 0 : 5,
    placement: "bottomLeft",
  });
}

/**
 * Helper function to the driver selection, opens a notification that allows the user
 * to make a driver switch
 */
export function getDriverNotification(
  index,
  driver,
  drierId,
  setSwitchDriver,
  caller,
  form,
  prevDriver,
  prevEditBlock,
  setEditBlock
) {
  let c = index === null;
  let baseSelector = c ? "Return" : "";
  let indexSelector = c ? "" : index;
  notification.info({
    message: (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <InfoIcon />{" "}
        <span style={{ fontWeight: "550" }}>{`Info for ${
          c ? "return route" : `route ${index + 1}`
        }`}</span>
      </div>
    ),
    key: "normalSwitchNotification",
    closeIcon: getCloseIcon(() => {
      notification.destroy("normalSwitchNotification");
      form.setFieldValue(`${baseSelector}Driver#${indexSelector}`, prevDriver);
      setEditBlock(prevEditBlock);
    }),
    description: switchSuggestionDescription(driver, () => {
      notification.destroy("normalSwitchNotification");
      setSwitchDriver({
        driverToSwitch: drierId,
        routeIndex: index,
        selector: caller,
      });
    }),
    duration: 0,
    placement: "bottomLeft",
  });
}

export function getNextDayWarningNotification({
  index,
  prevEditBlock,
  setEditBlock,
  handler,
}) {
  notification.error({
    key: "timeNextDayNotification",
    message: getErrorMessage(index),
    closeIcon: getCloseIcon(() => {
      setEditBlock(prevEditBlock);
    }),
    description: (
      <>
        <strong>{`Suggested time is earlier than ${
          index === 0 ? "the selected date" : "previous arrival"
        }`}</strong>
        <p>
          If you choose to proceed, this route&nbsp;
          {index !== null && "and the ones below "}will pass on to the next
          date.
        </p>
        {getClickPhrase({
          handler() {
            notification.destroy("timeNextDayNotification");
            handler();
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

export function getNextDayErrorNotification({
  index,
  prevEditBlock,
  setEditBlock,
  handler,
  cancelHandler,
}) {
  notification.error({
    key: "timeNextDayErrorNotification",
    message: getErrorMessage(index),
    closeIcon: getCloseIcon(() => {
      setEditBlock(prevEditBlock);
      cancelHandler();
    }),
    description: (
      <>
        <strong>
          Depart cannot be earlier than the previous route's arrival time.
        </strong>
        <p>
          If you choose to precede, this route&nbsp;
          {index !== null && "and the ones below "}will pass on to the next
          date.
        </p>
        {getClickPhrase({
          handler() {
            setEditBlock(prevEditBlock);
            notification.destroy("timeNextDayErrorNotification");
            handler();
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

export function getRouteCancelOnSwitchNotification({
  cancelHandler = () => {},
  handler = () => {},
  setEditBlock,
  prevEditBlock,
  index,
}) {
  notification.warning({
    message: warningNotificationTitle(index),
    key: "getRouteCancelOnSwitchNotification",
    closeIcon: getCloseIcon(() => {
      setEditBlock(prevEditBlock);
      cancelHandler();
    }),
    description: (
      <>
        <strong>
          Careful, you are about to cancel a route needed for a driver switch.
        </strong>
        <p>
          If you choose to proceed, you will be required to update the dispatch
          holding the switch as well.{" "}
          <strong>This action can not be reversed!</strong>
        </p>
        {getClickPhrase({
          handler() {
            notification.destroy("getRouteCancelOnSwitchNotification");
            handler();
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

export function getSameCargoWarning(index) {
  notification.warning({
    message: warningNotificationTitle(index),
    key: "sameCargoWarningNotification",
    closeIcon: getCloseIcon(),
    description: (
      <>
        <strong>
          Careful, this route and the one above have the same cargo status
          (Loaded).
        </strong>
        <p>Make sure to select a paperwork type.</p>
      </>
    ),
    duration: 5,
    placement: "bottomLeft",
  });
}

export function getTimeErrorForRequest({
  index,
  time,
  handler = () => {},
  closeHandler = () => {},
}) {
  notification.error({
    message: getErrorMessage(index),
    key: "suggestionEarlier",
    closeIcon: getCloseIcon(() => {
      closeHandler();
    }),
    description: (
      <>
        <strong>Truck cannot make the requested time.</strong>
        <p>{`In order to reach the location at ${time}, every route above this one needs to have their times changed`}</p>
        {getClickPhrase({
          handler() {
            notification.destroy("suggestionEarlier");
            handler();
          },
        })}
      </>
    ),
    duration: 0,
    placement: "bottomLeft",
  });
}

export function getTruckOverlappingNotification() {
  notification.error({
    message: getErrorMessage(),
    key: "truckOverlapping",
    closeIcon: getCloseIcon(),
    description: (
      <>
        <strong>Truck is occupied in another dispatch.</strong>
        <p>
          The planned departure and arrival for a truck should not overlap with
          another dispatch.
        </p>
        <span>Please select other times</span>
      </>
    ),
    duration: 5,
    placement: "bottomLeft",
  });
}

export function getStatusWarningMessage(status) {
  notification.warning({
    message: warningNotificationTitle(null, true),
    key: "statusWarningMessage",
    closeIcon: getCloseIcon(),
    description: (
      <>
        <strong>
          Careful, there are changes made to the referenced schedules in this
          dispatch
        </strong>
        <p>
          Please make sure you made all the appropriate changes to the dispatch
          routes before saving
        </p>
        <span>{`If you save after changing the status to ${status}, you will not be able to see the schedule changes anymore`}</span>
      </>
    ),
    duration: 10,
    placement: "bottomLeft",
  });
}
