import React, { createContext, useState, useContext } from "react";

const DriveDocumentsContext = createContext();

export const DriveDocumentsProvider = ({ children }) => {
  const [driveDocuments, setDriveDocuments] = useState([]);

  return (
    <DriveDocumentsContext.Provider
      value={{ driveDocuments, setDriveDocuments }}
    >
      {children}
    </DriveDocumentsContext.Provider>
  );
};

export const useDriveDocuments = () => useContext(DriveDocumentsContext);
