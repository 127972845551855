import { useSelector } from "react-redux";
import { driveApi } from "../integrations/DriveRequest";
import { useState } from "react";

/** * @param {Object} imageCache - Cached Image in variable */

export const useGetImageSrc = () => {
  const { accessToken } = useSelector((state) => state.accessToken);

  const [imageSrc, setImageSrc] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [error, setError] = useState(null);

  const driveRequest = driveApi({ accessToken });
  const handleGetImageSrc = async (googleDriveFileId) => {
    try {
      const response = await driveRequest.getImageSrc(googleDriveFileId);
      setImageSrc(response.src);
      setImageBlob(response.blob);

      return response.src;
    } catch (error) {
      setError(error);
    }
  };

  const houseKeeping = () => {
    setImageSrc(null);
    setImageBlob(null);
    setError(null);
  };

  return {
    imageSrc,
    imageBlob,
    error,
    handleGetImageSrc,
    houseKeeping,
  };
};
