import { Flex } from "antd";
import InputGridEditor from "src/components/pages/Settings/settingsComponents/Crews/Components/UploadEmployees/components/Cell Components/InputGridEditor";
import { RatesNumberEditor } from "src/components/pages/Payroll/Tabs/Jobsite/components/JobsiteRatesGrid/utils";
import { OpenLinkIcon } from "src/assets";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton } from "src/components/commonComponents";

const confirmationColumns = [
  {
    headerName: "Truck #",
    field: "fleetName",
  },
  {
    headerName: "Sheet Name",
    field: "sheetName",
    editable({ context }) {
      if (!context?.isWritable) {
        context?.showWritableInfo && context.showWritableInfo();
      }
      return context?.isWritable;
    },
    cellEditor: InputGridEditor,
  },
  {
    headerName: "Sheet Id",
    field: "sheetId",
    editable({ context }) {
      if (!context?.isWritable) {
        context?.showWritableInfo && context.showWritableInfo();
      }
      return context?.isWritable;
    },
    cellEditor: InputGridEditor,
  },
  {
    headerName: "Last Row",
    field: "lastRow",
    editable({ context }) {
      if (!context?.isWritable) {
        context?.showWritableInfo && context.showWritableInfo();
      }
      return context?.isWritable;
    },
    cellEditor: RatesNumberEditor,
  },
  {
    headerName: "Actions",
    cellStyle: {
      display: "grid",
      placeItems: "center",
    },
    cellRenderer({ context, data }) {
      return (
        <Flex gap={10}>
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick() {
                if (!context?.isWritable) {
                  context?.showWritableInfo && context.showWritableInfo();
                } else if (context?.onDeleteRow) {
                  context?.onDeleteRow(data);
                }
              },
            }}
          >
            {null}
          </MondayButton>
          <MondayButton
            {...{
              Icon: <OpenLinkIcon />,
              className: "mondayButtonBlue",
              onClick() {
                if (context?.goToAudit) {
                  context.goToAudit(data);
                }
              },
            }}
          >
            {null}
          </MondayButton>
        </Flex>
      );
    },
  },
];

export default confirmationColumns;
