import { formatCurrency } from "../../../../utils";

export const applications = ({ tables, programFields }) => {
  // sort tables.Applications by project name and application number
  const data = tables?.applications?.sort(
    (a, b) =>
      a?.projectName?.localeCompare(b?.projectName) ||
      a?.applicationNo - b?.applicationNo
  );

  return {
    applicationStatus: () => ({
      statusData: data?.map((a) => {
        return {
          label: `${a?.projectName} - RequisitionNo ${a?.applicationNo}`,
          value: a?.applicationStatus,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Requisition?.find(
                (b) =>
                  b?.statusName?.toLowerCase() ===
                  a?.applicationStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
          custom: [
            {
              "Total Amount": {
                "Current Payment": formatCurrency(a?.totalAmount),
                "Total Retainage": formatCurrency(a?.totalRetainage),
                "Total Rental With Tax": formatCurrency(a?.totalRentalWithTax),
              },
            },
          ],
          id: a?.projectId,
        };
      }),
    }),
  };
};
