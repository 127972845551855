import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import { GeneralReportsContext } from "./context";
import { LoadableComp } from "../../../XComponents";
import { filterTables } from "../../../../../utils/filterTables";
import { useProgramFields, useRecordId } from "../../../../../hooks";
import { uuidRegex } from "../../../Documentation/View/documentationViewData";
import { GeneralReportsContainer, GeneralReportsFilters } from "./components";

import "./GeneralReports.scss";

const GeneralReports = () => {
  let location = useLocation();
  const recordId = useRecordId();

  const formattedPath = location.pathname.replace(uuidRegex, "");

  const { ["Reports Integration Config"]: reportsIntegrationConfig } =
    useProgramFields();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [activeFilters, setActiveFilters] = useState({});
  const [reportsAvailable, setReportsAvailable] = useState([]);
  const [reportsFilter, setReportsFilter] = useState([]);

  const [loading, setLoading] = useState(true);

  const { categoryName, filters, partitionKeys } = useMemo(
    () => reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location]
  );

  useEffect(() => {
    if (!categoryName) setLoading(false);
    (async () => {
      const reportsAvailableTemp = await filterTables(
        "reportsAvailable",
        "categoryName",
        categoryName
      ).then((res) => {
        return res;
      });
      setReportsAvailable(reportsAvailableTemp);
      setReportsFilter(reportsAvailableTemp);
      setLoading(false);
    })();
  }, [categoryName]);

  return (
    <GeneralReportsContext.Provider
      value={{
        filters,
        recordId,
        partitionKeys,
        activeFilters,
        setActiveFilters,
        reportsAvailable,
        setReportsAvailable,
        reportsFilter,
      }}
    >
      <LoadableComp {...{ loading }}>
        <div
          className={`general-reports-wrapper ${
            isDarkMode && "general-reports-wrapper-dark"
          }`}
        >
          <GeneralReportsFilters isDarkMode={isDarkMode} />
          <GeneralReportsContainer isDarkMode={isDarkMode} />
        </div>
      </LoadableComp>
    </GeneralReportsContext.Provider>
  );
};

export default GeneralReports;
