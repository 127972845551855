import { useState } from "react";
import { useSelector } from "react-redux";

import { ThreeAsteriskIcon } from "../icons";
import { formSubmit, validateFields } from "../utils";
import WarningSignupModal from "./WarningSignupModal";
import { MondayButton } from "../../../components/commonComponents";
import { showErrorMsg, showLoadingMsg, showSuccessMsg } from "../../../utils";
import { RenderDynamicComponents } from "../../../components/Header/forms/Components";
import { useEditLogs } from "../../../hooks";

const PasswordConfirmation = ({ form, populatedFields, setUser }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const allUsers = userConfiguration.allUsers.Items || [];

  const formSubmitHandler = () => {
    showLoadingMsg({ content: "Creating new user. Please wait..." });
    formSubmit({ form, userConfiguration, saveAddedLogs })
      .then((result) => {
        setUser(result);
        showSuccessMsg({ content: "New user created successfully!" });
      })
      .catch((error) => {
        if (error.name === "UsernameExistsException") {
          showErrorMsg({
            content:
              "An account with the given email already exists. Please use another email.",
          });
          return;
        }
        showErrorMsg({ content: "There was a problem creating user" });
        console.log({ error });
      });
  };

  return (
    <>
      <div className="PasswordConfirmation">
        <ThreeAsteriskIcon />
        <h2>Password Confirmation</h2>
        <p>Password must have an uppercase letter, a number and a symbol.</p>
        <div className="formInputs">
          {RenderDynamicComponents(populatedFields).filter(
            (item) => Number(item.key) > 5
          )}
        </div>

        <MondayButton
          style={{ width: "100%" }}
          hasIcon={false}
          onClick={() => {
            if (
              allUsers?.some(
                (el) =>
                  el?.username !== undefined &&
                  el?.username === form.getFieldValue("userName")
              )
            ) {
              setVisible(true);
            } else {
              validateFields(
                form,
                formSubmitHandler,
                Object.values(populatedFields)
                  .flat()
                  .map(({ formItemName }) => formItemName)
              );
            }
          }}
          className="formButtonElement"
        >
          Create New User
        </MondayButton>
      </div>

      {visible && (
        <WarningSignupModal
          visible={visible}
          setVisible={setVisible}
          title={"Warning"}
          isDarkMode={isDarkMode}
          warningMessage={
            "Please use another email. A user with this email already exist."
          }
        />
      )}
    </>
  );
};

export default PasswordConfirmation;
