import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
export const handleDisableData = ({ current, form, dateKey }) => {
  const startDate = form.getFieldValue("taskStartDate");
  const endDate = form.getFieldValue("taskEndDate");
  const deadLine = form.getFieldValue("taskDeadline");

  const startDay = startDate ? dayjsNY(startDate).startOf("day") : null;
  const endDay = endDate ? dayjsNY(endDate).startOf("day") : null;
  const deadlineDay = deadLine ? dayjsNY(deadLine).startOf("day") : null;

  const timeKeys = {
    taskStartDate: () =>
      (endDay && current > endDay) ||
      (deadlineDay && current > deadlineDay) ||
      current < dayjsNY().startOf("day").subtract(1, "day"),

    taskEndDate: () =>
      (startDay && current < startDay) ||
      (deadlineDay && current > deadlineDay) ||
      current < dayjsNY().startOf("day").subtract(1, "day"),

    taskDeadline: () =>
      (startDay && current < startDay) ||
      (endDay && current < endDay) ||
      current < dayjsNY().startOf("day").subtract(1, "day"),
  };

  return !!timeKeys[dateKey]();
};
