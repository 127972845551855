import types from "../../types"

const initialState = {
  base64: undefined,
}

const base64Reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_BASE_64:
      return {
        ...state,
        base64: payload,
      }
    default:
      return state
  }
}

export default base64Reducer
