import { StatusColors } from "../types";

export interface MappedSectionType {
  name: string;
  statuses: { status: string; color: string }[];
}

type NestedSectionType = {
  name: string;
  subsection: string;
};

function mapStatusColors(
  sections: Array<string | NestedSectionType>,
  statusColors: StatusColors
) {
  let arr: MappedSectionType[] = [];

  for (const key of sections) {
    let tmp = [];

    let name: string;
    let subsection: string;

    if (typeof key === "string") {
      name = key;
    } else {
      name = key.name;
      subsection = key.subsection;
    }

    const field = statusColors[name];
    if (!field) {
      continue;
    }

    if (Array.isArray(field)) {
      tmp = field.map(({ statusColor, statusName }) => ({
        color: statusColor,
        status: statusName,
      }));
    } else {
      if (!subsection) {
        continue;
      }

      const actualField = field[subsection];
      tmp = actualField.map(({ statusName, statusColor }) => ({
        color: statusColor,
        status: statusName,
      }));
    }

    arr = arr.concat({
      name,
      statuses: tmp,
    });
  }

  return arr;
}

export default mapStatusColors;
