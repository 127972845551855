import { updateChargeServices } from "../../../utils/deleteCharge";
import { updatePLIsFromCategory } from "../utils";

export const generateServiceOptionsNewCharge = ({
  categoryFrom,
  result,
  defaultParams,
  whereToFind,
  selectedGroup,
}) => {
  let newServiceOptions;
  if (categoryFrom === "Requisition") {
    newServiceOptions = updatePLIsFromCategory({
      ...result,
      ...defaultParams,
    });
  } else if (categoryFrom === "Estimation") {
    newServiceOptions = updatePLIsFromCategory({
      ...result,
      ...defaultParams,
      services: whereToFind.services[selectedGroup],
    });
  } else {
    newServiceOptions = updatePLIsFromCategory({
      ...result,
      ...defaultParams,
      ...whereToFind,
    });
  }
  return newServiceOptions;
};

export const generateServiceOptionsCurrentCharge = ({
  categoryFrom,
  updatedCharge,
  defaultParams,
  whereToFind,
  selectedGroup,
  objToPost,
}) => {
  let newServiceOptions;
  if (categoryFrom === "Requisition") {
    newServiceOptions = updatePLIsFromCategory({
      ...updatedCharge,
      recordId: selectedGroup,
      tableFrom: "applications",
    });
  } else if (categoryFrom === "Estimation" || categoryFrom === "Rental") {
    newServiceOptions = updatePLIsFromCategory({
      ...updatedCharge,
      ...defaultParams,
      services: updateChargeServices(
        whereToFind.services?.[selectedGroup] || whereToFind.services,
        updatedCharge
      ),
      ...objToPost,
    });
  } else if (categoryFrom === "Schedule Of Value") {
    newServiceOptions = updatePLIsFromCategory({
      ...updatedCharge,
      ...defaultParams,
      ...whereToFind,
    });
  }
  return newServiceOptions;
};
