import { Form } from "antd";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import "./OptionsFormList.scss";
import { Close } from "../../../../../Roles/src";
import { Plus } from "../../../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons/index";
import { MondayButton } from "src/components/commonComponents";

const OptionsFormList = ({ form, formItemName = "fieldOptions" }) => (
  <Form {...{ form, rules: {} }}>
    <div className="docOptionsContainer">
      <Form.List
        name={formItemName}
        initialValue={[""]}
        rules={[
          {
            validator: (_, value = []) =>
              value.filter(Boolean).length === 0
                ? Promise.reject(new Error("Please provide the options!"))
                : Promise.resolve().then((res) => console.log("Result", res)),
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <div
              style={{
                // position: "relative",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <MondayButton Icon={<Plus />} onClick={add}>
                Add Option
              </MondayButton>
            </div>
            {fields?.map((field, index) => (
              <div className="optionField">
                <InputComponent
                  {...{
                    ...field,
                    onPressEnter: () => add(),
                    label: `Option ${index + 1}`,
                    placeholder: "Type one here...",
                    formItemName: field.name,
                  }}
                />
                {fields.length !== 1 && (
                  <span
                    onClick={() => {
                      if (fields.length === 1) {
                        remove(field.name);
                        add();
                      } else remove(field.name);
                    }}
                    className="fieldOptionButton added"
                  >
                    <Close className="closeSvgIcon" />
                  </span>
                )}
              </div>
            ))}

            <div
              style={{
                color: "red",
              }}
            >
              {errors}
            </div>
            {/* <Form.ErrorList {...{ errors }} /> */}
          </>
        )}
      </Form.List>
    </div>
  </Form>
);

export default OptionsFormList;
