export const leads = ({ tables, programFields }) => {
  return {
    leadStatus: () => ({
      statusData: tables?.Leads?.map((a) => {
        return {
          label: a?.leadCompany,
          value: a?.leadStatus,
          color:
            programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Leads?.find(
                (b) => b?.statusName?.toLowerCase() === a?.leadStatus?.toLowerCase()
              )?.statusColor || "#6D8299",
          date: a?.createdAt,
        }
      }),
    }),
    leadAddress: () => ({
      statusData: tables?.Leads?.map((a) => {
        return {
          label: a?.leadAddress,
          value: a?.borough,
          color:
            programFields
              ?.find((a) => a?.fieldName === "NY Boroughs")
              ?.fieldOptions?.find(
                (b) => b?.boroughName?.toLowerCase() === a?.borough?.toLowerCase()
              )?.boroughColor || "#6D8299",
          date: a.createdAt,
        }
      }),
    }),
  }
}
