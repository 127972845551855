import { API } from "aws-amplify";

/**
 * Handles missing records by fetching them from the API and updating the dispatchData object.
 *
 * @param {Object} rowObject - The row object containing scheduleId and/or projectId.
 * @param {string} rowObject.scheduleId - The schedule ID.
 * @param {string} rowObject.projectId - The project ID.
 * @param {Object} dispatchData - The dispatchData object.
 * @returns {Promise<void>} - A promise that resolves when the missing record is fetched and updated in the dispatchData object.
 */
const handleMissingRecord = async ({
  rowObject = {
    scheduleId: "",
    projectId: "",
  },
  dispatchData = {},
}) => {
  if (rowObject?.scheduleId) {
    if (
      !dispatchData?.scheduling?.some(
        (el) => el?.scheduleId === rowObject?.scheduleId
      )
    ) {
      await API.get("scheduling", `/scheduling/${rowObject?.scheduleId}`, {
        queryStringParameters: {
          keysToInclude: JSON.stringify([
            "scheduleId",
            "scheduleAddress",
            "scheduleName",
            "scheduleDays",
            "createdAt",
            "teamsConfiguration",
          ]),
        },
      })
        .then((res) => {
          dispatchData.scheduling.push(res);
        })
        .catch((err) => {
          console.error("Error getting missing record: ", err);
        });
    }
  } else if (rowObject?.projectId) {
    if (
      !dispatchData?.projects?.some(
        (el) => el?.projectId === rowObject?.projectId
      )
    ) {
      await API.get("projects", `/projects/${rowObject?.projectId}`, {
        queryStringParameters: {
          keysToInclude: JSON.stringify([
            "projectId",
            "projectName",
            "accountId",
            "accountName",
            "createdAt",
            "teamsConfiguration",
          ]),
        },
      })
        .then((res) => {
          dispatchData.projects.push(res);
        })
        .catch((err) => {
          console.error("Error getting missing record: ", err);
        });
    }
  }
};

export default handleMissingRecord;
