import { FleetInspectionType } from "../types";
import { parseInTz } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { pdfBorderedSection } from "../../Fleet/view/pageView/components/SingleTruckMaintenance/modals/maintenanceModal/maintenanceModalData";
import { pdfImageSection } from "../../Safety/safetyPdf";
import { pdfBorderedSection as section } from "../../Safety/safetyPdf";

type InspectionDoc = {
  inspection: FleetInspectionType;
  imageSources: string[];
  companyLogo: string;
  signatures?: Array<{ label: string; signature: string | null }>;
};

function getInspectionDocDefinition({
  inspection,
  companyLogo,
  imageSources,
  signatures = [],
}: InspectionDoc) {
  const maintenanceDate = parseInTz(inspection.inspectionDate).format(
    "MMM DD, YYYY"
  );
  const mechanic = inspection?.mechanicInfo?.mechanic;

  const flexImageSectionConfig = imageSources.length
    ? pdfImageSection({
        title: "Inspection Report Images",
        body: imageSources.map((src, index) => ({
          name: `Image ${index + 1}`,
          src: src,
          link: undefined,
        })),
        noColor: false,
      })
    : {};

  const signaturesSection = section({
    title: "Signatures",
    body: signatures.map((signature) => {
      return {
        value: signature.signature || "No Signature",
        key: signature.label,
      };
    }),
  });

  return {
    pageOrientation: "portrait",
    pageMargins: [20, 20, 20, 30],
    footer: (currentPage, pageCount) => {
      return {
        text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
        alignment: "center",
        margin: 7,
      };
    },
    content: [
      {
        columns: [
          {
            image: "logo",
            width: 100,
          },
          {
            stack: [
              {
                text: `Inspection`,
                style: ["strong", "large"],
                margin: [0, 10, 90, 10],
              },
            ],
            width: "*",
            alignment: "center",
          },
        ],
        style: "subheader",
      },
      {
        svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
        width: 555,
        height: 2,
        style: "header",
      },
      {
        columns: [],
        alignment: "left",
        width: "auto",
        style: "subheader",
      },
      {
        text: `Vehicle: ${inspection.fleetName || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Inspection Date: ${maintenanceDate || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Mechanic: ${mechanic || ""}`,
        style: "subheader",
        margin: [0, 5],
      },
      {
        text: `Teams Configuration: ${inspection.teamsConfiguration.flatMap(
          (el) => el.members.map((e) => e.nameOfUser)
        )}`,
        style: "subheader",
        margin: [0, 5],
      },
      [
        pdfBorderedSection({
          title: "Inspection Items",
          body: inspection.inspectionReport,
          form: undefined,
          noColor: false,
        }),
      ],
      signaturesSection,
      flexImageSectionConfig,
    ],
    images: {
      logo: companyLogo,
      ...signatures.reduce(
        (acc, val) => ({
          ...acc,
          [val.label]: val.signature || "",
        }),
        {}
      ),
    },
    styles: {
      strong: {
        bold: true,
      },
      large: { fontSize: 20 },
      header: {
        margin: [0, 0, 0, 20],
      },
      subheader: { margin: [0, 0, 0, 10], fontSize: 14 },
      innerTable: { margin: 0 },
      outterTable: { margin: [0, 0, 0, 30] },
      lastColumn: { color: "#4A4A4A" },
      bool: { margin: [0, 5], fontSize: 13 },
      cellItem: { margin: [0, 2] },
    },
  };
}

export default getInspectionDocDefinition;
