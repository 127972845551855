export const rentals = (
  programFields,
  updatedDynamicStates,
  IdHandler,
  rentalsData
) => {
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  return {
    rentalStatus: () => ({
      title: "Rental Status",
      BodyComponent: "LongLineSteper",
      type: "LongLineSteper",
      BodyComponentParams: {
        table: "Rentals",
        field: "rentalStatus",
        content: rentalStatus?.statusName?.map((status, index) => ({
          key: index,
          title: status,
          taskNumber: rentalsData?.length,
          taskDone: rentalsData?.filter((a) => a?.rentalStatus === status)
            ?.length,
          color: rentalStatus?.statusColor[index],
          backgroundColor: "#B2DD9B",
          contentHandler: ` ${status} Rental`,
          contentsHandler: ` ${status} Rentals`,
        })),
      },
      id: IdHandler,
      width: "1600px",
    }),
  };
};
