import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";

export const columnDefs = [
  {
    headerName: "Employee Id",
    field: "employeeId",
  },
  {
    headerName: "Full Name",
    field: "employeeFullName",
  },
  {
    headerName: "Clock In",
    field: "firstClockIn",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
    },
  },
  {
    headerName: "Clock Out",
    field: "clockOut",
    cellRenderer({ value }) {
      if (value) {
        return dayjsNY(value).format(`${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`);
      }
      return "";
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
    },
  },
  {
    headerName: "Jobsite Address",
    field: "jobsiteAddress",
  },
];
