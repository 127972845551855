import AmountLabel from "./components/AmountLabel/AmountLabel";
import "./PaymentAmounts.scss";
import { useMemo } from "react";
import { forceToNumber } from "../../../../utils/checkers";
import { useRedux } from "../../../../../../../../../hooks/useRedux";

const PaymentAmounts = () => {
  const [fakePayment] = useRedux("fakePayment");

  const totalities = useMemo(() => {
    let totalDue = 0;
    let credits = 0;
    let thisPaid = 0;
    if (Array.isArray(fakePayment?.invoices))
      totalDue = fakePayment?.invoices?.reduce(
        (acc, obj) => acc + forceToNumber(obj.invoiceAmountDue),
        0
      );
    credits = fakePayment?.invoices?.reduce(
      (acc, obj) => acc + forceToNumber(obj?.invoiceCredit || 0),
      0
    );
    thisPaid = fakePayment?.invoices?.reduce(
      (acc, obj) => acc + forceToNumber(obj?.invoicePaidAmount || 0),
      0
    );
    return { totalDue, credits, thisPaid };
  }, [fakePayment?.invoices]);

  return (
    <div className="payment-amounts-body">
      <h5 className="amounts-text">Amounts for selected invoices</h5>
      <div className="payment-amounts-labels">
        <AmountLabel text="Amount Due" amount={totalities?.totalDue} />
        <AmountLabel
          text="Amount Applied"
          amount={fakePayment?.appliedAmount}
        />{" "}
        <AmountLabel text="This Payment" amount={totalities?.thisPaid} />
        <AmountLabel
          text="Discount and Credits Applied"
          amount={totalities?.credits}
        />
        <hr />
        <AmountLabel
          text="Balance (Applied - Amount Due)"
          amount={fakePayment?.appliedAmount - totalities?.totalDue}
        />
        {/* <AmountLabel
          text="New Customer Balance"
          amount={fakePayment?.newCustomerBalance - totalities?.credits}
        /> */}
      </div>
    </div>
  );
};

export default PaymentAmounts;
