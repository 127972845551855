import {
  useMemo,
  Fragment,
  useState,
  useEffect,
  useContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { InfoWindowF } from "@react-google-maps/api";

import LiveMapContext from "../../LiveMapContext";
import { YARD_LOCATIONS } from "../../../../data";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { FleetsLiveContext, findBoundExtremities } from "../../../../utils";
import LocationInfo from "../LocationInfo/LocationInfo";
import LocationMarker from "../LocationMarker/LocationMarker";

const LiveMarkerGroup = forwardRef((_, ref) => {
  const {
    mapInstance,
    onInfoClick,
    getStatusFilter,
    getVehicleFilter,
    getSelectedVehicle,
  } = useContext(LiveMapContext);
  const { locations } = useContext(FleetsLiveContext);

  const [markersVisible, setMarkersVisible] = useState(true);
  const [infoPosition, setInfoPosition] = useState(undefined);
  const [vehiclesToRender, setVehiclesToRender] = useState([]);

  function filterLocations(vins = [], status = null) {
    let locationRes = locations?.filter(
      ({ locationStatus, deviceSerialNumber }) => {
        if (vins?.length) {
          if (!vins?.includes(deviceSerialNumber)) {
            return false;
          }
        }

        if (status) {
          if (locationStatus !== status) {
            return false;
          }
        }

        return true;
      }
    );
    return locationRes;
  }

  function findBounds(filteredTrucks) {
    if (mapInstance) {
      if (filteredTrucks?.length) {
        const bounds = new window.google.maps.LatLngBounds();
        let extremities = findBoundExtremities(
          filteredTrucks?.map((e) => e?.position)
        );
        for (const location of extremities) {
          bounds.extend(location);
        }
        mapInstance.fitBounds(bounds);
      } else {
        mapInstance.panTo(YARD_LOCATIONS()?.[0] || NYC_CENTER);
        mapInstance.setZoom(17);
      }
    }
  }

  /**
   * Function used to follow the selected truck when
   * the locations data updates
   */
  function followSelectedVehicle() {
    let location = getSelectedVehicle();
    if (location && mapInstance) {
      mapInstance.panTo(location?.position);
      mapInstance.setZoom(mapInstance.getZoom());
    }
  }

  useEffect(() => {
    let truckVins = getVehicleFilter();
    let truckStatus = getStatusFilter();

    let res = filterLocations(truckVins, truckStatus);
    setVehiclesToRender(res);

    followSelectedVehicle();
    setInfoPosition(undefined);
  }, [locations]);

  useImperativeHandle(
    ref,
    () => {
      return {
        filterByVin(arr) {
          let filtered = filterLocations(arr, getStatusFilter());
          findBounds(filtered);
          setVehiclesToRender(filtered);
        },
        filterByStatus(status) {
          let filtered = filterLocations(getVehicleFilter(), status);
          findBounds(filtered);
          setVehiclesToRender(filtered);
        },
        changeMarkerVisibility(visible) {
          setMarkersVisible(visible);
        },
        changeLocationInfo(info) {
          setInfoPosition(info);
        },
      };
    },
    [locations, mapInstance]
  );

  const infoClickHandler = useCallback(
    (location) => {
      if (location?.fleetId !== infoPosition?.fleetId) {
        onInfoClick(location);
        setInfoPosition(location);

        if (mapInstance) {
          mapInstance.panTo(location?.position);
          mapInstance.setZoom(17);
        }
      } else {
        setInfoPosition(undefined);
      }
    },
    [infoPosition, mapInstance]
  );

  function openStreetViewHandler() {
    if (mapInstance) {
      const panorama = mapInstance.getStreetView();
      panorama.setOptions({
        enableCloseButton: true,
        fullscreenControl: true,
        visible: true,
        position: infoPosition?.position,
        pov: {
          heading: infoPosition?.direction || 0,
          pitch: 0,
        },
      });
    }
  }

  const Markers = useMemo(() => {
    return vehiclesToRender?.map((location, i) => {
      return (
        <LocationMarker
          position={location?.position}
          info={location}
          onInfoClick={infoClickHandler}
          key={`location-${location?.fleetName}`}
        />
      );
    });
  }, [vehiclesToRender, infoPosition]);

  return (
    <Fragment>
      {infoPosition && (
        <InfoWindowF
          {...{
            visible: !!infoPosition,
            position: infoPosition?.position,
            zIndex: 1001,
          }}
        >
          <LocationInfo
            {...{
              location: infoPosition,
              className: "map-location-info",
              closable: true,
              onClose() {
                setInfoPosition(undefined);
              },
              footer: (
                <span
                  style={{ color: "#1065A4", cursor: "pointer" }}
                  onClick={openStreetViewHandler}
                >
                  Open Street View
                </span>
              ),
            }}
          />
        </InfoWindowF>
      )}
      {markersVisible ? Markers : null}
    </Fragment>
  );
});

export default LiveMarkerGroup;
