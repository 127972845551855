import { useState, useEffect, useMemo } from "react";
import { getCoordinatesAndZip } from "../../../../SidebarPages/Fleet/utils";
import { Form, Tooltip } from "antd";
import {
  billingAddFormNames,
  BILLING_ADDRESS,
  fieldsJSON,
  shippingAddFormNames,
  SHIPPING_ADDRESS,
} from "./billingAndShippingAddData";
import "./BillingAndShippingAdd.scss";
import {
  compareIncluding,
  openInStreetView,
} from "../../../../SidebarPages/utils";
import RenderDynamicComponents from "../RenderDynamicComponents";
import { BorderedTextCard } from "../../../../SidebarPages/Fleet/components";
import { AddressClass } from "../../../../../utils/AddressClass";
import { CopyIcon } from "../../../assets";
import Geofence from "../../../../commonComponents/Map/Geofence/Geofence";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";

const billingClass = new AddressClass();
const shippingClass = new AddressClass();

/**
 * Renders a component for managing billing and shipping addresses.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - The form object.
 * @param {string} props.title - The title of the component.
 * @param {string} [props.className=""] - The additional CSS class for the component.
 * @param {string} props.mapClassName - The CSS class for the map.
 * @param {string} [props.billingAddress=""] - The billing address.
 * @param {string} [props.billingAddressAptNr=""] - The billing address apartment number.
 * @param {string} [props.shippingAddress=""] - The shipping address.
 * @param {string} [props.shippingAddressAptNr=""] - The shipping address apartment number.
 * @param {boolean} [props.onlyOneAddress=false] - If true renders only Shipping Address Form.
 * @param {boolean} [props.disabled=false] - Indicates if the all component fields are disabled.
 * @param {boolean} props.contactModal - Determines different styling for the contact modal.
 * @returns {JSX.Element} Component for managing billing and shipping addresses. Also renders a map on address select.
 */
const BillingAndShippingAdd = ({
  form,
  title,
  className = "",
  mapClassName,
  billingAddress: proppedBillingAddress = "",
  billingAddressAptNr: proppedBillingAddressAptNr = "",
  shippingAddress: proppedShippingAddress = "",
  shippingAddressAptNr: proppedShippingAddressAptNr = "",
  // setAddressesCoordinates,
  onlyOneAddress = false,
  disabled = false,
  contactModal,
}) => {
  const [locations, setLocations] = useState([]);
  const [billingAddress, setBillingAddress] = useState(proppedBillingAddress);
  const [shippingAddress, setShippingAddress] = useState(
    proppedShippingAddress
  );
  const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(false);
  //state to be used on order to send calls
  //reduces the number of calls to whenever the input changes, to whenever
  //an autocomplete result is selected
  const [billingSelected, setBillingSelected] = useState(false);
  const [shippingSelected, setShippingSelected] = useState(false);

  const getAddCoordinates = async () => {
    if (!!billingAddress || !!shippingAddress) {
      const billingLatLng =
        !!billingAddress && (await getCoordinatesAndZip(billingAddress));
      const shippingLatLng =
        !!shippingAddress && (await getCoordinatesAndZip(shippingAddress));

      const res = [
        { addType: "billing", color: "#F51720", ...billingLatLng },
        { addType: "shipping", color: "#F8D210", ...shippingLatLng },
      ];
      setLocations(res);
      return res;
    }
  };

  const splitAddIntoComps = (location) => {
    const { addType, address_components: addDetails = [], zipCode } = location;
    let address = addType === "billing" ? billingAddress : shippingAddress;
    //sometimes the address is written in it's short version
    //so we need to account for that as well
    const formattedAddDetails = addDetails?.reduce(
      (acc, { long_name, short_name, types = [] }) => ({
        ...acc,
        [types[0]]:
          types[0] === "route"
            ? !address?.includes(long_name)
              ? short_name
              : long_name
            : long_name,
      }),
      {}
    );

    //we set the street route and the number in order to return the correct address formatting
    if (compareIncluding(addType, "billing")) {
      billingClass.setAddress(billingAddress);
      billingClass.setStreet(
        formattedAddDetails?.street_number + " " + formattedAddDetails?.route
      );
      //sets the apt number in the field if it's present in the address
      if (billingAddress !== proppedBillingAddress) {
        form.setFieldValue("aptNumber", formattedAddDetails?.subpremise || "");
      }
    } else {
      shippingClass.setAddress(shippingAddress);
      shippingClass.setStreet(
        formattedAddDetails?.street_number + " " + formattedAddDetails?.route
      );
      if (isBillingSameAsShipping) {
        form.setFieldValue(
          "shippingAddApartmentNr",
          form.getFieldValue("aptNumber")
        );
      } else {
        if (billingAddress !== proppedBillingAddress) {
          form.setFieldValue(
            "shippingAddApartmentNr",
            formattedAddDetails?.subpremise || ""
          );
        }
      }
    }

    form.resetFields(
      compareIncluding(addType, "billing")
        ? billingAddFormNames
        : shippingAddFormNames
    );
    form.setFieldsValue({
      [`${addType?.toLowerCase()}AddState`]:
        formattedAddDetails["administrative_area_level_1"],
      [`${addType?.toLowerCase()}AddCity`]:
        formattedAddDetails["political"] || formattedAddDetails["locality"],
      [`${addType?.toLowerCase()}AddStreet`]: [
        formattedAddDetails["street_number"],
        formattedAddDetails["route"],
      ]
        .filter(Boolean)
        .join(" "),
      [`${addType?.toLowerCase()}AddPostalCode`]: zipCode,
    });
  };

  //splitting the individual calls in order for the autocomplete
  //to not override apt number data without the user's permission
  useEffect(() => {
    if (!!billingAddress) {
      getAddCoordinates().then((r) => {
        let tmp = r?.filter(({ addType }) =>
          compareIncluding(addType, "billing")
        );
        splitAddIntoComps(tmp[0]);
      });
    } else {
      let tmpFields = {};
      billingAddFormNames.forEach((el) => {
        tmpFields[el] = "";
      });
      form.setFieldsValue(tmpFields);
    }
  }, [billingSelected]);

  useEffect(() => {
    if (!!shippingAddress) {
      getAddCoordinates().then((r) => {
        let tmp = r?.filter(({ addType }) =>
          compareIncluding(addType, "shipping")
        );
        splitAddIntoComps(tmp[0]);
      });
    } else {
      let tmpFields = {};
      shippingAddFormNames.forEach((el) => {
        tmpFields[el] = "";
      });
      form.setFieldsValue(tmpFields);
    }
  }, [shippingSelected]);

  useEffect(() => {
    form.setFieldsValue({
      primaryAddress: proppedBillingAddress,
      aptNumber: proppedBillingAddressAptNr,
    });
    billingClass.setAddress(proppedBillingAddress);
    setBillingAddress(proppedBillingAddress);
    setBillingSelected((prev) => !prev);

    form.setFieldsValue({
      secondaryAddress: proppedShippingAddress,
      shippingAddApartmentNr: proppedShippingAddressAptNr,
    });
    shippingClass.setAddress(proppedShippingAddress);
    setShippingAddress(proppedShippingAddress);
    setShippingSelected((prev) => !prev);

    if (
      !!proppedBillingAddress &&
      proppedBillingAddress === proppedShippingAddress
    ) {
      form.setFieldsValue({ isBillingSameAsShipping: true });
      setIsBillingSameAsShipping(true);
    }
  }, [proppedBillingAddress, proppedShippingAddress]);

  useEffect(() => {
    if (isBillingSameAsShipping) {
      setShippingAddress(billingAddress);
      shippingClass.setAddress(billingAddress);
      form.setFieldsValue({
        secondaryAddress: form.getFieldValue("primaryAddress"),
        shippingAddApartmentNr: form.getFieldValue("aptNumber"),
      });
      setShippingSelected((prev) => !prev);
    } else {
      if (!proppedShippingAddress) {
        form.resetFields([
          ...shippingAddFormNames,
          "secondaryAddress",
          "shippingAddApartmentNr",
        ]);

        setShippingAddress("");
        shippingClass.setAddress(billingAddress);
        setLocations((prevLocations) =>
          prevLocations?.filter(
            ({ addType }) => !compareIncluding(addType, "shipping")
          )
        );
      }
    }
  }, [isBillingSameAsShipping]);

  useEffect(() => {
    form.setFieldsValue({
      billingCoordinates: locations.find(({ addType }) => addType === "billing")
        ?.coordinates,
      shippingCoordinates: locations.find(
        ({ addType }) => addType === "shipping"
      )?.coordinates,
    });
  }, [locations]);

  const inputFields = fieldsJSON({
    form,
    contactModal,
    disabled,
    onlyOneAddress,
    isBillingSameAsShipping,
    setIsBillingSameAsShipping,
    setBillingAddress,
    setShippingAddress,
    billingAddress,
    shippingAddress,
    proppedBillingAddressAptNr,
    proppedShippingAddressAptNr,
    billingClearHandler() {
      billingClass.setAddress("");
      billingClass.setStreet("");
      setBillingAddress("");
      setLocations((prev) => {
        const indx = prev?.findIndex(({ addType }) =>
          compareIncluding(addType, "billing")
        );
        if (indx > -1) {
          prev[indx] = { addType: "billing", color: "#F51720" };
        }
        return prev;
      });
      form.resetFields([
        ...billingAddFormNames,
        "primaryAddress",
        "aptNumber",
        "billingCoordinates",
      ]);
    },
    shippingClearHandler() {
      shippingClass.setAddress("");
      shippingClass.setStreet("");
      setLocations((prev) => {
        const indx = prev?.findIndex(({ addType }) =>
          compareIncluding(addType, "shipping")
        );
        if (indx > -1) {
          prev[indx] = { addType: "shipping", color: "#F8D210" };
        }
        return prev;
      });
      setShippingAddress("");
      form.resetFields([
        ...shippingAddFormNames,
        "secondaryAddress",
        "shippingAddApartmentNr",
        "shippingCoordinates",
      ]);
    },
    billingAptChanger: (e) => {
      if (!!billingClass?.street) {
        let tmp = billingClass.addressFormatter(e);
        setBillingAddress(tmp);
        form.setFieldValue("primaryAddress", tmp);
      }
    },
    shippingAptChanger: (e) => {
      if (!!shippingClass?.street) {
        let tmp = shippingClass.addressFormatter(e);
        setShippingAddress(tmp);
        form.setFieldValue("secondaryAddress", tmp);
      }
    },
    setBillingSelected() {
      setBillingSelected((prev) => !prev);
    },
    setShippingSelected() {
      setShippingSelected((prev) => !prev);
    },
  });

  return (
    <>
      <BorderedTextCard
        title={title}
        className="billingBorderedTextCard"
        childrenClassName={`billingAndShippingAddressesContainer ${className}`.trim()}
      >
        {!onlyOneAddress && (
          <Tooltip title="Street View">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/e/e0/Google_Street_View_icon.svg"
              className="streetViewIcon billingAddIcon"
              alt=""
              onClick={() =>
                openInStreetView(
                  locations?.find(({ addType }) =>
                    compareIncluding(addType, "billing")
                  )?.coordinates
                )
              }
            />
          </Tooltip>
        )}
        <Tooltip title="Street View">
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/e/e0/Google_Street_View_icon.svg"
            className="streetViewIcon shippingAddIcon"
            alt=""
            // style={{ paddingRight: 45 }}
            onClick={() =>
              openInStreetView(
                locations?.find(({ addType }) =>
                  compareIncluding(addType, "shipping")
                )?.coordinates
              )
            }
          />
        </Tooltip>
        <div className="businessAddContainer">
          {!onlyOneAddress && (
            <div className="splitAddContainer">
              <div
                className={`copyIconDiv ${
                  isBillingSameAsShipping ? "activeCopyIcon" : ""
                }`}
              >
                <Tooltip title="Same as Billing Address">
                  <CopyIcon
                    onClick={() =>
                      setIsBillingSameAsShipping(!isBillingSameAsShipping)
                    }
                  />
                </Tooltip>
              </div>
              {RenderDynamicComponents(
                inputFields?.find(({ category }) =>
                  compareIncluding(category, BILLING_ADDRESS)
                )?.fields
              )}
              <Form.Item noStyle name="billingCoordinates" />
              <Form.Item noStyle name="shippingCoordinates" />
            </div>
          )}
          <div className="splitAddContainer">
            {RenderDynamicComponents(
              inputFields?.find(({ category }) =>
                compareIncluding(category, SHIPPING_ADDRESS)
              )?.fields
            )}
          </div>
        </div>
      </BorderedTextCard>
      <div className={`${mapClassName}`}>
        {locations?.filter(
          (el) => el?.coordinates && !!keys(el?.coordinates)?.length
        ).length > 0 && (
          <Geofence
            places={[
              {
                coordinates: locations
                  ?.filter((el) => el?.coordinates)
                  .map(({ coordinates }) => coordinates),
              },
            ]}
            showDrawingManager={false}
            allowEditing={false}
          />
        )}
      </div>
    </>
  );
};

export default BillingAndShippingAdd;
