import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

dayjs.extend(customParseFormat);

/**
 * Function that checks if there are missing days in between uploads
 * @param {Object} config
 * @param {dayjs} config.auditDate
 * @param {string} config.lastDate
 * @returns boolean
 */
function areUploadDatesLinked({ auditDate, lastDate }) {
  if (!lastDate || lastDate === "Date") {
    return true;
  }

  let audit = dayjsNY(auditDate);
  let upload = dayjs(lastDate, "MM/DD/YY");

  let diff = audit.diff(upload, "day");
  if (diff > 3 || diff < 1) {
    return false;
  }

  /**
   * We want to check for the day of the week if the difference is not 1.
   * We don't want to alert the user for every audit when auditing monday's activities
   */
  if (audit.day() === 1) {
    return true;
  } else {
    return diff === 1;
  }
}

export default areUploadDatesLinked;
