import {
  dateInputHandler,
  getDisabledTooltipContent,
} from "./DateInputFunctions";
import { CalendarIcon } from "../../../../../../../../../../../../BasePage/src";
import { useRedux } from "../../../../../../../../../../../../hooks";
import "./DateInput.scss";

// import { useDispatchContext, useStateContext } from "../../../../../../../../Context/Context"

import { DatePicker, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";
import { parseInTz } from "../../../../../../../../../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const { RangePicker } = DatePicker;

const DateInput = ({ allRow, elevationData }) => {
  // const { isWritable, fakeRentals, selectedService } = useStateContext()

  const [, setUnchangingFakeRentals] = useRedux("unchangingFakeRentals");
  const [fakeRentals, setFakeRentals] = useRedux("fakeRentals");
  const [selectedService] = useRedux("rentalsSelectedService");
  const [isWritable] = useRedux("rentalsIsWritable");

  // const dispatchContext = useDispatchContext()
  const selectedDate = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      (data) => data?.elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => id === allRow?.id)?.rentalDetails;

  //this is called when we use the date input
  const handleDateChange = (date) =>
    dateInputHandler({
      date,
      setUnchangingFakeRentals,
      setFakeRentals,
      allRow,
      fakeRentals,
      elevationData,
      selectedService,
    });

  //this function check if this row has any charge related with him
  const hasChargesChecked = getDisabledTooltipContent({
    selectedService,
    elevationData,
    fakeRentals,
    isWritable,
    allRow,
  });

  return (
    <div className="DateInputContainer">
      <Tooltip title={hasChargesChecked}>
        <RangePicker
          value={
            !!selectedDate?.startDate
              ? [
                  parseInTz(selectedDate?.startDate),
                  parseInTz(
                    !!selectedDate?.endDate ? selectedDate.endDate : parseInTz()
                  ),
                ]
              : null
          }
          presets={getRangePresets()}
          onChange={(date) => handleDateChange(date)}
          format="MM/DD/YYYY"
          popupClassName="filter-by-date-picker-dropdown"
          className="datePickerInputRentals"
          suffixIcon={<CalendarIcon />}
          disabled={!isWritable || hasChargesChecked}
        />
      </Tooltip>
    </div>
  );
};

export default DateInput;
