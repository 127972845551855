import { Tooltip } from "antd";

import { useStateContext } from "../../../../../../../Context/Context";
import "./event-cell-renderer.css";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const EventCellRenderer = ({ value, serviceLabel, estimationId }) => {
  // Context state
  const { errors } = useStateContext();

  const hasEventError =
    errors && errors[`${serviceLabel}/${estimationId}`]?.event;

  return (
    <div className="event-cell-renderer">
      <span className="event-name">{value}</span>
      <div
        className="event-status"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {!value ? (
          <Tooltip title={hasEventError || "Name must not be empty"}>
            <ExclamationCircleFilled style={{ color: "#EB4345" }} />
          </Tooltip>
        ) : (
          <CheckCircleFilled style={{ color: "#68C142" }} />
        )}
      </div>
    </div>
  );
};

export default EventCellRenderer;
