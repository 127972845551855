import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { message } from "antd";
import {
  osName,
  osVersion,
  browserVersion,
  deviceType,
} from "react-device-detect";
import { fetchAllData, recursivelyFindObj } from "../utils";
import { GOOGLE_API_KEY } from "../helpers/constants";

const logObjValueTypes = {
  category: "string",
  actionType: "string",
  recordId: "string",
  recordName: "string",
  topic: "string",
  label: "string",
  previousData: "object",
  currentData: "object",
  updatedKeys: "object",
  updatedAt: "number",
  nameOfUser: "string",
  cognitoUserId: "string",
  sessionInfo: "object",
  member: "string",
};

const checkValueTypes = (log) => {
  return Object.entries(log).every(
    ([key, val]) => !val || typeof val === logObjValueTypes[key]
  );
};

export const useEditLogs = () => {
  const location = useLocation();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [loading, setLoading] = useState(false);
  const [fetchedLogs, setFetchedLogs] = useState();

  const category = useMemo(() => {
    if (userConfiguration?.cognitoUserId) {
      return getPathnameCategory(userConfiguration, location);
    }
  }, [userConfiguration?.cognitoUserId, location?.pathname]);

  // if any prop it wills save it directly as single log
  const saveAddedLogs = async (logObj) => {
    const logsToSave = await getLogObj({
      userConfiguration,
      category,
      logObj,
    });

    if (logsToSave.length > 0) {
      return await API.post("editLogs", "/editLogs", {
        body: logsToSave,
      })
        .then(() => logsToSave)
        .catch((err) => console.log("Error posting logs", { err }));
    } else {
      console.log("Empty logs to save!!!");
      return [];
    }
  };

  const handleFetchLogs = async (props = {}) => {
    const { filters, keys } = props;

    setLoading(true);
    return await fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
        ...(filters && { filters: JSON.stringify(filters) }),
        ...(keys && { keysToInclude: JSON.stringify(keys) }),
      },
    })
      .then((logs) => {
        message.destroy();
        setFetchedLogs(logs);
        setLoading(false);

        return logs;
      })
      .catch((error) => {
        console.error("Error fetching logs: ", error);
        setLoading(false);
      });
  };

  return {
    saveAddedLogs,
    fetchedLogs,
    setFetchedLogs,
    handleFetchLogs,
    loading,
  };
};

export const getSessionInfo = async () => {
  const [geoLocation, ipAddress] = await Promise.all([
    getGeoLocation(),
    API.get("getIp", "/getIp"),
  ]);
  return {
    osName,
    osVersion,
    browserVersion,
    deviceType,
    address: geoLocation?.formatted_address || "Denied location access!",
    coords: geoLocation?.geometry?.location || "Denied location access!",
    ipAddress: ipAddress?.split(",")[0] || "Ip not found!",
  };
};

const getPathnameCategory = (userConfiguration, location) => {
  const pathElements = location?.pathname?.split("/") || [];

  const locationPathName = pathElements[1];

  if (locationPathName === "settings") {
    return recursivelyFindObj(
      userConfiguration?.routeConfig?.find(({ path }) => path === "/settings")
        ?.children || [],
      "path",
      location?.pathname,
      "children",
      "title"
    )?.obj;
  } else {
    return userConfiguration?.routeConfig?.find(
      (el) =>
        el?.path === `/${locationPathName}` ||
        el?.path === `/${locationPathName}/:id` ||
        el?.endpoint === locationPathName
    );
  }
};

const getLogObj = async ({ userConfiguration, category, logObj }) => {
  const sessionInfo = await getSessionInfo();

  return (Array.isArray(logObj) ? logObj : [logObj])
    .map((log) => {
      if (log) {
        const hasValidTypes = checkValueTypes(log);

        if (hasValidTypes) {
          return {
            category: log?.category || category?.title || "",
            actionType: log?.actionType || "Create",
            recordId: log?.recordId || "",
            recordName: log?.recordName || "",
            topic: log?.topic || "",
            label: log?.label || "",
            previousData: log?.previousData || {},
            currentData: log?.currentData || {},
            updatedKeys: log?.updatedKeys || [],
            updatedAt: log?.updatedAt || Date.now(),
            nameOfUser: log?.nameOfUser || userConfiguration?.nameOfUser || "",
            cognitoUserId:
              log?.cognitoUserId || userConfiguration?.cognitoUserId || "",
            sessionInfo,
          };
        } else {
          console.error(
            "This log " +
              log +
              " didn't saved because one of it's values contain different types from " +
              logObjValueTypes,
            {
              log,
              logObjValueTypes,
            }
          );
        }
      }
    })
    .filter(Boolean);
};

const getGeoLocation = () => {
  return new Promise(async (resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
          ).then((res) => res.json());

          resolve(response?.results?.[0]);
        },
        (error) => {
          resolve(error);
          // console.log("Geolocation error", { error });
        }
      );
    } else {
      // console.log("Geolocation not supported");
      resolve("Geolocation not supported");
    }
  });
};
