import { getRandomColor } from "../../../../utils";
import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const estimations = (
  IdHandler,
  updatedDynamicStates,
  programFields,
  engData,
  archData
) => {
  return {
    estSTATUS: () => ({
      //estSTATUS
      title: "Estimations Status",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Estimations",
        field: "estSTATUS",
        labels: programFields
          ?.find((a) => a?.fieldName === "Estimation Status")
          ?.fieldOptions?.map((a) => a.statusName),
        datasets: [
          {
            label: "Estimations",
            data: programFields
              ?.find((a) => a?.fieldName === "Estimation Status")
              ?.fieldOptions?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) => a.estSTATUS?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),

            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status colors")
              ?.fieldOptions?.Estimations?.map((a) => a.statusColor),
            borderWidth: 1,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //estSTATUS
    archAssignedTo: () => ({
      title: "Architect Assigned To",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Estimations",
        field: "archAssignedTo",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "archAssignedTo"
          )
        ),
        datasets: [
          {
            label: "Estimations",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "archAssignedTo"
              )
            ),

            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "archAssignedTo"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //archAssignedTo
    engAssignedTo: () => ({
      title: "Engineer Assigned To",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Estimations",
        field: "engAssignedTo",
        labels: Object.keys(
          repeatedElementSum(updatedDynamicStates?.[IdHandler], "engAssignedTo")
        ),
        datasets: [
          {
            label: "Estimations",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler],
                "engAssignedTo"
              )
            ),

            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler],
                "engAssignedTo"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //engAssignedTo
    statusByArch: () => ({
      title: "Status By Architect",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByArch",
        labels: programFields
          ?.find((a) => a?.fieldName === "Status by Architecture")
          ?.fieldOptions?.map((a) => a.statusName),
        datasets: [
          {
            label: "Estimations",
            data: programFields
              ?.find((a) => a?.fieldName === "Status by Architecture")
              ?.fieldOptions?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) =>
                      a.statusByArch?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),

            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status by Architecture")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //statusByArch
    statusByEng: () => ({
      title: "Status By Engineer",
      BodyComponent: "Pie",
      type: "Pie",
      id: IdHandler,
      BodyComponentParams: {
        table: "Estimations",
        field: "statusByEng",
        labels: programFields
          ?.find((a) => a?.fieldName === "Status by Engineering")
          ?.fieldOptions?.map((a) => a.statusName),
        datasets: [
          {
            label: "Estimations",
            data: programFields
              ?.find((a) => a?.fieldName === "Status by Engineering")
              ?.fieldOptions?.map((a) => a.statusName)
              ?.map(
                (status) =>
                  (updatedDynamicStates?.[IdHandler] || [])?.filter(
                    (a) =>
                      a.statusByEng?.toLowerCase() === status?.toLowerCase()
                  )?.length
              ),

            backgroundColor: programFields
              ?.find((a) => a?.fieldName === "Status by Engineering")
              ?.fieldOptions?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }), //statusByEng
    projectExecutive: () => ({
      title: "Estimations Project Executive",
      BodyComponent: "Pie",
      type: "Pie",
      BodyComponentParams: {
        table: "Estimations",
        field: "projectExecutive",
        labels: Object.keys(
          repeatedElementSum(
            updatedDynamicStates?.[IdHandler] || [],
            "projectExecutive"
          )
        ),
        datasets: [
          {
            label: "Estimations",
            label: "Estimations",
            data: Object.values(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                updatedDynamicStates?.[IdHandler] || [],
                "projectExecutive"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),

    projectManager: () => ({
      title: "Estimations Project Manager",
      BodyComponent: "Pie",
      type: "Pie",
      BodyComponentParams: {
        table: "Estimations",
        field: "projectManager",
        labels: Object.keys(
          repeatedElementSum(
            (updatedDynamicStates?.[IdHandler] || [])
              ?.map((a) => a?.projectManager)
              ?.flat(),
            "nameOfUser"
          )
        ),
        datasets: [
          {
            label: "Estimations",
            data: Object.values(
              repeatedElementSum(
                (updatedDynamicStates?.[IdHandler] || [])
                  ?.map((a) => a?.projectManager)
                  ?.flat(),
                "nameOfUser"
              )
            ),
            backgroundColor: Object.keys(
              repeatedElementSum(
                (updatedDynamicStates?.[IdHandler] || [])
                  ?.map((a) => a?.projectManager)
                  ?.flat(),
                "nameOfUser"
              )
            )?.map((a) => getRandomColor()),
            borderWidth: 0,
          },
        ],
      },
      id: IdHandler,
      width: "390px",
    }),
  };
};
