import { mouseEnter, mouseLeave } from "../utils";

const Filters = ({
  Icon,
  title,
  i,
  color,
  type,
  setType,
  isDarkMode,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="NotificationPopOverBodyFilterItemContainer filter-item"
      style={
        type === title
          ? { background: color, border: `1px solid ${color}` }
          : {
              background: isDarkMode ? "#12131b" : "#f4f5f6",
              border: "1px solid rgb(226, 226, 226)",
            }
      }
      onMouseEnter={() => type !== title && mouseEnter(i, color)}
      onMouseLeave={() => type !== title && mouseLeave(i, isDarkMode)}
    >
      <span
        className="NotificationPopOverBodyFilterItemContainerLabel filter-label"
        style={type === title ? { color: "#fff" } : { color: "#323338" }}
      >
        {title}
      </span>
      <span
        className="NotificationPopOverBodyFilterItemContainerIcon filter-icon"
        style={type === title ? { fill: "#fff" } : { fill: "#323338" }}
      >
        <Icon />
      </span>
    </div>
  );
};
export default Filters;
