import { InputNumber } from "antd";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./DimensionEditor.scss";

const DimensionEditor = ({ value = 0 }, ref) => {
  const [dimensions, setDimensions] = useState({ feet: 0, inches: 0 });
  const feetInput = useRef();

  const { feet, inches } = dimensions;

  /**
   * Updates the dimensions state (Inputs value)
   *
   * @param {String} unit key of the state object (feet | inches)
   * @param {Number} value Value of the InputNumber
   */
  const onValueChange = (unit) => (value) =>
    setDimensions((prev) => ({ ...prev, [unit]: +(+value).toFixed(2) }));

  //Only for "feet" input so when pressing tab it doesn't stop editing
  //the cell but moves focus to the "inches" input
  const onFeetKeyDown = (e) => e.stopPropagation();

  //Required by AgGrid so it can get the value
  useImperativeHandle(ref, () => {
    return {
      //converts the value to feet and returns it to AgGrid
      getValue: () => +(feet + inches / 12),
      isCancelBeforeStart: () => false,
    };
  });

  //Sets the focus to the "feet" input
  useLayoutEffect(() => {
    setTimeout(() => feetInput.current?.focus?.(), 0);
  }, []);

  //updates the state  whenever the value changes
  useEffect(() => {
    const valueToRefer = !isNaN(value) ? value : 0; //in case we don't have any value
    const calculatedFeet = parseInt(valueToRefer); //integral part of the value (feet)
    const calculatedInches = (valueToRefer - calculatedFeet) * 12; //decimal part of value * 12 (inches)

    setDimensions({
      feet: calculatedFeet,
      inches: Math.round(calculatedInches),
    });
  }, [value]);

  return (
    <div className="dimensionCellEditor" style={{ width: 250 }}>
      <InputNumber
        ref={feetInput}
        value={feet}
        addonAfter="ft."
        onChange={onValueChange("feet")}
        onKeyDown={onFeetKeyDown}
        controls={false}
      />
      <InputNumber
        value={inches}
        addonAfter="in."
        onChange={onValueChange("inches")}
        controls={false}
        min={0}
        max={12}
      />
    </div>
  );
};

export default forwardRef(DimensionEditor);
