import { Tick } from "../../../../../../../../../DynamicView/components/FilterView/FilterIcons";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../../commonComponents";
import { PercentageIcon } from "../../../../../../../../../BasePage/src";
import { newApplicationHandler } from "./changeRetenageModalFunction";
import { Close } from "../../../../../../../../../DynamicView/src";
import "./ChangeRetenageModal.scss";

import { InputNumber, message, Modal } from "antd";
import { useEffect, useState } from "react";
import { applyRetainageToAllServices } from "../../../Service/ServiceHeader/ServiceHeaderComponents/ChangeRetenageModal/changeRetenageModalFunction";
import { AgGridReact } from "ag-grid-react";
import ChangeRetainageActions from "./ChangeRetainageActions/ChangeRetainageActions";
import AccountingConfirmationModal from "../../../../../../../../../../commonComponents/AccountingConfirmationModal/AccountingConfirmationModal";
import { useSelector } from "react-redux";

const ChangeRetenageModal = ({ service }) => {
  const [isRetainageModalVisible, setIsRetainageModalVisible] = useState(false);
  const [retainageState, setRetainageState] = useState(service?.retainage);
  const { fakeApplication, isWritable, darkMode } = useStateContext();
  const dispatchContext = useDispatchContext();
  const { serviceId, estimationId } = service || {};
  const [retainageData, setRetainageData] = useState({});
  const [writeableObject, setWriteableObject] = useState(null);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const { applications } = useSelector((state) => state.applications);
  //close the modal
  const closeRetainageModal = () => {
    setIsRetainageModalVisible(false);
    setWriteableObject(null);
  };

  const setApplication = (newApplication) => {
    dispatchContext({ type: "SET_FAKE_APPLICATION", payload: newApplication });
    dispatchContext({
      type: "SET_UNCHANGING_FAKE_APPLICATION",
      payload: newApplication,
    });
  };

  //sets the new retenage and the new amounts
  const handleChangeRetainage = () => {
    //this function calculates the new values of application
    const newApplication = newApplicationHandler({
      fakeApplication,
      retainageState,
      estimationId,
      serviceId,
    });

    //sets the new application to context
    setApplication(newApplication);
    //close the modal
    closeRetainageModal();
  };

  const handleChangeRetForAll = () => {
    const newApplication = applyRetainageToAllServices({
      fakeApplication,
      writeableObject,
      applications,
    });
    //sets the new application to context
    setApplication(newApplication);
    //close the modal
    closeRetainageModal();
    console.log("writable object", { newApplication, writeableObject });
  };

  //handles the new value that we put on input
  const inputChangerHandler = (e) => {
    let initialValue = e;
    if (initialValue < 0) {
      setRetainageState(0);
      message.warning("Input less than 0!!");
    } else if (initialValue > 100 || isNaN(parseFloat(initialValue))) {
      // setRetainageState(100);
      message.warning("Input NOT valid!!");
      console.log("input not valid", initialValue);
    } else {
      setRetainageState(parseFloat(initialValue));
    }
  };
  useEffect(() => {
    if (isRetainageModalVisible && !!fakeApplication?.services) {
      const fakeService = fakeApplication?.services?.find(
        (s) => s?.label === service?.label
      );
      // console.log("service", fakeService);
      setRetainageState(fakeService?.retainage);
    }
  }, [JSON.stringify(fakeApplication?.services), isRetainageModalVisible]);
  // console.log("retainageData", retainageData);

  return (
    <div
      className="changePercentageApplicationContainer"
      onClick={(e) => e.stopPropagation()}
    >
      <MondayButton
        className={`changeRetainageSOVButton`}
        onClick={() => setIsRetainageModalVisible(true)}
        Icon={<PercentageIcon />}
        //disabled={!isWritable}
      >
        {isWritable ? "Change Retainage" : "View Retainage"}
      </MondayButton>

      <Modal
        open={isRetainageModalVisible}
        title={isWritable ? "Change Retainage" : "View Retainage"}
        destroyOnClose={true}
        maskClosable={false}
        wrapClassName={
          darkMode
            ? "changeRetenagePercentageApplicationModalDark"
            : "changeRetenagePercentageApplicationModalLight"
        }
        onCancel={closeRetainageModal}
        footer={
          isWritable ? (
            <div
              className="serviceFooterRetainageModal"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <MondayButton
                className="cancelMondayButton"
                Icon={<Close />}
                onClick={closeRetainageModal}
              >
                Cancel
              </MondayButton>
              {/* <MondayButton
							className="applyMondayButton"
							Icon={<Tick />}
							onClick={handleChangeRetainage}
						>
							Apply
						</MondayButton> */}
              <MondayButton
                className="applyMondayButton"
                Icon={<Tick />}
                onClick={() => setConfirmationVisible(true)}
                style={{ marginLeft: "10px" }}
              >
                Apply for All
              </MondayButton>
              <AccountingConfirmationModal
                {...{
                  darkMode,
                  visible: confirmationVisible,
                  setVisible: setConfirmationVisible,
                  onConfirm: () => handleChangeRetForAll(),
                  text: "Are you sure you want to apply this retainage to all services?",
                  title: "CONFIRMATION REQUIRED",
                  confirmButtonText: "Apply",
                }}
              />
            </div>
          ) : null
        }
      >
        {/* <div>
					<InputNumber
						className="changeRetainageInput"
						placeholder="Enter new Retainage"
						style={{ width: "450px" }}
						width="100px"
						value={retainageState}
						onChange={inputChangerHandler}
					/>
				</div> */}
        {isRetainageModalVisible && (
          <ChangeRetainageActions
            {...{
              fakeApplication,
              writeableObject,
              setWriteableObject,
              isRetainageModalVisible,
              applications,
              isWritable,
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default ChangeRetenageModal;
