import { handleAppliedAmountCellChanger } from "./functions/AppliedAmount/AppliedAmountFunctions";
import { updateRental } from "../../../../../../../Utils/rentalManipulation";
import { changeProgress } from "./functions/ChangeProgress/ChangeProgress";
import { handleNumberOfWeeksChange } from "./functions/NumberOfWeeks/NumberOfWeeks";

export const masterTableLockHandler = ({
  setFakeRentals,
  selectedService,
  elevationData,
  fakeRentals,
  params,
  setUnchangingFakeRentals,
}) => {
  console.log("masterTableLOCK", params);
  const thisElevation = elevationData?.elevationId;
  const thisPli = params?.data?.id;

  const lock = params.colDef.field === "lock" ? !params?.value : true;

  const newFakeRentals =
    //if the column that we want to edit is the lock/unlock column,return this function
    params?.colDef?.field === "lock"
      ? updateRental({
          selectedService,
          fakeRentals,
          thisElevation,
          thisPli,
          pliProps: { lock },
        })
      : params?.colDef?.field === "appliedAmount" //else if the column is applied amount return the object that is generated from this function
      ? handleAppliedAmountCellChanger({
          selectedService,
          thisElevation,
          fakeRentals,
          thisPli,
          params,
        })
      : params?.coldDef?.field === "numberOfWeeks"
      ? handleNumberOfWeeksChange({
          selectedService,
          thisElevation,
          fakeRentals,
          thisPli,
          params,
          setUnchangingFakeRentals,
          elevationData,
          setFakeRentals,
        })
      : //else if the column is progress return the object that is generated from this function
        changeProgress({
          selectedService,
          thisElevation,
          fakeRentals,
          thisPli,
          params,
        });

  //here we save changes to context
  setFakeRentals(newFakeRentals);
};
