import { groupArray } from "../../../../../SidebarPages/utils";
import { RenderDynamicComponents } from "../../../Components";
import StepHeader from "../StepHeader/StepHeader";
import { fieldsJSON } from "./subcontractorsStepData";
import "./SubcontractorsStep.scss";
import { useEffect } from "react";
import { validateForm } from "../../../../../SidebarPages/Fleet/utils";

const SubcontractorsStep = ({
  form,
  setValidateStepMethod,
  attorneys,
  electricians,
  boomCompanies,
  expeditors,
  safetyCompanies,
  insuranceBrokers,
}) => {
  const populatedFields = fieldsJSON({
    attorneys,
    electricians,
    boomCompanies,
    expeditors,
    safetyCompanies,
    insuranceBrokers,
  });

  const validateSubcontractorsStep = async (onSuccessFunction) => {
    validateForm(form, onSuccessFunction);
  };

  useEffect(() => {
    setValidateStepMethod(validateSubcontractorsStep);
  }, []);

  return (
    <div className="subcontractorsStepContainer">
      {/* <StepHeader title="Subcontractors" /> */}
      <div className="bodyContainer">
        <div className="inputsContainer">
          {groupArray(populatedFields, 3).map((inputsArr = [], i) => (
            <div key={i} className="inputsPair">
              {RenderDynamicComponents(inputsArr)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubcontractorsStep;
