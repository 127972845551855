export const layoutRemoveFunction = (layout, id) => {
  return {
    lg: layout?.lg.filter((a) => a.i !== id),
    a: layout?.a.filter((a) => a.i !== id),
    b: layout?.b.filter((a) => a.i !== id),
    md: layout?.md.filter((a) => a.i !== id),
    sm: layout?.sm.filter((a) => a.i !== id),
    xs: layout?.xs.filter((a) => a.i !== id),
    xxs: layout?.xxs.filter((a) => a.i !== id),
  }
}
