// import React from "react";
import { CircleF, OverlayViewF, PolygonF } from "@react-google-maps/api";

import { Marker } from "../../../../../../../commonComponents";

const circleOptions = {
  fillColor: "#4e8afa",
  strokeColor: "#0059ff",
  fillOpacity: 0.2,
  strokeWeight: 2,
};

const polygonOptions = {
  fillColor: "#d99f00",
  strokeColor: "#d99f00",
  fillOpacity: 0.4,
  strokeWeight: 2,
};

function JobsiteMarker({
  key,
  radius,
  address,
  onClick,
  position,
  geoFenceInfo,
}) {
  return (
    <OverlayViewF position={position} mapPaneName={"overlayMouseTarget"}>
      <div className="markerWrapper">
        <Marker
          key={key}
          address={address}
          onClick={onClick}
          coordinates={position}
        />
      </div>
      {!!radius && (
        <CircleF
          options={circleOptions}
          radius={parseFloat(radius)}
          center={position}
        />
      )}
      {!!geoFenceInfo?.length &&
        geoFenceInfo?.map((geofence) => {
          return (
            <PolygonF
              options={{
                options: polygonOptions,
                editable: false,
                draggable: false,
              }}
              path={geofence?.geoFenceInfo}
            />
          );
        })}
    </OverlayViewF>
  );
}

export default JobsiteMarker;
