import {
  useState,
  useMemo,
  useCallback,
  useContext,
  useRef,
  useEffect,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { message } from "antd";

import {
  matchGridColumns as columnDefs,
  matchGridDetails,
  ProjectMatchContext,
} from "../../data";
import { parseInTz } from "../../../NewDispatchModal/utils/dateFunctions";
import { RouteShower } from "../../../NewDispatchModal/components";
import FilteredProjectsModal from "../FilteredProjectsModal/FilteredProjectsModal";
import MultipleDispatchAddresses from "../MultipleDispatchAddresses/MultipleDispatchAddresses";
import ManualProjectMatchModal from "../ManualProjectMatchModal/ManualProjectMatchModal";

import "./MatchGridView.scss";

/**
 * @typedef MatchDataItem
 * @property {string} id
 * @property {string} name
 * @property {string} address
 * @property {LatLng} [position]
 * @property {GeoFenceInfo[]} [geoFenceInfo]
 * @property {boolean} [isNameSameAsAddress=true]
 */

//#region COMPONENT
const MatchGridView = forwardRef((props, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    filters,
    offProjectData,
    offScheduleData,
    updateViewType,
    dispatchRecords,
    filteredAddresses,
  } = useContext(ProjectMatchContext);

  //#region STATES
  const [openDispatch, setOpenDispatch] = useState();
  const [filteredProjectsOpen, setFilteredProjectsOpen] = useState(false);
  const [manualProjectMatchOpen, setManualProjectMatchOpen] = useState(false);
  const [multipleOptionsData, setMultipleOptionsData] = useState(
    /** @type {MatchDataItem[]} */ ([])
  );

  /** @type {React.MutableRefObject<AgGridReact>} */
  const gridRef = useRef(null);
  /** @type {React.MutableRefObject<string>} */
  const requestSelectionRef = useRef("");

  //#region FILTER HANDLER
  const handleFilterData = useCallback(() => {
    const data = [];
    const dataToConsider =
      filters["records"] === "Off Project"
        ? { ...offProjectData }
        : { ...offScheduleData };

    for (const data of filteredAddresses) {
      if (data.type !== filters["records"]) {
        continue;
      }

      delete dataToConsider[data.address];
    }

    if (
      Object.keys(filters).some((key) => {
        if (key === "records") {
          return false;
        }
        return !!filters[key];
      })
    ) {
      for (const address in dataToConsider) {
        const details = dataToConsider[address].filter((e) => {
          if (filters["driverId"] !== e.driverId && filters["driverId"]) {
            return false;
          }

          if (filters["fleetId"] !== e.fleetId && filters["fleetId"]) {
            return false;
          }

          if (
            filters["date"] !==
              parseInTz(e.dispatchDate).startOf("day").format() &&
            filters["date"]
          ) {
            return false;
          }

          return true;
        });

        const id = `${address}-${filters["records"]}`;
        if (gridRef.current?.api) {
          const node = gridRef.current.api.getRowNode(id);
          if (node && node?.expanded) {
            if (!details.length) {
              gridRef.current.api.applyTransaction({ remove: [{ id }] });
            } else {
              gridRef.current.api.applyTransaction({
                update: [{ id, details }],
              });
            }
          }
        }

        if (!details.length) {
          continue;
        }

        data.push({ address, id, details });
      }
    } else {
      for (const address in dataToConsider) {
        const id = `${address}-${filters["records"]}`;
        const details = dataToConsider[address];

        if (gridRef.current?.api) {
          const node = gridRef.current.api.getRowNode(id);
          if (node && node?.expanded) {
            if (!details.length) {
              gridRef.current.api.applyTransaction({ remove: [{ id }] });
            } else {
              gridRef.current.api.applyTransaction({
                update: [{ id, details }],
              });
            }
          }
        }

        data.push({ address, id, details });
      }
    }

    if (gridRef.current?.api) {
      gridRef.current?.api?.setRowData(data);
    }
  }, [filters, offProjectData, offScheduleData, filteredAddresses]);

  //#region GRID DATA
  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableColResize: true,
      sortable: true,
      filter: true,
      flex: 1,
      enableRowGroup: true,
      suppressSizeToFit: true,
      minWidth: 130,
      suppressDragLeaveHidesColumns: false,
      enablePivot: false,
    };
  }, []);

  const onFirstDataRendered = useCallback((event) => {
    const { api } = event;
    api.sizeColumnsToFit();
  }, []);

  const onCellClicked = useCallback((param) => {
    param.node.setExpanded(!param.node.expanded);

    setTimeout(() => {
      param.api.redrawRows({
        rowNodes: [param.node],
      });
    }, 0);
  }, []);

  const getRowId = useCallback((param) => {
    return param.data.id;
  }, []);

  const detailCellRendererParams = useMemo(() => {
    const { detailGridOptions, getDetailRowData } = matchGridDetails;

    return {
      detailGridOptions: {
        ...detailGridOptions,
        getRowId(param) {
          return param.data.activityId;
        },
        context: {
          showDispatchHandler(data) {
            const { dispatchId } = data;
            const dispatchToShow = dispatchRecords[dispatchId];
            if (!dispatchToShow) {
              message.error({
                content: "Something went wrong, could not find dispatch",
                key: "onDispatch",
              });
              return;
            }

            setOpenDispatch({
              ...dispatchToShow,
              routes: dispatchToShow["routes"].map((route) => ({
                ...route,
                fleetId: dispatchToShow["fleetId"],
                fleetName: dispatchToShow["fleetName"],
              })),
            });
          },
        },
      },
      getDetailRowData,
    };
  }, [dispatchRecords]);

  //#region FILTER EFFECT
  useEffect(() => {
    handleFilterData();
  }, [handleFilterData]);

  //#region REF HANDLE
  useImperativeHandle(
    ref,
    () => {
      return {
        textFilterHandler(text) {
          gridRef.current.api.setQuickFilter(text);
        },
        openFilteredProjectsModal() {
          setFilteredProjectsOpen(true);
        },
        openManualMatchModal() {
          setManualProjectMatchOpen(true);
        },
      };
    },
    []
  );

  //#region UPDATE VIEW HANDLER
  /**
   * @param {Object} data
   * @param {"PROJECT"|"ALL"} data.type
   * @param {string|null} data.address
   * @param {string[]|null} data.ids
   */
  async function updateViewTypeHandler(data) {
    await updateViewType(data, ({ error, data: callbackData, address }) => {
      switch (error) {
        case "NOT_FOUND":
          message.error({
            content: `${data["address"]} does not match any of our records!`,
            key: "addressInfo",
          });
          break;
        case "MULTIPLE_IDS":
          requestSelectionRef.current = address;
          setMultipleOptionsData(callbackData);
          break;
        default:
          break;
      }
    }).catch((err) => {
      message.error({
        content: `Something went wrong while getting information for ${data["address"]}`,
        key: "addressInfo",
      });
      console.log("Error getting information: ", err);
    });
  }

  /**
   * @param {MatchDataItem} choice
   */
  function onMultipleChoiceConfirm(choice) {
    updateViewType({
      address: requestSelectionRef.current || choice.address,
      ids: [choice.id],
      type: "PROJECT",
    });

    requestSelectionRef.current = "";
  }

  //#region JSX
  return (
    <Fragment>
      <div className="project-match-grid-view-container">
        <div
          className={`grid-container ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          } `}
        >
          <AgGridReact
            {...{
              columnDefs,
              getRowId,
              animateRows: true,
              embedFullWidthRows: true,
              ref: gridRef,
              suppressRowClickSelection: true,
              onCellClicked,
              detailCellRendererParams,
              onGridReady: handleFilterData,
              masterDetail: true,
              defaultColDef,
              detailRowHeight: 600,
              headerHeight: 47,
              onFirstDataRendered,
              context: {
                updateViewTypeHandler,
              },
              sideBar: {
                toolPanels: [
                  {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                  },
                  {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                  },
                ],
              },
            }}
          />
        </div>
      </div>
      {openDispatch && (
        <RouteShower
          {...{
            isDarkMode,
            onCancel() {
              setOpenDispatch(undefined);
            },
            open: !!openDispatch,
            routes: openDispatch["routes"],
            selectedDate: openDispatch["dispatchDate"],
          }}
        />
      )}
      {multipleOptionsData.length ? (
        <MultipleDispatchAddresses
          {...{
            multipleOptionsData,
            onCancel() {
              setMultipleOptionsData([]);
            },
            onConfirm: onMultipleChoiceConfirm,
            open: multipleOptionsData.length,
          }}
        />
      ) : null}
      {filteredProjectsOpen ? (
        <FilteredProjectsModal
          {...{
            open: filteredProjectsOpen,
            onCancel() {
              setFilteredProjectsOpen(false);
            },
          }}
        />
      ) : null}
      {manualProjectMatchOpen ? (
        <ManualProjectMatchModal
          {...{
            open: manualProjectMatchOpen,
            onCancel() {
              setManualProjectMatchOpen(false);
            },
          }}
        />
      ) : null}
    </Fragment>
  );
});

export default MatchGridView;
