import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Timeline } from "antd";

import LiveAuditContext from "../../../../LiveAuditContext";
import { FleetsLiveContext } from "../../../../../../utils";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";

import "./InitialPlanCard.scss";

const InitialPlanCard = forwardRef(({}, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { plannedTripsForDate, isWritable } = useContext(FleetsLiveContext);
  const { selectedVehicle, openDispatch } = useContext(LiveAuditContext);
  const [mode, setMode] = useState("VIEW_MODE");

  useEffect(() => {
    if (!isWritable) {
      setMode("VIEW_MODE");
    }
  }, [isWritable]);

  useImperativeHandle(
    ref,
    () => {
      return {
        changeMode() {
          setMode((prev) => {
            if (prev === "VIEW_MODE") {
              return "EDIT_MODE";
            }
            return "VIEW_MODE";
          });
        },
      };
    },
    []
  );

  let trips = plannedTripsForDate?.[selectedVehicle] || [];

  let items = [],
    tmpId = "";
  for (const trip of trips) {
    let c = trip["dispatchId"] !== tmpId;

    if (c) {
      tmpId = trip["dispatchId"];
    }

    items = items.concat([
      {
        color: "green",
        dispatchid: trip?.["dispatchId"],
        children: (
          <div className={`single-card-location ${trip?.["activityId"]}`}>
            <div className="location-arrive-time">
              <span>{`Depart: ${dayjsNY(
                trip?.["departAt"] || trip?.["startingTime"]
              ).format("MMM DD, hh:mm a")}`}</span>
              <div>
                <span>
                  Driver:&nbsp;<span>{trip?.["driverName"]}</span>,&nbsp;
                </span>
                <span>
                  Cargo:&nbsp;<span>{trip?.["cargo"]}</span>
                </span>
              </div>
            </div>
            <div className={`location-arrive${c ? " first-location" : ""}`}>
              {trip?.["pickUpLocation"]}
            </div>
          </div>
        ),
      },
      {
        color: "red",
        dispatchid: trip?.["dispatchId"],
        children: (
          <div className={`single-card-location ${trip?.["activityId"]}`}>
            <div className="location-arrive-time">
              <span>{`Arrive: ${dayjsNY(
                trip?.["arriveBy"] || trip?.["timeAtLocation"]
              ).format("MMM DD, hh:mm a")}`}</span>
            </div>
            <div className="location-arrive">{trip?.["dropOffLocation"]}</div>
          </div>
        ),
      },
    ]);
  }

  items = _.groupBy(items, (item) => item?.dispatchid);

  return (
    <div
      className={`audit-locations-card audit-initial-plan-card ${
        isDarkMode ? "initial-plan-dark" : ""
      }`}
    >
      <div className="locations-card-title">
        INITIAL PLAN
        {mode === "EDIT_MODE" && (
          <span style={{ color: "#fe4c4a", fontSize: "14px" }}>
            &nbsp;(EDIT MODE)
          </span>
        )}
      </div>
      <div className="locations-card-info-container" id="live-audit-initial">
        {Object.keys(items).map((dispatchId, index) => {
          return (
            <div
              className={`audit-dispatch-container${
                mode === "EDIT_MODE" ? " edit-mode-hover" : ""
              }`}
              key={index}
              onClick={() => {
                if (mode === "EDIT_MODE") {
                  openDispatch(dispatchId);
                }
              }}
            >
              <Timeline
                {...{
                  mode: "left",
                  items: items[dispatchId],
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default InitialPlanCard;
