import React from "react";
import { useMemoCompare } from "../../../../../../hooks/useMemoCompare";
import { CollapseIcon, ExpandMoreIcon } from "../../../../../../icons/index";
import { getRandomColor } from "../../../../../Header/forms/Scheduling/helpers/creators";
import { useRedux } from "../../../../hooks/useRedux";
import OptionHeader from "../option/OptionHeader";
import ServiceHeader from "../ServiceHeader/ServiceHeader";
import { Collapse, message, Button } from "antd";
import ElevationPanelHeader from "../elevationPanel/ElevationPanelHeader";
import ElevationPanelExtra from "../elevationPanel/ElevationPanelExtra";
import { ElevationContent } from "../elevationPanel/ElevationContent";
import { PlusCircleOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

function ServiceComponent({
  record,
  //   service,
  isWritable,
  stateSetter,
  pricingData,
  priceEdit,
  priceView,
  selectedPriceSchemesForService,
  borderColor,
  serviceIndex,
  // gridData,
  updateStateAndSave,
  fullScreenService,
  fullscreenizeService,
  savedData,
  agGridTheme,
  deFullscreenize,
  handleModifyOption,
  collapseAllElevations,
  expandAllElevations,
  handleRentPerServiceChange,
  handleAlternateRentPerServiceChange,
  handlePricePerUnitChange,
  handleDefaultPricePerUnitChange,
  onInputBlur,
  viewActivePanels,
  serviceId,
  ServicesIndex,
  appendElevation,
  handleElevationFieldChange,
  agGridApiForEachElevation,
  accessRights,
  serviceDefs,
  handleSave,
  estimationId,
  jobSiteAddress,
  estimation,
  saveProject,
  saveEstimation,
  saveServicesInOppHandler,
  setTheme,
  versionId,
  serviceDefinitions,
  frameworkComponents,
  onGridReady,
  onCellKeyPress,
  addonsCellValueChanged,
}) {
  const myKey = serviceId;
  const [gridData] = useRedux("takeOffTableData");
  const service = useMemoCompare(gridData?.[serviceIndex], (prev, next) => {
    return _.isEqual(prev, next);
  });
  return (
    <div>
      {" "}
      <div
        key={"Test"}
        className="serviceContainer"
        style={{
          boxShadow: `0px 0px 6px 3px ${getRandomColor()}77`,
        }}
      >
        <ServiceHeader
          {...{
            record,
            service,
            isWritable,
            stateSetter,
            pricingData,
            priceEdit,
            priceView,
            selectedPriceSchemesForService,
            borderColor,
            serviceIndex,
            // gridData,
            updateStateAndSave,
          }}
          fullscreenizeService={fullscreenizeService}
          //   fullScreenService={savedData.fullScreenService}
          agGridTheme={agGridTheme}
          deFullscreenize={deFullscreenize}
          handleModifyOption={handleModifyOption}
          collapseAllElevations={collapseAllElevations}
          expandAllElevations={expandAllElevations}
          handleRentPerServiceChange={handleRentPerServiceChange}
          handleAlternateRentPerServiceChange={
            handleAlternateRentPerServiceChange
          }
          handlePricePerUnitChange={handlePricePerUnitChange}
          handleDefaultPricePerUnitChange={handleDefaultPricePerUnitChange}
          onInputBlur={onInputBlur}
        />

        {service.serviceOptions.map((option, optionIndex) => {
          return (
            <div key={optionIndex}>
              {
                <div className="optionText">
                  {optionIndex === 0 ? "Primary" : "Alternate"}
                  <span
                    style={{
                      marginLeft: 10,
                      paddingBottom: 10,
                      fontSize: 14,
                    }}
                  >
                    <OptionHeader
                      serviceId={service.serviceId}
                      {...{
                        priceView,
                        priceEdit,
                        option,
                        service,
                        optionIndex,
                      }}
                    />
                  </span>
                </div>
              }
              {option.length === 0 ? (
                <div className="text-center">
                  No elevations to show. Get started by clicking the "New
                  Elevation" button below.
                </div>
              ) : (
                <Collapse
                  expandIcon={({ isActive }) => (
                    <div
                      className="dropdownIconContainer"
                      style={{
                        // color: "#fff",
                        // fill: "#fff",
                        // backgroundColor: isActive
                        //   ? "#5b5b5b"
                        //   : "#1264A3",
                        // background: "red",
                        // width: "32px",
                        // height: "32px",
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        // borderRadius: "50%",
                      }}
                    >
                      {isActive ? (
                        <CollapseIcon />
                      ) : (
                        <ExpandMoreIcon rotate={isActive ? 90 : 0} />
                      )}
                    </div>
                  )}
                  // expandIcon={
                  //   <span>
                  //     <ExpandMoreIcon />
                  //   </span>
                  // }
                  activeKey={
                    viewActivePanels?.[serviceIndex]?.[optionIndex] ?? []
                  }
                  onChange={(value) => {
                    message.loading("Loading...", 0);
                    handleCollapseChange(value, serviceIndex, optionIndex);
                    message.destroy();
                  }}
                >
                  {option.map((elevation, elevationIndex) => {
                    return (
                      <Panel
                        key={elevation.elevationId}
                        header={
                          <div onClick={(e) => e.stopPropagation()}>
                            <ElevationPanelHeader
                              {...{
                                elevation,
                                stateSetter,
                                handleElevationFieldChange,
                                serviceIndex,
                                optionIndex,
                                elevationIndex,
                                isWritable,
                                gridData,
                                updateStateAndSave,
                                agGridApiForEachElevation,
                                selectedPriceSchemesForService,
                                priceView,
                                accessRights,
                              }}
                            />
                          </div>
                        } //end elevation panel header
                        extra={
                          <ElevationPanelExtra
                            {...{
                              service,
                              isWritable,
                              gridData,
                              updateStateAndSave,
                              selectedPriceSchemesForService,
                              serviceIndex,
                              optionIndex,
                              elevationIndex,
                              agGridApiForEachElevation,
                              serviceDefs,
                              stateSetter,
                              handleSave,
                              priceView,
                              priceEdit,
                              accessRights,
                            }}
                          />
                        }
                      >
                        <ElevationContent
                          {...{
                            service,
                            serviceIndex,
                            optionIndex,
                            elevationIndex,
                            elevation,
                            ServicesIndex,
                            accessRights,
                          }}
                          dataEntryGrid={{
                            state: {
                              ...savedData,
                            },
                            props: {
                              estimation,
                              estimationId,
                              jobSiteAddress,
                              isWritable,
                              saveEstimation,
                              saveProject,
                              saveServicesInOppHandler,
                              agGridTheme,
                              setTheme,
                              versionId,
                              serviceDefinitions,
                            },
                            frameworkComponents,
                            onGridReady,
                            onCellKeyPress,
                            addonsCellValueChanged,
                            onBottomAddonChange,
                            addonsCellEditingStarted,
                            handleAddonsGridReady,
                            changeStateHandler,
                            saveDataFromRTDEditor,
                            updateSWSPPU,
                            saveHoistAddonsData,
                            handleInputElementChange,
                            onAddonRichTextChange,
                            saveSidewalkShedAddonsData,
                            handleGridCellFocused,
                            toggleApproveAll,
                            handleChange,
                            updateStateAndSave,
                            stateSetter,
                            resizeGridRows,
                            handleSave,
                            getAddonsCaption,
                            cellEditingStarted,
                            cellEditingStopped,
                            appendPLIRow: appendPLIRowHandle,
                            duplicatePLIRow: duplicatePLIRowHandle,
                            removePLIRow: removePLIRowHandle,

                            isWritable,
                          }}
                          optionIndex={optionIndex}
                          service={service}
                          serviceIndex={serviceIndex}
                          elevationIndex={elevationIndex}
                          elevation={elevation}
                          agGridTheme={agGridTheme}
                          gridData={gridData}
                          removePLIRow={removePLIRowHandle}
                        />
                      </Panel>
                    );
                  })}
                </Collapse>
              )}

              {!service.isScope && (
                <div className="text-center">
                  <Button
                    disabled={
                      !isWritable ||
                      !hasAccessRightsElevAdding?.write ||
                      !!!hasAccessRightsElevAdding
                    }
                    className="appendElevation"
                    onClick={() => {
                      // service.serviceId != 9999 ?

                      appendElevation(
                        serviceDefs,
                        gridData,
                        updateStateAndSave,
                        serviceIndex,
                        optionIndex
                      );
                      // : this.setState({
                      //     scopeSelectorModalVisible: true,
                      //   })
                    }}
                  >
                    <span>
                      New Elevation <PlusCircleOutlined />{" "}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          );
        })}

        {/* <>
          <>
            {serviceDefinitions?.find(
              (sE) => Number(sE?.serviceId) === Number(service?.serviceId)
            )?.hasServiceAddons === true && (
              <ServiceAddon
                {...{
                  service,
                  serviceIndex,
                  pricingData,
                  getAddonsCaption,
                  handleChangePricingData,
                  gridData,
                  updateStateAndSave,
                  serviceIndex,
                  saveThisEstimation,
                }}
                agGridTheme={agGridTheme}
              />
            )}{" "}
          </>
          <>
            {serviceDefinitions?.find(
              (sE) => Number(sE?.serviceId) === Number(service?.serviceId)
            )?.hasDocumentation === true && (
              <ServiceDocumentation
                {...{
                  service,
                  onServiceDocumentationChange,
                  agGridTheme: agGridTheme,
                }}
              />
            )}
          </>
        </> */}

        {/* {
          <IncludesExcludes
            {...{
              service,
              serviceIndex,
              gridData,
              updateStateAndSave,
            }}
          />
        } */}
      </div>
    </div>
  );
}

export default React.memo(ServiceComponent);
