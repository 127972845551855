import { isEmpty } from "lodash";
import sessionLogsQuickFilter from "./filterSessionLogs";
import dayjs from "dayjs";

function sortLogs(logs) {
  return logs.sort((a, b) => {
    return b?.sessionInfo?.logTime - a?.sessionInfo?.logTime;
  });
}
function applySessionLogsFilters({ allLogs = [], filters = {}, quickFilter }) {
  if (quickFilter) {
    return sortLogs(sessionLogsQuickFilter(allLogs, quickFilter));
  } else if (
    Object.keys(filters).length > 0 &&
    Object.keys(filters).some((key) => !isEmpty(filters[key]))
  ) {
    return sortLogs(filtersCheck(allLogs, filters));
  } else {
    return sortLogs(allLogs);
  }
}

export default applySessionLogsFilters;

function filtersCheck(logs, filters) {
  return logs.filter((log) => {
    const {
      sessionId,
      cognitoUserId,
      sessionStatus,
      nameOfUser,
      userDepartment,
      userRole,
      sessionInfo,
    } = log || {};
    const { ip, osName, browserName, logTime, identityId } = sessionInfo || {};
    return Object.entries(filters)?.every(([key, value]) => {
      if (value?.length > 0) {
        if (key === "nameOfUser") {
          return value.includes(cognitoUserId);
        } else if (key === "sessionStatus") {
          return value === sessionStatus;
        } else if (key === "quickFilter") {
          return sessionLogsQuickFilter(false, value, logTime);
        } else if (key === "dateRange") {
          const startDate = dayjs(value?.[0])?.valueOf();
          const endDate = dayjs(value?.[1])?.valueOf();
          if (!startDate || !endDate) {
            return true;
          }
          return logTime >= startDate && logTime <= endDate;
        } else if (key === "osName") {
          return value === osName;
        } else if (key === "browserName") {
          return value === browserName;
        } else if (key === "departments") {
          return value?.includes(userDepartment);
        } else if (key === "roles") {
          const newVal = value.map((val) => ({
            department: val.split("*")[0],
            role: val.split("*")[1],
          }));
          return newVal?.some(
            ({ department, role }) =>
              userRole === role && userDepartment === department
          );
        }
      }
    });
  });
}
