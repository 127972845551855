import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useMediaQuery } from "react-responsive";
import { Form, message, Modal } from "antd";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../../hooks";
import { useCreateDriveFolders } from "../../../../../hooks/useCreateDriveFolders";
import { getDriveFolderKeyName } from "../../../utils/getDriveFolderKeyName";
import { driveApi } from "../../../../../integrations/DriveRequest";
import {
  fetchAllData,
  filterTables,
  lazyFetch,
  updateDocumentTitle,
} from "../../../../../utils";
import { modalFooter, fixFormFields } from "./utils";
import { filterSchedulesOnDate } from "../../SafetyModal/utils/filterSchedulesOnDate";
import StatementsFields from "../../SafetyModal/components/StatementsFields";
import AskForTaskModal from "../../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/AskForTaskModal";
import InspectionModal from "../../../components/AgGridComponents/InspectionModal";
import { LoadableComp } from "../../../XComponents";
import { RenderDynamicComponents } from "../../../../Header/forms/Components";
import { BorderedTextCard } from "../../../Fleet/components";
import {
  DocUploader,
  DocumentationLogsModal,
} from "../../../Documentation/View/components/DocumentationModal/components";
import SignControllerButtons from "../../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/components/SignControllerButtons";
import { SignatureModal } from "../../../XComponents";
import {
  MultiLevelTreeLogs,
  ProgressComponent,
  Stepper,
} from "../../../../commonComponents";
import { InputComponent } from "../../../Fleet/components";
import WarningModal from "../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as WarningIcon } from "../../../../../icons/warningTriangle.svg";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import {
  pdfBorderedSection,
  separateWitnessForPdf,
  personalInjuryPdfBody,
} from "../../safetyPdf";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { PlusIcon } from "../../../../../assets";
import DynamicTeamModal from "../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import EmailBox from "../../../Communication/components/EmailBox/EmailBox";
import TasksBasePage from "../../../TaskManager/Tasks/TasksBasePage";
import ReactQuillRichText from "../../../../commonComponents/ReactQuillRichText/ReactQuillRichText";
import { useNavigate } from "react-router-dom";
import NewTaskModal from "src/components/SidebarPages/TaskManager/Tasks/TasksModals/NewTask/NewTaskModal";
import {
  changeDateOfIncident,
  checkSaveDraft,
  createSafety,
  deleteSafety,
  editSafety,
  onChangeAddress,
  onCloseModal,
  onGenerateSafetyPdf,
  populateSafetyForm,
  safetyBody,
  safetyEmailNotif,
} from "../../helpers";
import IncidentComments from "../../../Incidents/Components/IncidentComments";

export const viewClaimsModal = (claimsUsingSafety) => {
  Modal.info({
    title:
      "View Claims that are using this Safety Report. Click on the Claim to open it.",
    content: (
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {claimsUsingSafety?.map(({ claimId, claimNumber }) => {
          return (
            <a
              href={`/claims/${claimId}`}
              target="_blank"
              style={{
                color: "white",
                minWidth: "150px",
                maxWidth: "330px",
                height: 32,
                background: "#1264a3",
                borderRadius: 5,
                textAlign: "center",
                whiteSpace: "nowrap",
                display: "inline-block",
                verticalAlign: "middle",
                fontSize: 16,
                lineHeight: 2,
              }}
            >
              {claimNumber}
            </a>
          );
        })}
      </div>
    ),
    onOk() {},
    maskClosable: true,
    centered: true,
  });
};

function NewPersonalInjuryModal({
  visible,
  setVisible,
  refreshTable = () => {},
  rowObject,
  setVisibleReport,
  updateIncidentStatus = () => {},
  propProjId,
  propAddress,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
}) {
  const dispatch = useDispatch();

  const [
    { accessToken },
    { userConfiguration },
    { hotCredentials },
    { programFields },
    { authenticatedUser },
    { base64 },
    { notifications },
    { isDarkMode },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.hotCredentials,
    state.programFields,
    state.authenticatedUser,
    state.base64,
    state.notifications,
    state.darkMode,
  ]);
  const { preferences } = useSelector((state) => state.preferences);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const navigate = useNavigate();

  const {
    safetyApplicationId: defaultId,
    safetyApplicationObject: defaultObj,
  } = rowObject || {};
  const incidentId =
    rowObject?.safetyApplicationObject?.incidentId ||
    rowObject?.incidentId ||
    "";

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Personal Injury");

  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: "Personal Injury",
  });

  const driveRequest = driveApi({ accessToken });
  const [pdfId, setPdfId] = useState("");
  const [emailBox, setEmailBox] = useState(false);
  const [dynamicFields, setDynamicFields] = useState();
  const [descriptionValues, setDescriptionValues] = useState({});
  const [foremen, setForemen] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const [sign, setSign] = useState();
  const [editLogs, setEditLogs] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [divClickable, setDivClickable] = useState(true);
  const [steps, setSteps] = useState([]);
  const [statusesColors, setStatusesColors] = useState([]);
  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessStatements, setWitnessStatements] = useState([""]);
  const [witnessSignings, setWitnessSignings] = useState([""]);
  const [taskModalData, setTaskModalData] = useState();
  const [askTaskModal, setAskTaskModal] = useState();
  const [requiredTextArea, setRequiredTextArea] = useState([]);
  const [nameDisabled, setNameDisabled] = useState(
    !!defaultObj?.fullNameOfInjuredIndividual
  );
  const [assignedTo, setAssignedTo] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [changes, setChanges] = useState(
    rowObject?.safetyApplicationObject?.incidentId || rowObject?.incidentId
  );

  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    projectId: rowObject?.projectId || propProjId || "",
    accountId: rowObject?.accountId || "",
    scheduleId: rowObject?.scheduleId || "",
  });
  const [displayViewLogs, setDisplayViewLogs] = useState();
  const [tasksVisible, setTasksVisible] = useState(false);

  const [claimsUsingSafety, setClaimsUsingSafety] = useState([]);

  const [actionType, setActionType] = useState("Update");
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({
    categoryName: "Safety",
    actionType:
      actionType === "Delete" ? "Delete" : !!defaultId ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const disableAllFields =
    claimsUsingSafety?.length > 0 &&
    Array.isArray(claimsUsingSafety) &&
    rowObject?.safetyApplicationStatus !== "Draft" &&
    !!defaultId;

  const [videoTutorial, setVideoTutorial] = useState(false);

  const videoTutorialLink = programFields
    ?.filter((item) => item?.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item?.categoryName === "Safety")
    ?.subCategories[0]?.videos.find(
      (item) => item?.videoName === "New Personal Injury Safety"
    )?.videoLink;

  const [form] = Form.useForm();

  const selectedService = Form.useWatch("serviceBeingDone", form);
  const typeOfEquipment = Form.useWatch("typeOfEquipmentInvolved", form);
  const immediateActions = Form.useWatch(
    "immediateActionsTakenFollowingTheIncident",
    form
  );

  const safetyHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "16"
  );

  const windowHandler = useCallback((event) => {
    event.preventDefault();
    event.returnValue = "Are you sure you want to leave the page?";
    return "Are you sure you want to leave the page?";
  }, []);

  useEffect(() => {
    visible &&
      disableAllFields &&
      message.warning(
        "This form is disabled because this Safety Application is being used in one or more Claims and cannot be edited.",
        [5]
      );
  }, [disableAllFields, visible]);

  useEffect(() => {
    if (visible) {
      message.loading({
        content: "Opening Form...",
        key: "modalLoading",
      });
      Promise.allSettled([
        filterTables("programFields", "fieldName", "Safety Dynamic Fields"),
        lazyFetch({
          tableName: "crews",
          listOfKeys: ["crewId", "crewName", "foreman"],
          filterKey: "crewStatus",
          filterValue: "Active",
        }),
        lazyFetch({
          tableName: "projects",
          listOfKeys: ["projectName", "projectId", "accountId", "accountName"],
        }),
        lazyFetch({
          tableName: "scheduling",
          listOfKeys: [
            "scheduleAddress",
            "scheduleId",
            "scheduleDays",
            "projectId",
          ],
        }),
        API.get("teams", "/teams"),
        filterTables("claims", "claimCategory", "Personal Injury"),
      ])
        .then(
          ([
            { value: res },
            { value: crews },
            { value: pr },
            { value: sch },
            { value: teams },
            { value: claims },
          ]) => {
            message.destroy("modalLoading");
            window.addEventListener("beforeunload", windowHandler);
            setTeams(teams);
            setProjects(pr);
            setSchedules(sch);
            const claimExist = claims?.filter(
              ({ safety }) =>
                safety?.hasOwnProperty("id") && safety?.id === defaultId
            );
            setClaimsUsingSafety(claimExist);
            setSchedulesForDate(
              filterSchedulesOnDate(sch, defaultObj?.dateOfInjury || dayjsNY())
                ?.schedulesForDay || []
            );

            let defaultObject = {};
            let formFields = res[0]?.fieldOptions?.["Personal Injury"] || [];
            let tmpDescriptions = {};
            for (let i = 0; i < formFields?.length; i++) {
              if (formFields[i].type === "textEditor") {
                tmpDescriptions[formFields[i].formItemName] =
                  defaultObject?.[formFields[i].formItemName] || "";
              }
            }
            setForemen(
              crews
                ?.filter((e) => !!e.foreman)
                ?.map(({ crewId, crewName }) => ({
                  label: crewName,
                  value: crewId,
                }))
            );
            setDescriptionValues(tmpDescriptions);
            setDynamicFields(formFields);
          }
        )
        .catch((err) => {
          message.error({
            content: "Something Went Wrong!",
            key: "modalLoading",
          });
          console.error("Error Getting Fields: ", err);
          closeModal();
        });

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );

      setSteps(
        fieldObj["Statuses Of Categories"]["Safety"]
          ?.map(({ statusName }) => ({ title: statusName }))
          ?.filter((el) => {
            if (rowObject?.safetyApplicationStatus !== "Proceeded to Claim") {
              return el.title !== "Proceeded to Claim";
            } else {
              return el;
            }
          })
      );
      setStatusesColors(fieldObj["Statuses Of Categories"]["Safety"]);
    }

    return () => {
      window.removeEventListener("beforeunload", windowHandler);
    };
  }, [visible, rowObject]);

  const fieldsJSON = useMemo(() => {
    if (!dynamicFields) {
      return [];
    }

    return fixFormFields({
      dynamicFields,
      descriptionValues,
      setDescriptionValues,
      selectedService,
      typeOfEquipment,
      immediateActions,
      nameDisabled,
      userConfiguration,
      foremen,
      // disabledFieldsForIncident,
      setRequiredTextArea,
      projects,
      schedulesForDate,
      changeDateOfIncident(date) {
        changeDateOfIncident({
          date,
          form,
          schedules,
          setSchedulesForDate,
          setAddressSelectionInfo,
          addressField: "addressOfInjury",
          addressSelectionInfo,
        });
      },
      onChangeAddress(val, option) {
        onChangeAddress({
          val,
          option,
          projects,
          schedulesForDate,
          setAddressSelectionInfo,
        });
      },
      setChanges,
      disableAllFields,
      addressSelectionInfo,
      navigate,
      defaultId,
      form,
      propAddress,
    });
  }, [
    dynamicFields,
    descriptionValues,
    selectedService,
    typeOfEquipment,
    immediateActions,
    nameDisabled,
    foremen,
    schedulesForDate,
  ]);
  useEffect(() => {
    if (!!defaultId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: rowObject?.safetyApplicationId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setEditLogs(res);
        })
        .catch((err) => {
          console.error("Error Getting Logs: ", err);
          setEditLogs([]);
        });
    }
    if (!!rowObject && !!dynamicFields) {
      populateSafetyForm({
        form,
        pdfId,
        rowObject,
        statusKey: "safetyApplicationStatus",
        fieldsObject: "safetyApplicationObject",
        dynamicFields,
        setSign,
        statusesColors,
        setPdfId,
        setAssignedTo,
        setCurrentStep,
        setSelectedTeam,
        setWitnessCount,
        setUploadedFiles,
        setWitnessSignings,
        setWitnessStatements,
        setDescriptionValues,
      });
      if (!!defaultId) {
        if (!!rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
          setDivClickable(false);
        }
        let { viewLogs = {} } = rowObject;
        viewLogs = {
          ...viewLogs,
          [authenticatedUser?.sub]: {
            lastSeen: Date.now(),
            name: userConfiguration?.nameOfUser,
          },
        };

        API.put("safety", `/safety/${rowObject?.safetyApplicationId}`, {
          body: {
            viewLogs,
          },
        })
          .then(() => {
            setDisplayViewLogs(viewLogs);
          })
          .catch((err) => console.error("Error Updating View Logs: ", err));
      }
    }
  }, [rowObject, dynamicFields]);

  async function onSave(saveDraft = false) {
    let findRequiredArea = Object.keys(descriptionValues)?.filter((key) => {
      if (requiredTextArea.includes(key) && !descriptionValues[key]) {
        return key;
      }
    });

    if (findRequiredArea?.length > 0 && !saveDraft) {
      findRequiredArea?.forEach((key) => {
        message.error(
          `${
            dynamicFields?.find((el) => el.formItemName === key)?.label
          } is required`
        );
      });
      return;
    }
    if (!sign && !saveDraft) {
      message.error("Please Sign this Safety Report");
      return;
    }

    let allFolders = null;
    if (
      !driveFunctions.parentFolderId &&
      !rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
      !!form.getFieldValue("fullNameOfInjuredIndividual")
    ) {
      try {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${(
            form.getFieldValue("fullNameOfInjuredIndividual") || "Draft"
          ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: safetyHotCredential?.credentialValue,
        });
        allFolders = folders;
      } catch (error) {
        console.error("error: ", error);
        allFolders = null;
      }
    }

    const { parentFolderId } = driveFunctions;

    const driveFolderCondition = () => {
      if (rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return rowObject?.googleDriveFolderIds;
      } else if (parentFolderId) {
        return driveFunctions?.allFolders;
      } else {
        return allFolders;
      }
    };

    message.loading({
      content: "Saving...",
      key: "safetyLoading",
      duration: 0,
    });

    const { tmpBody = {} } = safetyBody({
      form,
      sign,
      assignedTo,
      currentStep,
      selectedTeam,
      witnessCount,
      nameKey: "fullNameOfInjuredIndividual",
      category: "Personal Injury",
      projects,
      schedules,
      statusesColors,
      incidentId,
      saveDraft,
      table: "safety",
      dynamicFields,
      witnessSignings,
      requiredTextArea,
      witnessStatements,
      userConfiguration,
      descriptionValues,
      addressSelectionInfo,
      googleDriveFolderIds: driveFolderCondition(),
      formattedFiles,
    });
    if (!!defaultId) {
      setVisibleCreationProgress(rowObject);
      setCancelModalVisible(false);

      await editSafety({
        table: "safety",
        tmpBody,
        nameKey: "fullNameOfInjuredIndividual",
        category: "Personal Injury",
        rowObject,
        editAction: "onPersonalInjuryEdit",
        authenticatedUser,
        userConfiguration,
        customLogObj(data) {
          return {
            fullNameOfForeman:
              foremen?.find(({ value }) => value === data?.fullNameOfForeman)
                ?.label || "",
            reportPreparedBy: userConfiguration?.allUsers?.Items?.find(
              ({ identityId }) => identityId === data?.reportPreparedBy
            ),
          };
        },
        refreshTable,
        updateProgressStatus,
        saveAddedLogs,
      });

      // onClose();
    } else {
      setCancelModalVisible(false);
      await createSafety({
        table: "safety",
        form,
        dispatch,
        nameKey: "fullNameOfInjuredIndividual",
        category: "Personal Injury",
        tmpBody,
        loggedOnTasks,
        programFields,
        saveDraft,
        rowObject,
        incidentId,
        assignedTo,
        preferences,
        createAction: "onPersonalInjuryCreation",
        userConfiguration,
        refreshTable(res) {
          refreshTable(
            [res].reduce(
              (acc, val) => [
                ...acc,
                {
                  ...val,
                  addressOfInjury:
                    val?.safetyApplicationObject?.["addressOfInjury"],
                },
              ],
              []
            )
          );
        },
        setAskTaskModal,
        setUploadedFiles,
        updateIncidentStatus,
        // onClose,
        handleSavedRecord,
        setVisibleCreationProgress,
        updateProgressStatus,
        hideProgress,
        saveAddedLogs,
      }).then(
        (result) =>
          result?.safetyApplicationStatus !== "Draft" && nextStepHandler()
      );
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  }

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (_, data) => {
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== data.value));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };

  async function onClose(param = {}) {
    await onCloseModal({
      form,
      param,
      driveRequest,
      nameKey: "fullNameOfInjuredIndividual",
      rowObject,
      objectKey: "safetyApplicationObject",
      driveFunctions,
      filesToBeDeleted,
      setVisible,
      folderDriveKeyName,
      setUploadedFiles,
      updateDocumentTitle,
      setFilesToBeDeleted,
      setCancelModalVisible,
      saveAddedLogs,
    });
  }

  //upload button handler
  const uploaderButton = document.querySelector(".uploaderButton");
  async function onContinueToUpload() {
    form
      .validateFields(["fullNameOfInjuredIndividual"])
      .then(async () => {
        const { parentId } = await driveFunctions.create({
          parentFolderName: `${form.getFieldValue(
            "fullNameOfInjuredIndividual"
          )} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: safetyHotCredential?.credentialValue,
        });
        setNameDisabled(true);
        setCancelModalVisible(false);
        setDivClickable(false);
        setTimeout(() => {
          if (!!uploaderButton) {
            uploaderButton.click();
          }
        }, 1000);
      })
      .catch((err) => {
        setCancelModalVisible(false);
        console.error("Error Upload: ", err);
      });
  }

  function createPdfBody() {
    let tmpFields = fieldsJSON?.map((fields) => {
      if (fields?.title === "General Information") {
        return {
          ...fields,
          fields: [
            ...fields?.fields,
            {
              label: "Team Members",
              value: selectedTeam?.map((el) =>
                el?.members?.map((e) => e?.nameOfUser)
              ),
            },
          ],
        };
      }
      return fields;
    });

    const configPdfData = fieldsJSON?.map((el) => {
      return pdfBorderedSection({
        title: `${el?.title}`,
        body: personalInjuryPdfBody({
          category: el?.title,
          fieldsJSON: tmpFields,
          rowObject,
          foremen,
          userConfiguration,
        }),
      });
    });
    const witnessList = separateWitnessForPdf({
      defaultId,
      rowObject,
      witnessCount,
      witnessSignings,
    });

    const witnessPdfData = witnessList?.map((el) =>
      pdfBorderedSection({
        title: "Witness",
        body: el,
      })
    );

    return configPdfData?.concat(!!witnessList?.length ? witnessPdfData : []);
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const filterUsersByAccess = userConfiguration?.allUsers?.Items.reduce(
    (acc, user) =>
      !!user?.routeConfig?.find(
        ({ title, write }) => title === "Safety" && write === true
      ) &&
      user.cognitoUserId !== userConfiguration.cognitoUserId &&
      !user?.isSuspended
        ? [
            ...acc,
            {
              cognitoUserId: user?.cognitoUserId,
              nameOfUser: user?.nameOfUser,
              userName: user?.userName,
            },
          ]
        : acc,
    []
  );

  function checkForChanges() {
    if (!defaultId) {
      let requiredFields = fieldsJSON
        ?.find(({ title }) => title === "General Information")
        ?.fields?.filter(({ required }) => required)
        ?.map(({ formItemName }) => formItemName);
      let fields = form.getFieldsValue(requiredFields);
      if (Object.values(fields).some((value) => !!value)) {
        setCancelModalVisible(true);
      } else {
        onClose();
      }
    } else {
      setCancelModalVisible(true);
    }
  }

  function onCancel() {
    if (!!changes || !!driveFunctions?.parentFolderId) {
      checkForChanges();
    } else if (!changes && !defaultId) {
      checkForChanges();
    } else {
      onClose();
    }
  }
  async function sendEmailNotification() {
    await safetyEmailNotif({
      table: "safety",
      category: "Personal Injury",
      rowObject,
      defaultId,
      authenticatedUser,
      userConfiguration,
      saveAddedLogs,
    });
  }

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  };

  return (
    <Modal
      {...{
        open: visible,
        onCancel,
        title: (
          <CustomModalHeader
            title={"Personal Injury Form"}
            onClick={() => {
              !!videoTutorialLink
                ? setVideoTutorial(true)
                : message.info("No video tutorial available for this form");
            }}
          />
        ),
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Personal Injury Form" });
        },
        closable: true,
        centered: true,
        style: {
          display: !!dynamicFields ? "" : "none",
        },
        wrapClassName: `safetyDynamicFormsWrap ${
          isDarkMode && "safetyDynamicFormsWrapDark"
        }`,
        closeIcon: <XIcon />,
        className: "safetyDynamicForms",
        footer: modalFooter({
          onCancel,
          changes,
          isDarkMode: isDarkMode,
          claimsUsingSafety,
          setTasksVisible,
          onSave() {
            checkSaveDraft({
              changes,
              form,
              descriptionValues,
              requiredTextArea,
              saveFunction: onSave,
              setVisible,
              setCancelModalVisible,
              populatedInputFields: dynamicFields,
              incidentId,
            });
          },
          form,
          defaultId,
          onDelete() {
            claimsUsingSafety?.length > 0
              ? message.error({
                  content:
                    "This Safety Report is linked to a Claim. Please delete the Claim first.",
                  key: "messageDeleting",
                })
              : setCancelModalVisible("Delete");
          },
          onShowLogs() {
            setEditLogsVisibility(true);
          },
          onEmail: () => {
            setEmailBox(true);
            !pdfId && message.info("There is no PDF file for this report");
          },
          onReportView: () => setVisibleReport(true),
          hasReport: true,
          onGeneratePdf() {
            onGenerateSafetyPdf({
              base64,
              category: "Personal Injury",
              rowObject,
              pdfId,
              accessToken,
              driveFunctions,
              defaultObj,
              setPdfId,
              folderDriveKeyName,
              createPdfBody,
            });
          },
          selectedTeam,
          safetyName: rowObject?.safetyName,
        }),
      }}
    >
      <LoadableComp loading={!dynamicFields}>
        {isMobileView ? (
          <div className="stepper-section-mobile">
            <InputComponent
              {...{
                label: "Status",
                type: "select",
                dropdownClassName: isDarkMode && "darkDropDown",
                formItemName: "stepper",
                disabled: disableAllFields,
                customOptions: statusesColors?.map(
                  ({ statusId, statusName }) => ({
                    value: statusId,
                    label: statusName,
                  })
                ),
                onSelect(e) {
                  setCurrentStep(e);
                  setChanges(true);
                },
                defaultValue: currentStep,
              }}
            />
          </div>
        ) : (
          <div className="stepper-section">
            <Stepper
              {...{
                currentStep,
                setCurrentStep(e) {
                  form.setFieldValue("stepper", e);
                  setCurrentStep(e);
                  setChanges(true);
                },
                steps,
                statusesColors,
                stepperClassName: "stepper-component",
                disabled: disableAllFields,
                stepRenderer: false,
              }}
            />
          </div>
        )}
        <Form form={form} onFieldsChange={() => setChanges(true)}>
          <div className="form-section">
            {fieldsJSON?.map(({ title, fields }) => {
              let sortedFields = fields;
              if (sortedFields.some((el) => !!el?.index)) {
                sortedFields = sortedFields.sort((a, b) => a.index - b.index);
              }
              return (
                <BorderedTextCard
                  title={title}
                  key={title}
                  isDarkMode={isDarkMode}
                >
                  {RenderDynamicComponents(sortedFields, { form })}
                  {title?.toLowerCase() === "additional information" && (
                    <>
                      <InputComponent
                        mode="multiple"
                        required
                        showSearch
                        label="Assign To"
                        placeholder="Select some users"
                        dropdownClassName={isDarkMode && "darkDropDown"}
                        disabled={disableAllFields}
                        type="select"
                        formItemName="assignedTo"
                        onChange={(value, option) => setAssignedTo(option)}
                        options={filterUsersByAccess?.map(
                          ({ cognitoUserId, nameOfUser, userName }, i) => (
                            <Option
                              key={i}
                              label={nameOfUser}
                              value={nameOfUser}
                              cognitoUserId={cognitoUserId}
                              userName={userName}
                            />
                          )
                        )}
                      />
                      <div className="signings">
                        <div className="signContainer">
                          <img src={sign} alt="" />
                        </div>
                        <SignControllerButtons
                          {...{
                            setSignatureModalVisible,
                            sign,
                            setSign,
                            setWhichSignToChange,
                            existingSignature: "",
                            signDisabled: disableAllFields,
                            removeDisabled: disableAllFields,
                          }}
                        />
                      </div>
                    </>
                  )}
                  {title?.toLowerCase() === "general information" && (
                    <div style={{ display: "flex", gap: "20px" }}>
                      <InputComponent
                        mode="multiple"
                        type="select"
                        label="Team"
                        required={true}
                        formItemName="team"
                        dropdownClassName={isDarkMode && "darkDropDown"}
                        allowClear={false}
                        customOptions={teams?.map((team) => ({
                          value: team?.teamName,
                          label: team?.teamName,
                          members: team?.members?.map(
                            ({
                              identityId,
                              nameOfUser,
                              cognitoUserId = "",
                            }) => ({
                              identityId,
                              nameOfUser,
                              cognitoUserId,
                            })
                          ),
                        }))}
                        onSelect={onTeamSelect}
                        onDeselect={onTeamDeselect}
                        showSearch={true}
                        disabled={disableAllFields}
                      />
                      {!!selectedTeam?.length && (
                        <MondayButton
                          {...{
                            onClick: () => {
                              setTeamModalVisible(true);
                            },
                            Icon: <PlusIcon />,
                            style: { marginTop: "25px" },
                          }}
                          disabled={disableAllFields}
                        >
                          Team Members
                        </MondayButton>
                      )}
                    </div>
                  )}
                </BorderedTextCard>
              );
            })}
            <StatementsFields
              {...{
                form,
                fieldCount: witnessCount,
                setFieldCount: setWitnessCount,
                dynamicStatements: witnessStatements,
                dynamicSignings: witnessSignings,
                setDynamicStatements: setWitnessStatements,
                setDynamicSignings: setWitnessSignings,
                disableFields: disableAllFields,
                isDarkMode,
              }}
            />
            <BorderedTextCard
              title="Notes"
              className="notesCard"
              isDarkMode={isDarkMode}
            >
              <IncidentComments
                {...{
                  form,
                  nameKey: "fullNameOfInjuredIndividual",
                  rowObject,
                  setChanges,
                  userConfiguration,
                  refreshTable,
                  setChanges,
                  table: "safety",
                }}
              />
            </BorderedTextCard>
          </div>
          <div className="uploaderSection">
            <div
              style={{
                position: "relative",
              }}
            >
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: divClickable ? 10 : 0,
                }}
                onClick={() => {
                  divClickable && setCancelModalVisible("Upload");
                }}
              ></div>
              <DocUploader
                {...{
                  uploadedFiles,
                  onPickerSuccess: ({ uploadedFiles }) => {
                    setChanges(true);
                    onPickerSuccess({ uploadedFiles });
                  },
                  onDelete,
                  disabled:
                    (!rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
                      !driveFunctions.parentFolderId) ||
                    disableAllFields,
                  onChange() {},
                  folderId:
                    rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
                    driveFunctions.parentFolderId,
                }}
              />
            </div>
            {!!rowObject?.safetyApplicationObject?.incidentGoogleDriveUpload &&
              rowObject?.safetyApplicationObject?.incidentGoogleDriveUpload
                ?.length > 0 && (
                <div className="safetyFilesContainer">
                  <div className="safetyFilesTitle">
                    <hr className="safetyFilesTitleLine" />
                    <span className="safetyFilesTitleText">Incident Files</span>
                    <hr className="safetyFilesTitleLine" />
                  </div>
                  <DocUploader
                    {...{
                      uploadedFiles:
                        rowObject?.safetyApplicationObject
                          ?.incidentGoogleDriveUpload,
                      disabled: true,
                    }}
                  />
                </div>
              )}
          </div>
        </Form>
        {askTaskModal && (
          <AskForTaskModal
            {...{
              title: "Task request",
              span: "Would you like to create a task for this safety report?",
              askTaskModal,
              createTask() {
                message.loading({
                  content: "Opening Task Form...",
                  key: "taskLoadingFromSafety",
                });

                let tData = structuredClone(askTaskModal);
                setAskTaskModal();
                setTaskModalData(tData);
              },
              cancelTaskModal() {
                setAskTaskModal();
                onClose();
              },
            }}
          />
        )}
        {teamModalVisible && (
          <DynamicTeamModal
            {...{
              open: teamModalVisible,
              setOpen: setTeamModalVisible,
              selectedTeam,
              setSelectedTeam,
              isDarkMode,
              ClearOptions: () => {
                setTeamModalVisible(false);
              },
            }}
          />
        )}
        {tasksVisible && (
          <InspectionModal
            {...{
              visible: tasksVisible,
              onCancel: () => {
                setTasksVisible(false);
              },
              title: `Task for ${taskModalData?.safetyName}`,
              width: 1600,
              footer: false,
              closable: true,
              closeIcon: <XIcon fill="#333238" />,
              className: `header-tasks-modal-light`,
            }}
          >
            <TasksBasePage
              {...{
                customTaskProps: {
                  rowData: rowObject,
                  taskTopic: "Safety",
                  taskTopicId: defaultId,
                  taskRelatedTo: rowObject?.safetyName,
                },
              }}
            />
          </InspectionModal>
        )}
        {!!taskModalData && (
          <NewTaskModal
            {...{
              newTaskModal: !!taskModalData,
              setNewTaskModal() {
                setTaskModalData({});
                setVisible(false);
              },
              customTaskProps: {
                rowData: taskModalData,
                taskTopic: "Safety",
                taskTopicId: taskModalData?.safetyApplicationId,
                taskRelatedTo: taskModalData?.safetyName,
              },
            }}
          />
        )}
        {videoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: videoTutorial,
              setVisible: setVideoTutorial,
              url: videoTutorialLink,
              title: "Personal Injury Form Tutorial",
            }}
          />
        )}
        {emailBox && (
          <div className="safetyEmailBox">
            <EmailBox
              {...{
                // safety: true,
                // isFull: true,
                onClose: () => setEmailBox(false),
                setEmails: () => {},
                rowData: rowObject,
                onSave: (e, type, setLoading) => {
                  onEmailSave({ e, type, setLoading });
                  // setEmailBox(false);
                },
                isRedirected: [pdfId, ...uploadedFiles?.map((el) => el?.id)],
                isFromCharge: true,
                sendEmailNotification,
                sendNotification: true,
                proppedTemplateCat: "Safety",
              }}
            />
          </div>
        )}
        <SignatureModal
          visible={signatureModalVisible}
          setVisible={setSignatureModalVisible}
          setSign={whichSignToChange?.setFunc}
          isDarkMode={isDarkMode}
        />
        {editLogsVisibility && (
          <DocumentationLogsModal
            {...{
              visible: editLogsVisibility,
              setVisible: setEditLogsVisibility,
              logsData: editLogs,
              title: "Property Damage Logs",
              viewLogs: displayViewLogs,
            }}
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Safety",
              actionType:
                actionType === "Delete"
                  ? "Delete"
                  : !!defaultId
                  ? "Update"
                  : "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                setVisibleCreationProgress(false);
                actionType === "Delete"
                  ? onClose({ deleteAction: true, deletePostLogsBody })
                  : onClose();
              },
            }}
          />
        )}

        <WarningModal
          visible={cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            {cancelModalVisible === "Upload" ? (
              <>
                <strong style={{ textAlign: "center" }}>
                  If you choose to continue, you will not be able to change the
                  Name Of Injured Individual anymore
                </strong>
                <p>Are you sure you want to continue?</p>
              </>
            ) : cancelModalVisible === "Delete" ? (
              <p>Are you sure you want to delete?</p>
            ) : defaultId && cancelModalVisible !== "saveDraft" ? (
              <p>Are you sure you want to cancel?</p>
            ) : (
              <div style={{ textAlign: "center" }}>
                <strong>
                  {cancelModalVisible !== "saveDraft"
                    ? "You are about to exit the form with unsaved data"
                    : "You have made some changes"}
                </strong>
                <p>Would you like to save the current changes as a draft?</p>
              </div>
            )}
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  if (defaultId || cancelModalVisible === "Upload") {
                    setCancelModalVisible(false);
                  } else {
                    onClose({
                      deleteAction: true,
                    });
                  }
                }}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (cancelModalVisible === "Upload") {
                    onContinueToUpload();
                  } else if (cancelModalVisible === "Delete") {
                    setCancelModalVisible(false);
                    setActionType("Delete");

                    deleteSafety({
                      dispatch,
                      nameKey: "fullNameOfInjuredIndividual",
                      category: "Personal Injury",
                      rowObject,
                      notifAction: "onPersonalInjuryDeletion",
                      notifications,
                      authenticatedUser,
                      userConfiguration,
                      refreshTable,
                      // onCloseWarningModal: onClose,
                      setVisibleCreationProgress,
                      updateProgressStatus,
                      saveAddedLogs,
                    });
                  } else {
                    if (defaultId && cancelModalVisible !== "saveDraft") {
                      onClose({
                        cancel: true,
                      });
                    } else {
                      onSave(true);
                    }
                  }
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      </LoadableComp>
    </Modal>
  );
}

export default NewPersonalInjuryModal;
