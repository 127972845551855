import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../../DEG/components/modalComponents/utils/cellFunctions";

export const analyticColumns = [
  {
    headerName: "Employee Full Name",
    field: "employeeFullName",
  },
  {
    headerName: "Employee Id",
    field: "employeeId",
  },
  {
    headerName: "Punch Date",
    field: "punchDate",
    valueGetter: ({ data }) => dayjsNY(data?.punchDate).format(DEG_DATE_FORMAT),
  },
  {
    headerName: "Punch Time",
    field: "punchTime",
    valueGetter: ({ data }) => dayjsNY(data?.punchTime).format(DEG_TIME_FORMAT),
  },
  {
    headerName: "Punch Type",
    field: "punchType",
  },
  {
    headerName: "Jobsite Match",
    field: "jobsiteMatch",
    valueGetter: ({ data }) => data?.jobsiteMatch?.jobName,
  },
];
