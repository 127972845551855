import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  queryRegex,
  uuidRegex,
} from "../components/SidebarPages/Documentation/View/documentationViewData";
import { getAccessRights } from "../utils";

export const useAccessRights = (params = {}) => {
  const { title = "", child } = params;
  // console.log({ child });
  let location = useLocation();
  const formattedPath = location?.pathname?.replace(uuidRegex, "/:id");

  const { userConfiguration = {} } = useSelector((state) => state.userConfig);

  const { routeConfig, child: childConfig } = getAccessRights({
    userConfiguration,
    title,
    child,
    path: formattedPath,
  });

  const { read: routeConfigRead, write: routeConfigWrite } = routeConfig;
  const { read: childRead, write: childWrite } = childConfig;

  return !child
    ? [routeConfigRead, routeConfigWrite, routeConfig]
    : [childRead, childWrite, childConfig];
};
