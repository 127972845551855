import { ScheduleDayInnerView } from "./ScheduleDayInnerView";
import { Button, Popover, Tooltip } from "antd";
import React from "react";
import { extractContent } from "../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/rtfEditor/rtfEditor";
import { DisplayColors } from "../DayStatusChangeModal/DayStatuses";
import moment from "moment-timezone";
import dayjs from "dayjs";

export const ComesFromDayView = (props) => {
  const { scheduleDay, notesToAddForScheduleDays } = props;
  let removeDayView;
  if (scheduleDay.isNotAvailable()) {
    removeDayView = (
      <Tooltip
        title={`Can't remove ${scheduleDay?.status} day. Only confirmed days can be deleted like that.`}
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="remove-day-button disabled"
        >
          X
        </Button>
      </Tooltip>
    );
  }

  const thisScheduleDayNotesToAdd =
    notesToAddForScheduleDays?.[scheduleDay?.id];

  let lastNoteView = "";

  if (thisScheduleDayNotesToAdd) {
    const shortText = extractContent(thisScheduleDayNotesToAdd.text);
    lastNoteView = shortText;
  }

  return (
    <>
      <Popover
        trigger={"click"}
        overlayClassName={"canceled-day-popover"}
        content={
          <ScheduleDayInnerView
            {...{
              ...props,
              postponedOnce: true,
            }}
          />
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "0,1rem",
          }}
        >
          <Button
            style={{
              width: "100%",
              background: DisplayColors?.[scheduleDay?.status],
              color: "#fff",
              height: "fit-content",
            }}
          >
            {dayjs(scheduleDay?.startDate)
              .tz("America/New_York")
              .format("MM/DD/YYYY")}{" "}
            - {scheduleDay?.status}
            <p
              style={{ fontWeight: "bold", whiteSpace: "pre-wrap", margin: 0 }}
            >
              {lastNoteView}
            </p>
          </Button>
        </div>
      </Popover>
    </>
  );
};
