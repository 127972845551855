import moment from "moment";
import { Select } from "antd";
import { useSelector } from "react-redux";

import { useRedux } from "../../../../../../../../../hooks/useRedux";
import { validateThenSavePayment } from "../../../../utils/checkers";
import { getAllUserAccess } from "../../../PaymentTable/utils/getAllUserAccess";
import { DropDownArrow } from "../../../../../../../../../Fleet/components/InputComponent/assets";
import { DatePickerMoment } from "../../../../../../../../../../commonComponents";

import "./PaymentCheckForm.scss";

const PaymentCheckForm = ({ paymentData }) => {
  const [fakePayment, setFakePayment] = useRedux("fakePayment");
  const [isWritable] = useRedux("paymentIsWritable", false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const receivedBy = programFields.find(
    (field) => field.fieldName === "Payment Options"
  ).fieldOptions;

  const usersWithAccess = getAllUserAccess(userConfiguration, "Payments/View");
  const newReceivedBy = [...receivedBy, ...usersWithAccess];

  return (
    <div className="payment-check-dates">
      <div className="payment-check-dates-details">
        <label>Check Date</label>
        <DatePickerMoment
          name="Check Date"
          className="input-form"
          popupClassName={isDarkMode && "darkDateDropDown"}
          required
          allowClear={false}
          placeholder="Check Date"
          value={
            fakePayment?.checkDates?.checkDate
              ? moment(fakePayment?.checkDates.checkDate).tz("America/New_York")
              : undefined
          }
          onChange={(value) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                checkDates: {
                  ...fakePayment.checkDates,
                  checkDate: moment(value).tz("America/New_York").valueOf(),
                },
              })
            )
          }
          format="MM/DD/YYYY"
          disabled={paymentData && !isWritable}
        />
      </div>
      <div className="payment-check-dates-details">
        <label>Received Date</label>
        <DatePickerMoment
          name="Received Date"
          className="input-form"
          popupClassName={isDarkMode && "darkDateDropDown"}
          required
          allowClear={false}
          placeholder="Received Date"
          value={
            fakePayment?.checkDates?.receivedDate
              ? moment(fakePayment?.checkDates.receivedDate).tz(
                  "America/New_York"
                )
              : undefined
          }
          onChange={(value) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                checkDates: {
                  ...fakePayment.checkDates,
                  receivedDate: moment(value).tz("America/New_York").valueOf(),
                },
              })
            )
          }
          format="MM/DD/YYYY"
          disabled={paymentData && !isWritable}
        />
      </div>
      <div className="payment-check-dates-details">
        <label>Recorded Date</label>
        <DatePickerMoment
          name="Recorded Date"
          className="input-form"
          popupClassName={isDarkMode && "darkDateDropDown"}
          required
          allowClear={false}
          placeholder="Recorded Date"
          value={
            fakePayment?.checkDates?.recordedDate
              ? moment(fakePayment?.checkDates.recordedDate).tz(
                  "America/New_York"
                )
              : undefined
          }
          onChange={(value) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                checkDates: {
                  ...fakePayment.checkDates,
                  recordedDate: moment(value).tz("America/New_York").valueOf(),
                },
              })
            )
          }
          format="MM/DD/YYYY"
          disabled={paymentData && !isWritable}
        />
      </div>
      <div className="payment-check-dates-details">
        <label>Received By</label>
        <Select
          className="receieved-by-input"
          showSearch
          placeholder="Who received this check?"
          popupClassName={isDarkMode && "darkDropDown"}
          suffixIcon={<DropDownArrow />}
          disabled={paymentData && !isWritable}
          options={newReceivedBy}
          value={fakePayment?.checkDates?.receivedBy ?? ""}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onSelect={(value) => {
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                checkDates: {
                  ...fakePayment.checkDates,
                  receivedBy: value,
                },
              })
            );
          }}
        />
        {/* <Input
          name="Received By"
          className="receieved-by-input"
          required
          allowClear={false}
          placeholder="Who received this check?"
          value={fakePayment?.checkDates?.receivedBy ?? ""}
          onChange={(value) =>
            setFakePayment(
              validateThenSavePayment({
                ...fakePayment,
                checkDates: {
                  ...fakePayment.checkDates,
                  receivedBy: value.target.value,
                },
              })
            )
          }
        /> */}
      </div>
    </div>
  );
};

export default PaymentCheckForm;
