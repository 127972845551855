import { totalitiesForService } from "../../../../Projects/Accounting/Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";
import { formatCurrency } from "../../../../utils";
import { getSums, groupBy } from "../../calculatingFunctions";

export const rentals = ({ tables, programFields }) => {
  const rentalStatus = programFields
    ?.find(({ fieldName }) => fieldName === "Status colors")
    ?.fieldOptions?.Rentals.reduce((acc, curr) => {
      acc.statusName = [...(acc.statusName || []), curr.statusName];
      acc.statusColor = [...(acc.statusColor || []), curr.statusColor];
      return acc;
    }, {});

  const statusData = rentalStatus?.statusName?.map((status) =>
    tables?.rentals?.filter((a) => a?.rentalStatus === status)
  );

  const temp = !!tables?.rentals && totalitiesForService(tables.Rentals);
  const allServicesTogether = {};

  !!temp &&
    temp?.forEach((curr) => {
      if (Object.keys(curr?.amounts).length) {
        Object?.entries(curr.amounts)?.forEach(([key, value]) => {
          if (!!allServicesTogether[key]) {
            allServicesTogether[key] = {
              appliedRent:
                allServicesTogether[key]?.appliedRent + value?.appliedRent,
              totalPrice:
                allServicesTogether[key]?.totalPrice + value?.totalPrice,
              totalTax: allServicesTogether[key]?.totalTax + value?.totalTax,
              retainage: allServicesTogether[key]?.retainage + value?.retainage,
              projects: {
                ...allServicesTogether[key]?.projects,
                [curr?.projectAddress]: {
                  projectId: curr?.projectId,
                  "Total Price":
                    (allServicesTogether[key]?.projects[curr?.projectAddress]?.[
                      "Total Price"
                    ] || 0) + (value?.totalPrice || 0),
                  "Total Applied Rent":
                    (allServicesTogether[key]?.projects[curr?.projectAddress]?.[
                      "Total Applied Rent"
                    ] || 0) + (value?.appliedRent || 0),
                  "Total Tax":
                    (allServicesTogether[key]?.projects[curr?.projectAddress]?.[
                      "Total Tax"
                    ] || 0) + (value?.totalTax || 0),
                  "Retainage Amount":
                    (allServicesTogether[key]?.projects[curr?.projectAddress]?.[
                      "Retainage Amount"
                    ] || 0) + (value?.retainage || 0),
                },
              },
            };
          } else {
            allServicesTogether[key] = {
              appliedRent: value?.appliedRent,
              totalPrice: value?.totalPrice,
              totalTax: value?.totalTax,
              retainage: value?.retainage,
              projects: {
                [curr?.projectAddress]: {
                  projectId: curr?.projectId,
                  "Total Price": value?.totalPrice || 0,
                  "Total Applied Rent": value?.appliedRent || 0,
                  "Total Tax": value?.totalTax || 0,
                  "Retainage Amount": value?.retainage || 0,
                },
              },
            };
          }
        });
      }
    });

  return {
    rentalStatus: () => [
      {
        labels: rentalStatus?.statusName,
        data: statusData.map((a) => a?.length),
        backgroundColor: rentalStatus?.statusColor,
        custom:
          !!statusData[0] &&
          groupBy(statusData, "projectAddress").map((el, index) => {
            return [
              {
                "Total Amount": {
                  "Total Price": formatCurrency(
                    getSums({ data: statusData, key: "totalPrice" })[index]
                  ),
                  "Total Applied Rent": formatCurrency(
                    getSums({ data: statusData, key: "totalAppliedRent" })[
                      index
                    ]
                  ),
                  "Total Tax": formatCurrency(
                    getSums({ data: statusData, key: "totalTax" })[index]
                  ),
                  "Retainage Amount": formatCurrency(
                    getSums({ data: statusData, key: "retainageAmount" })[index]
                  ),
                },
              },
              ...Object?.entries(el)?.map(([key, value]) => {
                return {
                  [key]: {
                    projectId: value?.[0]?.projectId,
                    "Total Price": formatCurrency(
                      getSums({ data: value, key: "totalPrice" })
                    ),
                    "Total Applied Rent": formatCurrency(
                      getSums({ data: value, key: "totalAppliedRent" })
                    ),
                    "Total Tax": formatCurrency(
                      getSums({ data: value, key: "totalTax" })
                    ),
                    "Retainage Amount": formatCurrency(
                      getSums({ data: value, key: "retainageAmount" })
                    ),
                  },
                };
              }),
            ];
          }),
      },
    ],
    totalPrice: () => [
      {
        labels: Object.keys(allServicesTogether),
        data: Object.values(allServicesTogether).map((a) =>
          a?.totalPrice?.toFixed(2)
        ),
        backgroundColor: "#2DA365",
        custom: Object?.entries(allServicesTogether)?.map(([key, value]) =>
          Object.entries(value?.projects)?.map(([key, value]) => {
            return {
              [key]: {
                ...(value || {}),
              },
            };
          })
        ),
      },
      {
        labels: Object.keys(allServicesTogether),
        data: Object.values(allServicesTogether).map((a) =>
          a?.appliedRent?.toFixed(2)
        ),
        backgroundColor: "#B5D99C",
      },
      {
        labels: Object.keys(allServicesTogether),
        data: Object.values(allServicesTogether).map((a) =>
          a?.retainage?.toFixed(2)
        ),
        backgroundColor: "#F6CB51",
      },
      {
        labels: Object.keys(allServicesTogether),
        data: Object.values(allServicesTogether).map((a) =>
          a?.totalTax?.toFixed(2)
        ),
        backgroundColor: "#f04f4e",
      },
    ],
  };
};
