import React from "react";
import TimeDatePicker from "../TimeDatePicker/TimeDatePicker";
import Time from "../Time/Time";
import Calendar from "../Calendar/Calendar";

const DateTimePicker = ({
  type = "Time",
  showOnlyRange,
  hideResult,
  hideButtons,
  showOnlySwitch,
  startDate,
  endDate,
  allDisabledDays = [],
  setFormChanged = () => {},
}) => {
  if (type === "Time") {
    return <Time />;
  } else if (type === "Date") {
    return (
      <Calendar
        showOnlyRange={showOnlyRange}
        type={type}
        hideResult={hideResult}
        hideButtons={hideButtons}
        startDate={startDate}
        setFormChanged={setFormChanged}
        endDate={endDate}
        allDisabledDays={allDisabledDays}
      />
    );
  } else if (type === "DateTime") {
    return <TimeDatePicker {...{ hideResult, hideButtons, showOnlySwitch }} />;
  } else {
    return (
      <div>
        <h1>Type is wrong! Please try again!</h1>
      </div>
    );
  }
};

export default DateTimePicker;
