import { Modal, Tag } from "antd";
import React, { useState } from "react";
import columnDefinitions from "../../../../../../../../../../../../../../../../../src/components/SidebarPages/Estimations/DataEntryGrid/tools/columnDefiners/index";
import { AgGridReact } from "ag-grid-react";
import ApprovedRenderer from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/cellRenderers/approvedRenderer";
import PLIAddon from "src/components/SidebarPages/Estimations/DataEntryGrid/subcomponents/elevationPanel/PLIAddon/PLIAddon";
import { priceFormatter } from "src/components/SidebarPages/Estimations/DataEntryGrid/tools/formatters/pricing";
import {
  evaluateMathExpression2,
  valueGetter,
} from "src/components/SidebarPages/Estimations/DataEntryGrid/tools/formatters/evaluators";
import { priceCellClassRules } from "src/components/SidebarPages/Estimations/DataEntryGrid/tools/formatters/GridStyles";
import { NormalSizedModal } from "src/components/SidebarPages/Fleet/components";

function PLIDetails(props) {
  const [visible, setVisible] = useState(false);

  const toggleModal = () => {
    setVisible((prev) => !prev);
  };

  const ColumnDefs = () => {
    console.log("props", props);
    let serviceId = props?.service?.serviceId;

    let tempColumns = columnDefinitions?.[serviceId] || [];
    let columns = tempColumns?.map((col) => {
      if (col.field === "approved") {
        return {
          ...col,
          valueGetter: (params) => {
            return params?.data?.approved ? "Approved" : "Not Approved";
          },
          //   cellRenderer: "ApprovedRenderer",
        };
      } else if (
        col.field === "ppu" ||
        col.field === "rent" ||
        col.field === "price" ||
        col.field === "totalPrice"
      ) {
        col.valueFormatter = priceFormatter;
        col.valueParser = evaluateMathExpression2;
        col.valueGetter = valueGetter;
        col.cellClassRules = priceCellClassRules;
      }

      return col;
    });
    return columns?.filter(
      (col) => col.field !== "addons" && col.field !== "lock"
    );
  };

  return (
    <>
      <Tag
        color="#1264A3"
        onClick={toggleModal}
        style={{
          fontSize: 19,
          cursor: "pointer",
        }}
      >
        Show PLI Details
      </Tag>
      <NormalSizedModal
        darkMode={true}
        visible={visible}
        onCancel={toggleModal}
        setVisible={setVisible}
        title="PLI Details"
        maskClosable={true}
      >
        <div
          style={{
            width: "1800px",
          }}
        >
          <div
            className={`elevationContainer ${
              1 === 1 ? "ag-theme-alpine-dark" : "ag-theme-alpine"
            }`}
          >
            <AgGridReact //ag-grid for an elevation. example: SS-2-01
              columnDefs={ColumnDefs()}
              rowData={props?.serviceOption?.items || []}
              gridOptions={{}}
              frameworkComponents={{
                ApprovedRenderer: ApprovedRenderer,
                SidewalkShedAddonsDetail: PLIAddon,
              }}
            />
          </div>
        </div>
      </NormalSizedModal>
    </>
  );
}

export default PLIDetails;
