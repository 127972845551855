import { ExcelIcon, ImageIcon, PdfIcon, WordIcon } from "./assets";

export const fileIcons = {
  xls: <ExcelIcon />,
  xlsx: <ExcelIcon />,
  pdf: <PdfIcon />,
  doc: <WordIcon />,
  docx: <WordIcon />,
  image: <ImageIcon />,
};

export const docObjectLabels = {
  expirationDate: "Expiration Date",
  notes: "Notes",
  docStatus: "Documentation Status",
};

export const uuidRegex = /\/(\w+-){4}\w+/g;

export const queryRegex = /\?.*$/;
