import { Modal } from "antd";
import { antdModalProps } from "../../../../../../utils";
import JumpsModalContent from "./JumpsModalContent";

const JumpsModal = ({ visible, setVisible, elevation, indices }) => {
  const onCancel = () => setVisible(false);

  return (
    <Modal
      {...{
        ...antdModalProps,
        open: visible,
        destroyOnClose: true,
        onCancel,
        title: `${elevation.elevationLabel} - Jumps Configuration`,
      }}
    >
      <JumpsModalContent {...{ elevation, onCancel, indices }} />
    </Modal>
  );
};

export default JumpsModal;
