import React from "react";
import { openEmailBox } from "../../../../../../SidebarPages/Communication/functions";

export default function EmailBoxContainer({
    emailBoxes,
    rowData,
    setEmailBoxes,
    attachments,
    setAttachments,
    visibleReport,
}) {
    return (
        <div
            {...{ className: "emailBoxContainer" }}
            style={visibleReport ? { zIndex: 8888 } : {}}
        >
            {emailBoxes.map(({ Component, key }) => {
                return (
                    <Component
                        {...{
                            rowData,
                            key,
                            onClose: () => {
                                if (attachments.length > 0) setAttachments([]);
                                setEmailBoxes((prev) => prev?.filter((e) => e.key !== key));
                            },
                            authData: null,
                            isRedirected: false,
                            attachments,
                            openEmailBox,
                            boxKey: key,
                        }}
                    />
                );
            })}
        </div>
    );
}
