import { SimplePLI } from "./GeneralModels";

export class BarriersPLI extends SimplePLI {
  serviceId = 9;

  length;

  material; //['Concrete', 'Plastic (Water filled)']
  withFence; //boolean

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateBarriersPLI extends SimplePLI {
  serviceId = 9;

  length;

  material; //['Concrete', 'Plastic (Water filled)']
  withFence; //boolean
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
