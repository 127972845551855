const gridFilters = [
  {
    globalKeyId: "c81ea090-e824-4360-a680-96fc0570b224",
    label: "Vehicle",
    type: "Dropdown",
    key: "fleetName",
  },
  {
    globalKeyId: "c81ea090-e824-4360-a680-96fc0570b234",
    label: "Event Type",
    type: "Dropdown",
    key: "type",
  },
  {
    globalKeyId: "6eeaa019-9501-4b4d-b4cd-cddbc19d9475",
    label: "From Time",
    type: "DateRange",
    key: "fromTime",
  },
  {
    globalKeyId: "1fcb1e74-1ae2-47e0-8846-0c75135a7ab1",
    label: "From Location",
    type: "Dropdown",
    key: "fromLocation",
  },
  {
    globalKeyId: "adbe93cc-7842-4ff0-990d-5abcc3b9b746",
    label: "To Time",
    type: "DateRange",
    key: "toTime",
  },
  {
    globalKeyId: "742c8194-6f25-4c05-9b53-1647e2d1ed76",
    label: "Going To",
    type: "Dropdown",
    key: "toLocation",
  },
  {
    globalKeyId: "bd2ff4ff-ee42-4e98-bb89-6c918dcfdc8e",
    label: "Clock In",
    type: "DateRange",
    key: "clockIn",
  },
  {
    globalKeyId: "c1c0cac9-ba52-4d2e-9e03-9e5b758285ac",
    label: "Clock Out",
    type: "DateRange",
    key: "clockOut",
  },
];

export default gridFilters;
