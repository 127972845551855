import { message } from "antd";
import { useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

function AskToLogoutTimer({ askToLogout, setAskToLogout }) {
  function startTimer(retryTime) {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = retryTime - now;
      if (timeDiff <= 0) {
        clearInterval(interval);
        localStorage.removeItem("askToLogoutTime");
        setAskToLogout(null);
      } else {
        const minutes = Math.floor(
          (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
        ).padStart(2, "0");
        const seconds = String(
          Math.floor((timeDiff % (1000 * 60)) / 1000)
        ).padStart(2, "0");
        message.warning({
          content: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                gap: 10,
              }}
            >
              <span>
                You will be logged out in {minutes}:{seconds} minutes.
              </span>
              <CloseCircleOutlined
                style={{ color: "lightgray", cursor: "pointer" }}
                onClick={() => {
                  message.destroy("ask-to-logout-timer-message");
                }}
              />
            </div>
          ),
          duration: 10,
          className: "ask-to-logout-timer-message",
          key: "ask-to-logout-timer-message",
        });
      }
    }, 60000);
  }

  useEffect(() => {
    const storedRetryTime =
      JSON.parse(localStorage.getItem("askToLogoutTime")) || null;
    const currentTime = new Date();
    if (storedRetryTime) {
      if (storedRetryTime > currentTime) {
        startTimer(storedRetryTime);
      }
    } else if (askToLogout) {
      if (askToLogout > currentTime) {
        startTimer(timer);
      }
    }
  }, [askToLogout]);

  return <></>;
}

export default AskToLogoutTimer;
