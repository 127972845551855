export const applications = (
  updatedDynamicStates,
  IdHandler,
  programFields,
  applicationsData
) => {
  return {
    applicationStatus: () => ({
      title: "Requisition - Requisition Status",
      BodyComponent: "Progress",
      type: "Progress",
      BodyComponentParams: {
        table: "Applications",
        field: "applicationStatus",
        secondaryField: "projectName",
        // field: "applicationCompany",
        // secondaryField: "applicationStatus",
        filterField: [],
        id: IdHandler,
        statusData: applicationsData?.map((a) => {
          return {
            label: `${a?.projectName} - ${a?.applicationNo}`,
            value: a?.applicationStatus,
            color:
              programFields
                ?.find((a) => a?.fieldName === "Status colors")
                ?.fieldOptions?.Requisition?.find(
                  (b) =>
                    b?.statusName?.toLowerCase() ===
                    a?.applicationStatus?.toLowerCase()
                )?.statusColor || "#6D8299",
            date: a?.createdAt,
          };
        }),
      },
      id: IdHandler,
      width: "795px",
    }),
  };
};
