import React, { useEffect } from "react";
import "../styles/HeaderFilter.scss";
import DebounceSelect from "../../components/DebounceSelect/DebounceSelect";
import { fetchSearchRecords } from "../../../../utils";
import { useRedux } from "../../../SidebarPages/hooks";
import { MondayButton } from "../../../commonComponents";
import {
  CloseIcon,
  DeleteIcon,
} from "../../../pages/Settings/settingsComponents/Roles/src";
import { Select } from "antd";
import { useCallback } from "react";
import { BackIcon } from "../../../SidebarPages/Communication/assets";
import { RollbackOutlined } from "@ant-design/icons";
function HeaderFilter() {
  const [proposalData, setProposalData] = useRedux("proposalData");

  const onSelectDataSrc = (value) => {
    const dataProposal = structuredClone(proposalData);
    dataProposal.filter.project = value?.value;
    dataProposal.filter.filter = true;
    setProposalData(dataProposal);
  };

  const onClearFilters = () => {
    const dataProposal = structuredClone(proposalData);
    dataProposal.filter.project = null;
    dataProposal.filter.filter = false;
    setProposalData(dataProposal);
  };

  const fetchOptions = useCallback(async (value) => {
    return await fetchSearchRecords("projects", "projectAddress", value).then(
      (res) => {
        console.log("res", res);
        return res?.map((el) => {
          const { projectAddress = "", projectName = "", projectId = "" } = el;
          return {
            label: projectName || projectAddress,
            value: projectId,
            key: projectAddress + projectId,
            item: el,
          };
        });
      }
    );
  }, []);

  return (
    <div className="proposal-header-filter-wrapper">
      {" "}
      <div className="proposal-header-filter_item">
        <MondayButton
          className="mondayButtonBlue"
          Icon={<RollbackOutlined />}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Go Back
        </MondayButton>
      </div>
      <div className="proposal-header-filter_item">
        <DebounceSelect
          className="proposal-header-filter-select"
          value={proposalData?.filter?.project ?? null}
          placeholder="Search a project"
          fetchOptions={fetchOptions}
          onSelect={onSelectDataSrc}
        />
      </div>{" "}
      <div className="proposal-header-filter_item">
        <Select
          className="proposal-header-filter-select"
          placeholder="Filter by Proposal Status"
          // disabled
          options={
            [
              // { value: "Draft", label: "Draft" },
              // { value: "Sent", label: "Sent" },
              // { value: "Confirmed", label: "Confirmed" },
              // { value: "Sent to Client", label: "Sent to Client" },
            ]
          }
        />
      </div>{" "}
      <div className="proposal-header-filter_item">
        <MondayButton
          className="mondayButtonRed"
          Icon={<DeleteIcon />}
          onClick={onClearFilters}
        >
          Clear Filters
        </MondayButton>
      </div>
    </div>
  );
}

export default HeaderFilter;
