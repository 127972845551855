import React, { useState, useEffect, useMemo } from "react";
import { Checkbox, message } from "antd";
import { checkIfRowHasValidData } from "../../../tools/formatters/validators";
import { useRedux } from "../../../../../hooks/useRedux";
import _ from "lodash";
import { LockFilled, UnlockOutlined } from "@ant-design/icons";
const HoistCheckBox = (props) => {
  const [checked, setChecked] = useState(null);
  const [isWritable] = useRedux("estimationsIsWritable");
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");

  //   const handleCheckboxElementChange = (e, itemReference) => {
  //     if (!isWritable) {
  //       //if not in write mode just warn user and don't update anything
  //       message.error("Please enable write mode to start making changes");
  //       return;
  //     }
  //     const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
  //     let tempGridData = _.cloneDeep(gridData);
  //     _.update(
  //       tempGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
  //       itemReference,
  //       () => e.target.checked
  //     );
  //     setGridData(tempGridData);
  //   };

  const isChecked = useMemo(() => {
    const {
      serviceIndex = "",
      optionIndex = "",
      elevationIndex = "",
    } = props.indexes;
    const items =
      gridData[serviceIndex]?.serviceOptions?.[optionIndex]?.[elevationIndex]
        ?.items ?? [];
    console.log("CheckboxRenderer items", items);
    if (Array.isArray(items)) {
      const item = items?.find((i) => i.id === props.data.id);
      if (props?.column?.colId === "stop") {
        return item?.stop || false;
      } else if (props?.column?.colId === "dual") {
        return item?.dual || false;
      }
    } else return false;
  }, [gridData, props.indexes]);

  const handleCheck = (e) => {
    console.log("CheckboxRenderer props", props);

    if (isWritable) {
      const {
        serviceIndex = "",
        optionIndex = "",
        elevationIndex = "",
      } = props.indexes;
      const newGridData = _.cloneDeep(gridData);
      const items =
        newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex]
          ?.items || [];
      console.log("CheckboxRenderer items", items);
      if (Array.isArray(items)) {
        const item = items?.find((i) => i.id === props.data.id);
        if (props?.column?.colId === "stop") {
          item.stop = e.target.checked;
        } else if (props?.column?.colId === "dual") {
          item.dual = e.target.checked;
        }
        setTakeOffTableData(newGridData);
      }
    } else {
      message.error("Please enable write mode to start editing.");
    }
  };

  // useEffect(() => {
  //   if (checked === null) {
  //     setChecked(props.node.data[props.column.colId] || false);
  //   }
  // }, []);

  return (
    <>
      {props?.node?.group ? (
        <span>-</span>
      ) : props.column.colId === "lock" ? (
        <Button
          className={"checkboxRenderer-" + props.column.colId + "-" + checked}
          onClick={(e) => {
            handleCheck({ target: { checked: !checked } });
          }}
        >
          {checked ? <LockFilled /> : <UnlockOutlined />}
        </Button>
      ) : (
        <Checkbox
          className={"checkboxRenderer-" + props.column.colId}
          onChange={handleCheck}
          checked={isChecked}
        />
      )}
    </>
  );
};

export default HoistCheckBox;
