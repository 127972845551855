import React from "react";
import { Button, Popover, Tooltip } from "antd";

/**
 * @component
 * This component is used in PLI rows and addon rows. It is open as a small popup and have 3 buttons add/duplicate/remove.
 *
 * @param props.appendRow {function}
 * @param props.duplicateRow {function}
 * @param props.removeRow {function}
 * @param props.indexes {{serviceIndex: number, optionIndex: number, elevationIndex: number}
 * @param props.isWritable {boolean}
 * @param props.gridParams {gridApi}
 * @param props.type {'pli'|'addon"}
 *
 * */
export default function GridRowModifier(props) {
  return (
    <Popover
      trigger={"click"}
      overlayClassName="modifyRowPopover"
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div>
            <Tooltip
              title={`Append ${props.type.toUpperCase()}`}
              placement="left"
            >
              <Button
                size="small"
                shape="circle"
                className="rowModifierAdd"
                onClick={() => {
                  props.appendRow(
                    props.gridParams.api,
                    props.indexes.serviceIndex,
                    props.indexes.optionIndex,
                    props.indexes.elevationIndex,
                    props.gridParams.node.data.id
                  );
                }}
              >
                <img
                  src="https://img.icons8.com/material-outlined/12/ffffff/plus-math.png"
                  alt=""
                  style={{ width: "12px", height: "12px" }}
                />
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={`Duplicate ${props.type.toUpperCase()}`}
              placement="left"
            >
              <Button
                size="small"
                shape="circle"
                className="rowModifierDuplicate"
                onClick={() => {
                  props.duplicateRow(
                    props.gridParams.api,
                    props.indexes.serviceIndex,
                    props.indexes.optionIndex,
                    props.indexes.elevationIndex,
                    props.gridParams.node.data.id
                  );
                }}
              >
                <img
                  src="https://img.icons8.com/material-rounded/12/ffffff/duplicate.png"
                  alt=""
                />
              </Button>
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={`Remove ${props.type.toUpperCase()}`}
              placement="left"
            >
              <Button
                size="small"
                shape="circle"
                className="rowModifierRemove"
                onClick={() => {
                  props.removeRow(
                    props.gridParams.api,
                    props.indexes.serviceIndex,
                    props.indexes.optionIndex,
                    props.indexes.elevationIndex,
                    props.gridParams.node.data.id
                  );
                }}
              >
                <img
                  src="https://img.icons8.com/material-outlined/12/ffffff/minus-math.png"
                  alt=""
                />
              </Button>
            </Tooltip>
          </div>
        </div>
      }
    >
      <Button size="small" shape="circle" className="rowModifierButton">
        <img
          src="https://img.icons8.com/material-sharp/18/ffffff/menu-2.png"
          alt=""
        />
      </Button>
    </Popover>
  );
}
