function getReportCardOptions({ chartType, updateChartType }) {
  return [
    {
      label: "Line Chart",
      key: "CHART_TYPE_LINE",
      onClick() {
        if (chartType !== "Line") {
          updateChartType("Line");
        }
      },
    },
    {
      label: "Vertical Bar",
      key: "CHART_TYPE_VERTICAL",
      onClick() {
        if (chartType !== "Vertical") {
          updateChartType("Vertical");
        }
      },
    },
    {
      label: "Horizontal Bar",
      key: "CHART_TYPE_HORIZONTAL",
      onClick() {
        if (chartType !== "Horizontal") {
          updateChartType("Horizontal");
        }
      },
    },
    {
      label: "Pie Chart",
      key: "CHART_TYPE_PIE",
      onClick() {
        if (chartType !== "Pie") {
          updateChartType("Pie");
        }
      },
    },
    {
      label: "Doughnut Chart",
      key: "CHART_TYPE_DOUGHNUT",
      onClick() {
        if (chartType !== "Doughnut") {
          updateChartType("Doughnut");
        }
      },
    },
  ];
}

export default getReportCardOptions;
