import { useState } from "react";
import { useEditLogs } from "./useEditLogs";

export default function useLogsApi() {
  const [isLoading, setIsLoading] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const createLogs = async (data) => {
    setIsLoading(true);

    try {
      const response = await saveAddedLogs(data);

      setIsLoading(false);

      return response;
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  return {
    isLoading,
    createLogs,
  };
}
