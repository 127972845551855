import {
  ChangeOrderIcon,
  DeleteIcon,
} from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { useStateContext, useDispatchContext } from "../../Context/Context";
import "./services-menu.scss";

import RGL, { WidthProvider } from "react-grid-layout";
import { useSelector } from "react-redux";
import { Button, Checkbox, message, Popconfirm, Tooltip } from "antd";
import AddServices from "./AddServices";
import { API } from "aws-amplify";
import { groupBy } from "../../../../../../Accounting/components/utilities";
import { otherScopeServiceTotalPriceMapper } from "../../../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { WarningCircleIcon } from "../../../../../DobTab/assets";
import { WarningIcon } from "../../../../../../../../assets";
import { checkIfServiceIsHoist } from "../../../../../../Estimations/DataEntryGrid/models/Service";
import {
  getHoistServiceAddonsAsElevations,
  getModifiedServiceOptions,
} from "../../../ScheduleOfValueTab/NewScheduleOfValue/Components/ModalFooter/modalFooterFunctions";
import { forceToNumber } from "../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
const ReactGridLayout = WidthProvider(RGL);

const ServicesMenu = () => {
  const { isLoading } = useSelector((state) => state?.scheduleOfValues);

  // Context state & dispatch
  const {
    proposedTypeOfWork,
    isWritable,
    fakeSOV,
    errors,
    darkMode,
    retracted,
  } = useStateContext();
  const dispatchContext = useDispatchContext();

  let gridRowData = fakeSOV?.services?.reduce(
    (acc, cur) => ({
      ...acc,
      [cur?.estimationId]: [...(acc[cur?.estimationId] || []), cur],
    }),
    {}
  );

  // If autosaving is on, update SOV on db otherwise just update fake SOV
  const handleSave = (newSOV) => {
    dispatchContext({ type: "SET_FAKE_SOV", payload: newSOV });
  };

  // Hide or show all services
  const handleHideOrShowServices = (checked) => {
    const newScheduleOfValue = {
      ...fakeSOV,
      services: fakeSOV?.services?.map((sovService) => ({
        ...sovService,
        visible: checked,
      })),
    };
    handleSave(newScheduleOfValue);
  };

  // Handle sorting layout
  const handleLayoutChange = (estimationId, newLayout) => {
    const sortedNewLayout = newLayout?.sort((a, b) => a?.y - b?.y);

    // Change only services of this estimation
    const newEstimations = {
      ...gridRowData,
      [estimationId]: sortedNewLayout.map((item) =>
        fakeSOV?.services?.find(
          (service) =>
            service?.estimationId === estimationId &&
            service?.label === item?.i?.split("/")[0]
        )
      ),
    };

    // Convert services of this estimation back to array
    const services = Object?.values(newEstimations)?.flat(1);

    const newScheduleOfValue = {
      ...fakeSOV,
      services,
    };
    handleSave(newScheduleOfValue);
  };

  // Hide or show only one service
  const handleServiceSelected = (estimationId, serviceId, checked) => {
    const newScheduleOfValue = {
      ...fakeSOV,
      services: fakeSOV?.services?.map((sovService) =>
        sovService?.estimationId === estimationId &&
        sovService?.serviceId === serviceId
          ? { ...sovService, visible: checked }
          : sovService
      ),
    };

    handleSave(newScheduleOfValue);
  };

  const addServices = async (estimationId, services) => {
    let estimation = await API.get(
      "estimations",
      `/estimations/${estimationId}`
    ).then((res) => res);

    let hasAllServices = estimation?.services === services?.length;

    const newScheduleOfValue = {
      ...fakeSOV,
      hasAllServices: hasAllServices, // Shows if all services are included

      services: [
        ...fakeSOV?.services,
        ...services?.map((service, serviceIndex) => {
          let toReturn = {
            // Modify and return services of each estimation
            ...service, // Spread service properties that dont need to change
            estimationId: estimation.estimationId, // Add estimation id to each service
            amounts: [], // Initialize and empty array for amounts of each service
            visible: true,
            totalPrice: (() => {
              const { serviceAddons = [], serviceOptions = [] } = service || {};
              let serviceTotalPrice = 0;
              console.log("service123", service);
              if (service?.isScope) {
                serviceTotalPrice = otherScopeServiceTotalPriceMapper(service);
                console.log("serviceTotalPrice", serviceTotalPrice);
              } else {
                serviceTotalPrice = [
                  ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                    items?.flatMap(
                      ({ totalPrice = 0 }) => forceToNumber(totalPrice) || 0
                    )
                  ),
                  ...serviceAddons?.map(({ totalPrice = 0 }) =>
                    forceToNumber(totalPrice)
                  ),
                ]
                  ?.filter(Boolean)
                  ?.reduce((acc, curr) => (acc += +curr), 0);
              }

              return serviceTotalPrice;
            })(),
            price: (() => {
              const { serviceAddons = [], serviceOptions = [] } = service || {};

              const serviceTotalPrice = [
                ...serviceOptions?.[0]?.flatMap?.(({ items }) =>
                  items?.flatMap(({ price = 0 }) => forceToNumber(price))
                ),
                ...serviceAddons?.map(({ totalPrice = 0 }) =>
                  forceToNumber(totalPrice)
                ),
              ]
                ?.filter(Boolean)
                ?.reduce((acc, curr) => (acc += +curr), 0);

              return serviceTotalPrice;
            })(),
            typeOfSOV: "", // Type of SOV is empty initially,
            retainage: 10, // Default retainage 10%
            isChangeOrder: estimation?.isChangeOrder,
            serviceOptions: getModifiedServiceOptions(service),
          };

          return toReturn;
        }),
      ],
    };
    handleSave(newScheduleOfValue);
    message.success("Service added successfully");
  };

  // Hide or show only one service
  const handleServiceDeleted = (estimationId, serviceId, checked) => {
    console.log("deleting service", {
      estimationId,
      serviceId,
      checked,
      fakeSOV,
    });
    const newScheduleOfValue = {
      ...fakeSOV,
      services: fakeSOV?.services?.filter((sovService) => {
        if (
          sovService?.estimationId === estimationId &&
          sovService?.serviceId === serviceId
        ) {
          return false;
        }
        return true;
      }),
    };
    let groupedEstimations = groupBy(
      newScheduleOfValue?.services,
      "estimationId"
    );
    console.log("groupedEstimations", groupedEstimations);
    let updatedSelectedEstimations = fakeSOV?.estimationsSelected?.filter(
      (el) => {
        return !!groupedEstimations[el] && groupedEstimations[el]?.length > 0;
      }
    );
    newScheduleOfValue.estimationsSelected = updatedSelectedEstimations;

    console.log("newScheduleOfValue", newScheduleOfValue);
    handleSave(newScheduleOfValue);
    message.success("Service deleted successfully");
  };

  const showAllIndic =
    fakeSOV?.services?.some((el) => el?.visible !== true) || false;

  return (
    <div
      className={
        darkMode
          ? retracted
            ? "SOVServiceMenuContainerRetractedDark"
            : "SOVServiceMenuContainerDark"
          : retracted
          ? "SOVServiceMenuContainerRetractedLight"
          : "SOVServiceMenuContainerLight"
      }
      // className={darkMode ? "SOVServiceMenuContainerDark" : "SOVServiceMenuContainerLight"}
    >
      <div className="service-menu-container-options">
        {!showAllIndic && (
          <div
            className="showHideButtonContainer"
            onClick={() => {
              if (!isWritable || isLoading) {
                return;
              }
              handleHideOrShowServices(false);
            }}
            disabled={!isWritable || isLoading}
            //   Icon={<EyeInvisibleFilled />}
            //   className="mondayButtonBlue"
          >
            {" "}
            <EyeInvisibleFilled />
            Hide all
          </div>
          // <MondayButton
          //   onClick={() => handleHideOrShowServices(false)}
          //   disabled={!isWritable || isLoading}
          //   Icon={<EyeInvisibleFilled />}
          //   className="mondayButtonBlue"
          // >
          //   Hide All
          // </MondayButton>
        )}
        {/* <div className="divider" /> */}
        {showAllIndic && (
          <div
            className="showHideButtonContainer"
            onClick={() => {
              if (!isWritable || isLoading) {
                return;
              }
              handleHideOrShowServices(true);
            }}
            disabled={!isWritable || isLoading}
            //   Icon={<EyeInvisibleFilled />}
            //   className="mondayButtonBlue"
          >
            {" "}
            <EyeFilled />
            Show all
          </div>
        )}
        {/* <button
          className="showHideButton"
          onClick={() => handleHideOrShowServices(false)}
          disabled={!isWritable || isLoading}
        >
          <div className="textHolder">Hide All</div>
          <div className="iconHolder">
            <EyeInvisibleFilled  />
          </div>
        </button> */}
        {/* <div className="divider" />
        <button
          className="showHideButton"
          onClick={() => handleHideOrShowServices(true)}
          disabled={!isWritable || isLoading}
        >
          <div className="textHolder">Show All</div>
          <div className="iconHolder">
            <EyeFilled />
          </div>
        </button> */}
      </div>
      <div
        style={{
          // display: "flex",
          // // justifyContent: "center",
          // alignItems: "center",
          // flexDirection: "column",
          // gap: "20px",
          // justifyContent: "flex-start",
          // width: "100%",
          // justifyContent: "center",
          // alignItems: "center",
          // marginTop: "10px",
          // marginBottom: "10px",
          // overflowY: "scroll",
          height: "790px",
          marginBottom: "20px",
        }}
      >
        {gridRowData &&
          Object?.entries(gridRowData)?.map(([key, values], index) => (
            <div key={key} className="estimation-services">
              <div className="estimation-services-title">
                Estimation {index + 1} {values?.[0]?.quickbooksNumber || ""}
                <AddServices
                  estimationId={key}
                  darkMode={darkMode}
                  services={values}
                  addServices={addServices}
                  disabled={!isWritable || isLoading}
                />
              </div>
              <ReactGridLayout
                isResizable={false}
                cols={1}
                rowHeight={36}
                containerPadding={[0, 0]}
                margin={[0, 3]}
                onDragStop={(newLayout) => handleLayoutChange(key, newLayout)}
                draggableCancel=".estimation-services-checkbox"
                isDraggable={isWritable && !isLoading}
                // style={{ position: "absolute" }}
              >
                {!!proposedTypeOfWork?.length ? (
                  values?.map((service, serviceIndex) => {
                    const color = proposedTypeOfWork?.find(
                      (ptw) =>
                        ptw?.workId?.toString() ===
                        service?.serviceId?.toString()
                    )?.colorCode;
                    const hasError =
                      (errors &&
                        !!Object.keys(
                          errors?.[
                            `${service?.label}/${service?.estimationId}`
                          ] || {}
                        )?.length > 0) ||
                      false;
                    return (
                      <div
                        key={`${service?.label}/${service?.estimationId}`}
                        data-grid={{ x: 0, y: serviceIndex, w: 1, h: 1 }}
                      >
                        <div
                          className="estimation-services-item"
                          style={{
                            ...(service?.visible && {
                              borderRight: `5px solid ${color}`,
                            }),
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                          }}
                        >
                          <Checkbox
                            className="estimation-services-checkbox"
                            checked={service?.visible}
                            style={{
                              padding: "0px",
                            }}
                            onChange={(e) =>
                              handleServiceSelected(
                                key,
                                service?.serviceId,
                                e?.target?.checked
                              )
                            } // key is estimationId, or instead you can put service.estimationId
                            disabled={!isWritable || isLoading}
                          />
                          <div
                            className="estimationServicesContainer"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              height: "50px",
                            }}
                          >
                            <div
                              className="estimation-services-text"
                              style={{
                                fontSize: "14px",
                                height: "50px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip title={service?.label}>
                                {service?.label}
                              </Tooltip>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {service?.isChangeOrder && (
                                <Tooltip title="Change order">
                                  <div className="changeOrderContainer">
                                    <ChangeOrderIcon />
                                  </div>
                                </Tooltip>
                              )}
                              <Popconfirm
                                trigger={["hover"]}
                                title="Are you sure to delete this service?"
                                onConfirm={() =>
                                  handleServiceDeleted(key, service?.serviceId)
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <div
                                  // className="deleteContainer"
                                  // onClick={(e) => {
                                  //   e.stopPropagation();
                                  //   e.preventDefault();
                                  //   handleServiceDeleted(
                                  //     key,
                                  //     service?.serviceId
                                  //   );
                                  // }}
                                  style={{
                                    cursor: "pointer",
                                    zIndex: 100000,
                                  }}
                                >
                                  <DeleteIcon />
                                </div>
                              </Popconfirm>
                            </div>
                          </div>
                          {hasError && (
                            <span className="estimation-services-error">
                              <WarningIcon style={{ color: "#EB4345" }} />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    key="empty"
                    data-grid={{ x: 0, y: 0, w: 0, h: 0, static: true }}
                  />
                )}
              </ReactGridLayout>
              <br />
            </div>
          ))}{" "}
        <br />
      </div>
    </div>
  );
};

export default ServicesMenu;
