import React from "react";
import { Link } from "react-router-dom";

const ChargeDisabled = ({ currentCharge }) => {
  if (currentCharge?.invoices?.length === 1)
    return (
      <div>
        This invoice is included in
        <>
          :<br />
          <Link
            to={`/invoices/${currentCharge?.invoices[0]?.invoiceId}`}
            target={"_blank"}
            style={{ color: "#1890FF" }}
          >
            <b>Invoice {currentCharge?.invoices[0]?.invoiceNumber}</b>
          </Link>
        </>
      </div>
    );

  return (
    <div>
      <>
        Invoices:
        {currentCharge?.invoices?.map(({ invoiceId, invoiceNumber }, i) => (
          <>
            {" "}
            <Link
              to={`/invoices/${invoiceId}`}
              target={"_blank"}
              style={{ color: "#1890FF" }}
            >
              <b>{invoiceNumber}</b>
            </Link>
            {i !== currentCharge?.invoices?.length - 1 ? "," : ""}
          </>
        ))}
      </>
    </div>
  );
};

export default ChargeDisabled;
