import { Button, DatePicker, message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EditIcon } from "../../../../../../../../../../DynamicView/src";
import Header from "../../../../../../../ApplicationsTab/NewApplication/Components/Header/Header";
import MonthPicker from "../../../../../../../ApplicationsTab/NewApplication/Components/MonthPicker/MonthPicker";
import RangePickerContainer from "../../../../../../../ApplicationsTab/NewApplication/Components/RangePickerContainer/RangePickerContainer";
import "./EditDates.scss";
function EditDates({
  application,
  updateApplicationDates,
  darkMode = false,
  isWritable,
}) {
  const [visible, setVisible] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [custom, setCustom] = useState(null);
  const [canCreate, setCanCreate] = useState(true);
  const onView = () => {
    setVisible(!visible);
  };

  const {
    isLoading: isCreatingNewApplication,
    applications,
    newlyCreatedApplication,
  } = useSelector((state) => state.applications);
  const lastPeriod = applications
    ?.filter(
      (application2) =>
        application2?.projectId === application2?.projectId &&
        application2?.applicationNo !== application?.applicationNo
    )
    ?.reduce(
      (acc, cur) => (acc?.applicationNo > cur?.applicationNo ? acc : cur),
      null
    )?.periodTo?.end;

  // console.log("currentid", { applications, application });
  const onSaveEditDates = async () => {
    // console.log("onSaveEditDates");
    if (!!selectedMonth?.start && !!selectedMonth?.end && !!dueDate) {
      await updateApplicationDates({ selectedMonth, dueDate, application });
      setVisible(false);
    } else {
      message.error("Please fill all the fields");
    }
  };

  return (
    <div className="edit-dates-req-view">
      {isWritable && (
        <span>
          <EditIcon
            style={{ fill: darkMode ? "white" : "black" }}
            onClick={onView}
          />
        </span>
      )}
      {visible && (
        <Modal
          title="You are about to edit critical information regarding dates!"
          open={visible}
          onOk={onSaveEditDates}
          onCancel={onView}
          centered
          closable={false}
          width={"auto"}
          wrapClassName={darkMode ? "editDatesAppDark" : "editDatesAppLight"}
          // width={670}
          okText="Save changes"
          destroyOnClose
        >
          <div>
            <Header
              {...{
                setSelectedMonth,
                selectedMonth,
                setDueDate,
                canCreate,
                setCustom,
                dueDate,
                custom,
              }}
            />
            {custom ? (
              <RangePickerContainer
                {...{
                  setSelectedMonth,
                  selectedMonth,
                  lastPeriod: lastPeriod,
                  custom,
                  // chosenSOV,
                }}
              />
            ) : (
              <MonthPicker
                {...{
                  setSelectedMonth,
                  selectedMonth,
                  setDueDate,
                  lastPeriod: lastPeriod,
                  chosenSOV: true,
                  dueDate,
                  custom,
                }}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default EditDates;
