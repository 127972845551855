import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";

const DEFAULT_PREFERENCES = {
  liveSidebar: false,
  pageLoaded: "MAP",
  mapType: localStorage.getItem("mapType") || "hybrid",
  refreshRate: 900000,
  mapSidebar: "expanded",
  sidebarTripLocation: "",
  liveSelectedDate: dayjsNY().startOf("D").valueOf(),
  mapStyle: localStorage.getItem("mapStyle") || "light",
  mapSideList: "VEHICLE",
  mapVinFilters: [],
  mapStatusFilter: "",
  auditVehicleSide: "expanded",
  auditSelectedVehicle: "",
  stopModalInfo: "expanded",
  stopRadiusSearchValue: 300,
  stopRadiusSearchUnit: "foot",
  liveTripsToggle: "DATE",
  livePlansToggle: "DATE",
  liveReportsTimeline: [dayjsNY().startOf("M").valueOf(), dayjsNY().valueOf()],
  liveReportsVehicleFilter: "",
  liveReportsLayout: [],
  liveChartTypes: {},
  auditGoToLocation: localStorage.getItem("auditGoToLocation") || "HOVER",
  auditExternalRedirect: { fleetId: undefined, fleetName: undefined },
  afterHoursFromTime: null,
  afterHoursToTime: null,
  liveIdleDuration: 15,
  liveIdleOperation: "gt",
  liveIdleDurationUnit: "m",
  vehicleDateBoundaries: {},
};

export default DEFAULT_PREFERENCES;
