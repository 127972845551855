export const getCustomToolbarBtnConfig = (
  key,
  iconCssClass, // The CSS class for the icon. Check https://pictogrammers.github.io/@mdi/font/2.0.46/ for available icon classes.
  text, // text to display in the button
  enabled,
  action
) => ({
  key: `$${key}`,
  iconCssClass,
  text,
  enabled,
  action,
});
