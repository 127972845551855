import { useMemo, useState } from "react";
import Transfer from "../Transfer/Transfer";
import _ from "lodash";
import { Input, message } from "antd";
import { SearchIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";

//? EDIT CARD
function TeamsCard(props) {
  const { params, setEditMode, getEditedValue, form } = props;
  const { teamsConfiguration } = params;
  const [searchTeamMembers, setSearchTeamMembers] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [getUsers] = useState(
    userConfiguration?.allUsers?.Items.filter(({ isSuspended }) => !isSuspended)
  );
  const [activeFilter, setActiveFilter] = useState(false);

  const teamSelectedValues = useMemo(() => {
    const funcArr = [];
    teamsConfiguration?.map((el) => {
      el?.members?.map((x) => {
        funcArr.push(x.identityId);
      });
    });
    return funcArr;
  }, [teamsConfiguration]);

  const ClearOptions = () => {
    setEditMode(false);
  };

  const getEditedValues = async (team) => {
    function getPrevCurr(teamArr) {
      return {
        id: props?.id,
        Card: "TeamsCard",
        title: props?.title,
        params: {
          id: params?.id,
          formItemName: "teamsConfiguration",
          teamsConfiguration: teamArr,
          logs: true,
        },
      };
    }

    const getFilteredResult = (getUsers, team) => {
      return getUsers.reduce((acc, user) => {
        if (team.members.includes(user.identityId)) {
          const found = acc.find((item) => item.identityId === user.identityId);
          if (!found) {
            acc.push({
              identityId: user.identityId,
              nameOfUser: user.nameOfUser,
              cognitoUserId: user?.cognitoUserId || "",
            });
          }
        }
        return acc;
      }, []);
    };

    const filteredResult = getFilteredResult(getUsers, team);

    // message.success("Teams members are updated successfully", 5);
    let body = {
      teamsConfiguration: [
        {
          value: "Team",
          members: filteredResult || [],
          teamId: params?.teamsConfiguration?.flatMap(
            ({ teamId }) => teamId || []
          ),
        },
      ],
    };
    let newTeam = body?.teamsConfiguration;

    getEditedValue(
      body,
      {},
      {},
      getPrevCurr(params?.teamsConfiguration),
      getPrevCurr(newTeam)
    );
    ClearOptions();
  };

  //* search filter at Teams card
  const onSearchTeam = (e) => {
    let substring = e?.target?.value;
    let filteredData = getUsers?.filter((item) =>
      item?.nameOfUser?.toLowerCase()?.includes(substring?.toLowerCase())
    );
    setSearchTeamMembers(filteredData);
    !!substring ? setActiveFilter(true) : setActiveFilter(false);
  };

  function debounceSearch(func, timeout = 150) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  function getContent() {
    if (!activeFilter) {
      return getUsers?.map((users) => ({
        title: users?.nameOfUser,
        id: users?.identityId,
        role: users?.groupName,
        email: users?.userName,
      }));
    } else {
      return searchTeamMembers?.map((users) => ({
        title: users?.nameOfUser,
        id: users?.identityId,
        role: users?.groupName,
        email: users?.userName,
      }));
    }
  }

  return (
    <div className="teamsCardContainer">
      <div className="headerSearch">
        <div className="iconSearch">
          <SearchIcon style={{ width: 15, height: 15 }} />
        </div>
        <Input
          className="searchEditMembers"
          placeholder="Search members..."
          allowClear
          onChange={debounceSearch((e) => onSearchTeam(e))}
        />
      </div>
      <div className="teamsInnerContainer">
        {!!teamSelectedValues && (
          <Transfer
            params={{
              content: {
                value: teamSelectedValues,
                data: getContent(),
                formItemName: "members",
              },
            }}
            getEditedValue={getEditedValues}
            setEditMode={() => {}}
            setCloseTeamModalsOnCancel={() => {
              onSearchTeam({ target: { value: "" } });
              ClearOptions();
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TeamsCard;
