import types from "../types"

const initialState = {
  authenticatedUser: undefined,
}

const authenticatedUserReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case types.SET_AUTH_USER:
      return {
        ...state,
        authenticatedUser: payload,
      }
    default:
      return state
  }
}

export default authenticatedUserReducer
