import { createContext, useContext } from "react";

export const UserProfileContext = createContext({});

export const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error(
      "useUserProfileContext must be used within a UserProfileContext.Provider!"
    );
  }
  return context;
};
