import React, { Fragment } from "react";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { WarningIcon } from "../../../../../icons";
import { XIcon } from "../../../Communication/assets";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";

const UPLOAD_MESSAGE = {
  "Property Damage":
    "If you choose to continue, you will not be able to change the address anymore",
  "Personal Injury":
    "If you choose to continue, you will not be able to change the Name Of Injured Individual anymore",
  "Vehicle Damage":
    "If you want to continue uploading Address Of Incident can not be changed anymore!",
  "Other Trade Incident":
    "If you want to continue uploading Injured Individual Name cannot be changed anymore!",
};

/**
 * Renders a safety warning section component.
 *
 * @param {string} [defaultId=""] - The default ID.
 * @param {Function} [onSave=() => {}] - The save function.
 * @param {boolean} [isDarkMode=false] - Indicates if the dark mode is enabled.
 * @param {Function} [onClose=() => {}] - The close function.
 * @param {Function} [onDelete=() => {}] - The delete function.
 * @param {string} [safetyCategory=""] - The safety category.
 * @param {boolean} [cancelModal=false] - Indicates if the modal should be canceled.
 * @param {Function} [setActionType=() => {}] - The set action type function.
 * @param {Function} [setCancelModal=() => {}] - The set cancel modal function.
 * @param {Function} [onContinueToUpload=() => {}] - The continue to upload function.
 * @returns {JSX.Element} The rendered component.
 */
function SafetyWarningSection({
  defaultId = "",
  onSave = () => {},
  isDarkMode = false,
  onClose = () => {},
  onDelete = () => {},
  safetyCategory = "",
  cancelModal = false,
  setActionType = () => {},
  setCancelModal = () => {},
  onContinueToUpload = () => {},
}) {
  const warningMessage = () => {
    const strongStyle = { textAlign: "center" };
    if (cancelModal === "Upload") {
      return (
        <Fragment>
          <strong style={strongStyle}>
            {UPLOAD_MESSAGE[safetyCategory] ||
              UPLOAD_MESSAGE["Other Trade Incident"]}
          </strong>
          <p>Are you sure you want to continue?</p>
        </Fragment>
      );
    }
    if (cancelModal === "Delete") {
      return (
        <strong style={strongStyle}>Are you sure you want to delete?</strong>
      );
    } else if (defaultId && cancelModal !== "saveDraft") {
      return (
        <strong style={strongStyle}>Are you sure you want to cancel?</strong>
      );
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <strong>
            {cancelModal !== "saveDraft"
              ? "You are about to exit the form with unsaved data"
              : "You have made some changes"}
          </strong>
          <p>Would you like to save the current changes as a draft?</p>
        </div>
      );
    }
  };

  const warningFooter = () => {
    return (
      <div className="buttons">
        <MondayButton
          onClick={() => {
            if (defaultId || cancelModal === "Upload") {
              setCancelModal(false);
            } else {
              onClose({ deleteAction: true });
            }
          }}
          Icon={<XIcon />}
          className="mondayButtonRed"
        >
          No
        </MondayButton>
        <MondayButton
          onClick={() => {
            if (cancelModal === "Upload") {
              onContinueToUpload();
            } else if (cancelModal === "Delete") {
              setCancelModal(false);
              setActionType("Delete");
              onDelete();
            } else {
              if (defaultId && cancelModal !== "saveDraft") {
                onClose({
                  cancel: true,
                });
              } else {
                onSave(true);
              }
            }
          }}
          Icon={<TickIcon />}
        >
          Yes
        </MondayButton>
      </div>
    );
  };
  return (
    <WarningModal
      {...{
        visible: cancelModal,
        setVisible: setCancelModal,
        title: "Warning Message",
        closable: true,
        className: "logout-warning-modal",
        darkMode: isDarkMode,
      }}
    >
      <div className="logout-modal-body">
        <span>
          <WarningIcon />
        </span>
        {warningMessage()}
        {warningFooter()}
      </div>
    </WarningModal>
  );
}

export default SafetyWarningSection;
