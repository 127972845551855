//this function tell the user why the check all is disabled
export const getDisabledTooltipContent = ({
  selectedService,
  elevationData,
  fakeRentals,
  isWritable,
  params,
}) => {
  //here we find the object of the pli that the lock button is in
  const data = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(({ elevationId }) => elevationId === elevationData?.elevationId)
    ?.items?.find(({ id }) => id === params?.data?.id)

  return !!data?.charged
    ? "This pli is charged you cant unlock it!!" //the case where all pli are charged
    : !isWritable
    ? "Enable write mode to edit!" //the case where write mode is off
    : "" //the case where non of those above are true
}
