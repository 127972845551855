import React from "react";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { nameHandler, widgetOptionsMapping } from "../../Entity/EntityData";
import { XIcon } from "../../../../Communication/assets";

import "./DefaultWidgetInfo.scss";
import DynamicChartIcon from "./DynamicChartIcon";

/**
 * Renders a default widget card with information and selection functionality.
 *
 * @component
 * @param {string} cardKey - The key of the card.
 * @param {Object} el - The widget element object.
 * @param {Function} handleChartSelect - The function to handle chart selection.
 * @param {boolean} selected - Indicates if the widget is selected.
 * @returns {JSX.Element} The rendered DefaultWidgetInfo component.
 */
const DefaultWidgetInfo = ({
  cardKey = "",
  el = {},
  handleChartSelect = () => {},
  selected = false,
}) => {
  return (
    <div
      className="default-widget-card"
      key={`${el?.title}-${el?.id}`}
      id={`${el?.title}-${el?.id}`}
      data-testid={`default-widget-card-${el?.id}`}
    >
      <div className={`default-widget-data`}>
        <div className="default-widget-card-row">
          <div className="default-widget-main-info">
            <div className="default-widget-title">{el?.title}</div>
            <div className="default-widget-table">
              {el?.BodyComponentParams?.table}
            </div>
          </div>
          {DynamicChartIcon(widgetOptionsMapping?.[el?.type])}
        </div>
        <div className="default-widget-card-row">
          <div className="default-widget-type">
            {widgetOptionsMapping?.[el?.type]}
          </div>
          <div className="default-widget-field">
            {nameHandler?.[el?.BodyComponentParams?.field]}
          </div>
        </div>
      </div>
      <div
        className={`default-widget-select ${
          selected ? "list-selected" : "list-default"
        }`}
        onClick={() => handleChartSelect({ chart: el, selected })}
        data-testid={`widget-select-button-${el?.id}`}
      >
        {selected ? <XIcon fill="#fff" /> : <TickIcon />}
      </div>
    </div>
  );
};

export default DefaultWidgetInfo;
