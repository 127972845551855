import types from "../types";

const initialState = {
    booleanIfContact: false,
}

const booleanIfContact = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.SET_BOOLEAN_IF_CONTACT:
            return { booleanIfContact: payload };
        default:
            return state;
    }
};

export default booleanIfContact;