import {
  dispatching,
  documentation,
  estimations,
  fleet,
  fleetInspections,
  leads,
  maintenance,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  safety,
} from "./subCategory";

export const circleProgressBar = (
  IdHandler,
  updatedDynamicStates,
  circleProgressPicker,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData
) => {
  return {
    Leads: () => leads(circleProgressPicker, updatedDynamicStates, IdHandler),
    Claims: () => claims(circleProgressPicker, updatedDynamicStates, IdHandler),
    Opportunities: () =>
      opportunities(circleProgressPicker, IdHandler, updatedDynamicStates),
    Projects: () =>
      projects(circleProgressPicker, IdHandler, updatedDynamicStates),
    Documentation: () =>
      documentation(circleProgressPicker, IdHandler, updatedDynamicStates),
    Scheduling: () =>
      scheduling(circleProgressPicker, IdHandler, updatedDynamicStates),
    Dispatching: () =>
      dispatching(circleProgressPicker, IdHandler, updatedDynamicStates),
    Fleet: () => fleet(circleProgressPicker, IdHandler, updatedDynamicStates),
    "Fleet Inspections": () =>
      fleetInspections(circleProgressPicker, IdHandler, updatedDynamicStates),
    Incidents: () =>
      incidents(circleProgressPicker, IdHandler, updatedDynamicStates),
    Maintenance: () =>
      maintenance(circleProgressPicker, IdHandler, updatedDynamicStates),
    Violations: () =>
      violations(circleProgressPicker, IdHandler, updatedDynamicStates),
    Estimations: () =>
      estimations(circleProgressPicker, IdHandler, updatedDynamicStates),
    Applications: () =>
      applications(
        circleProgressPicker,
        IdHandler,
        updatedDynamicStates,
        applicationsData
      ),
    Rentals: () =>
      rentals(
        circleProgressPicker,
        IdHandler,
        updatedDynamicStates,
        rentalsData
      ),
    Charges: () =>
      charges(
        circleProgressPicker,
        IdHandler,
        updatedDynamicStates,
        chargesData
      ),
    Invoices: () =>
      invoices(
        circleProgressPicker,
        IdHandler,
        updatedDynamicStates,
        invoicesData
      ),
    Safety: () => safety(circleProgressPicker, IdHandler, updatedDynamicStates),
  }; //CircleProgressLine
};
