import { Modal, message } from "antd";
import { useEffect, useState } from "react";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { LoadingDots } from "../../../../../../commonComponents/3LoadingDots/LoadingDots";
import { driveApi } from "../../../../../../../integrations/DriveRequest";

const PlayVideoTutorial = ({ open, setVisible, url, title }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [playing, setPlaying] = useState(false);
  const [fileSrc, setFileSrc] = useState("");
  const [loading, setLoading] = useState(false);

  const driveReq = driveApi({ accessToken });

  const play = () => setPlaying(true);
  const pause = () => setPlaying(false);

  useEffect(() => {
    setLoading(true);
    driveReq
      .getImageSrc(url.split("=")[1])
      .then((res) => {
        setLoading(false);
        setFileSrc(res);
      })
      .catch((err) => {
        console.log("ERR: ", err);
        setLoading(false);
        message.error("Error loading video");
      });
  }, [url]);

  return (
    <Modal
      {...{
        open,
        setVisible,
        centered: true,
        onCancel: () => setVisible(false),
        footer: null,
        closeIcon: <XIcon />,
        className: `customVideoTutorialModal ${
          isDarkMode && "customVideoTutorialModalDark"
        }`,
        title: title ? title : null,
      }}
    >
      <div className={`${!title ? "modalBody" : ""}`}>
        {!loading ? (
          <ReactPlayer
            {...{
              url: [{ src: fileSrc.src }],
              playbackRate: 1,
              height: "100%",
              width: "100%",
              controls: true,
              onReady: play,
              onStart: play,
              playing,
              onPlay: () => {
                play();
              },
              onPause: pause,
              light: false,
              playsinline: true,
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
            }}
          >
            <LoadingDots style={{ fontSize: "50px" }} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PlayVideoTutorial;
