import { TimePicker } from "antd";
import dayjs from "dayjs";
import "./TimePicker.scss";

function FormItemTimePicker({
  value,
  form,
  disabled,
  onChange,
  isDarkMode = false,
  name,
  use12Hours = true,
  format = "h:mm a",
  status = "",
  showNow = true,
  disabledTime = undefined,
  newDate,
}) {
  function setDateMonth(value, date, customDay) {
    return dayjs(value)
      .set("year", dayjs(date).get("year"))
      .set("month", dayjs(date).get("month"))
      .set("date", customDay || dayjs(date).get("date"));
  }
  return (
    <div className="EditTimePicker">
      <TimePicker
        {...{
          value,
          disabled,
          use12Hours,
          format,
          onChange: (e) => {
            form.setFieldsValue({
              [name]: setDateMonth(e, newDate),
            });
          },
          status,
          showNow,
          className: `formItemTimePicker ${
            isDarkMode && "formItemTimePickerDark"
          }`,
          disabledTime,
          popupClassName: `formItemTimePickerPopup ${
            isDarkMode && "formItemTimePickerPopupDark"
          }`,
          // suffixIcon
        }}
      />
    </div>
  );
}

export default FormItemTimePicker;
