// using classes for more efficient memory usage
import _ from "lodash";
import { totalitiesExtractor } from "../../../../../../../SidebarPages/Projects/Accounting/Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/ServiceRentHeader/utils";
import { currencyFormatter } from "../../../../../../../SidebarPages/utils/currencyFormatter";
import { formatCurrency } from "../../../../../../../SidebarPages/utils/formatCurrency";
class Rentals {
  constructor(rentalsType) {
    this.rentalsType = rentalsType;
    this.defiance = indexes[rentalsType] || {};
  }

  getRentalsGeneral(rentalsKeys) {
    return rentalsKeys?.map?.((rentalKey) => {
      return this.defiance[rentalKey]?.(this.rentalsType[rentalKey]);
    });
  }

  getApplicableRentalKeys() {
    return Object.keys(this.defiance);
  }
}

export const getMyRentals = (rentalType) => {
  const rental = new Rentals(rentalType);
  return rental
    .getApplicableRentalKeys()
    ?.map((el) => mapKeysRentals[el] || el)
    .filter(Boolean);
};

export const GENERAL_RENTAL_DESC_DEFINERS = {
  charged: (chargedBoolean) => {
    return chargedBoolean ? "Charged" : "Not Charged";
  },
  createdBy: (createdBy) => {
    return createdBy || "Unknown Created By";
  },
  projectTaxRate: (projectTaxRate) => {
    return projectTaxRate || 0;
  },
  rentalNumber: (rentalNumber) => {
    return rentalNumber || "Unknown Rental Number";
  },
  rentalStatus: (rentalStatus) => {
    return rentalStatus || "Unknown Rental Status";
  },
  services: (services) => {
    return (
      services
        ?.map?.((service) => {
          return service?.label || null;
        })
        .filter(Boolean)
        ?.join(", ") || []
    );
  },
  taxRate: (taxRate) => {
    return taxRate || 0;
  },
  taxAmount: (taxAmount) => {
    return formatCurrency(taxAmount || 0).toString();
  },
  totalPrice: (totalPrice) => {
    return formatCurrency(totalPrice || 0).toString();
  },
  projectAddress: (projectAddress) => {
    return projectAddress || "Unknown Project Address";
  },
  charged: (charged) => {
    return charged ? "Charged" : "Not Charged";
  },
  chargedFromReq: (chargedFromReq) => {
    return chargedFromReq ? "Charged From Req" : "Not Charged From Req";
  },
};

const RENTAL_SERVICE_DEFINERS = {
  serviceName: (serviceName) => {
    return serviceName || "Unknown Service Name";
  },
  rentPercentage: (rentPercentage) => {
    return rentPercentage || 0;
  },
  retainagePercentage: (retainagePercentage) => {
    return retainagePercentage || 0;
  },
  elevations: (elevations) => {
    return (
      elevations
        ?.map((el) => {
          {
            return el?.elevationLabel;
          }
        })
        ?.join(", ") || "Unknown Elevations"
    );
  },
  appliedRent: (appliedRent) => {
    return appliedRent || 0;
  },
  totalPrice: (totalPrice) => {
    return totalPrice || 0;
  },
  totalTax: (totalTax) => {
    return totalTax || 0;
  },
  totalRetainage: (totalRetainage) => {
    return totalRetainage || 0;
  },
};

const indexes = {
  rentalsGeneral: GENERAL_RENTAL_DESC_DEFINERS,
  rentalsService: RENTAL_SERVICE_DEFINERS,
};

export const rentalFurtherProcessing = ({ rental = {} }) => {
  const rentalObject = _.cloneDeep(rental);
  const myRental = {
    rentalNumber: rentalObject?.rentalNumber,
    rentalStatus: rentalObject?.rentalStatus,
    createdBy: rentalObject?.createdBy,
    charged: rentalObject?.charged,
    projectTaxRate: rentalObject?.projectTaxRate,
    taxRate: rentalObject?.taxRate,
    totalPrice: rentalObject?.totalPrice,
    taxAmount: rentalObject?.totalPrice * rentalObject?.taxRate,
    services: rentalObject?.services,
    projectAddress: rentalObject?.projectAddress,
    charged: rentalObject?.charged,
    chargedFromReq: rentalObject?.chargedFromReq,
    createdAt: rentalObject?.createdAt,
  };
  return myRental;
};

export const rentalServiceProcessing = ({
  rentalService = {},
  rental = {},
}) => {
  const rentalServiceObject = _.cloneDeep(rentalService);
  const rentalObject = _.cloneDeep(rental);
  const temp = totalitiesExtractor(rentalService, rentalObject);
  const myRentalService = {
    serviceName: rentalServiceObject?.label,
    rentPercentage: rentalServiceObject?.rentPercentage,
    retainagePercentage: rentalServiceObject?.retainage,
    elevations: rentalServiceObject?.serviceOptions || [],
    appliedRent: temp?.appliedRent || {},
    totalPrice: temp?.totalPrice || {},
    totalTax: temp?.totalTax || {},
    totalRetainage: rentalServiceObject?.retainage * temp?.totalPrice || {},
  };
  console.log("rentalServiceObject", { rentalServiceObject, temp });
  return myRentalService;
};

// USED T0 MAKE SENSE OF THE DATA

export const mapKeysRentals = {
  charged: "Charged Type?",
  createdBy: "Created By",
  projectTaxRate: "Project Tax Rate",
  rentalNumber: "Rental Number",
  rentalStatus: "Rental Status",
  services: "Services",
  taxRate: "Tax Rate",
  taxAmount: "Tax Amount",
  totalPrice: "Total Price",
  projectAddress: "Project Address",
  chargedFromReq: "Charged From Requisition?",
  serviceName: "Service Name",
  rentPercentage: "Rent Percentage",
  retainagePercentage: "Retainage Percentage",
  elevations: "Elevations",
  appliedRent: "Applied Rent",
  totalPrice: "Total Price",
  totalTax: "Total Tax",
  totalRetainage: "Total Retainage",
};
