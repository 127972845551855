export function formatFloorHeight(floor_height) {
  if (isNaN(floor_height)) {
    return ""; // Return empty string if floor_height is NaN
  }

  var feet = Math.floor(floor_height);
  var inches = Math.round((floor_height - feet) * 12);
  var result = feet + "'" + inches + '"';
  return result;
}
