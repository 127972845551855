import { SimplePLI } from "./GeneralModels"

export class WindowProtectionPLI extends SimplePLI {
  serviceId = 19

  length
  height

  count

  constructor(id, taxRate) {
    super(id, taxRate)
  }
}
