import { queryParams } from "../../../../../../../../utils/queryParams";

export const exitIfInEditMode = (
  location,
  objectData,
  currentCharge,
  navigate
) => {
  if (location.state?.goBackTo === "accountingGrid") {
    navigate(-1);
  } else {
    const accountingTab =
      objectData?.type === "Credit Memo" ||
      currentCharge?.type === "Credit Memo"
        ? "Credit"
        : "Charges";

    navigate(
      `/projects/${currentCharge.projectId}?${queryParams({
        tab: "Accounting",
        subTab: accountingTab,
      })}`,
      {
        fromAccounting: true,
        accountingTab,
        tabPosition: "accounting",
      }
    );
  }
};
