import uniq from "lodash/uniq";
import { repeatedElementSum } from "../../../../NewDashboardFunctions";

export const incidents = ({ tables }) => {
  return {
    driverName: () => ({
      content: uniq(tables?.Incidents?.map((a) => a?.driverName))?.map(
        (status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Incidents?.filter((a) => a?.driverName === status).length /
              tables?.Incidents?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Incidents?.filter((a) => a?.driverName === status)
            .length,
        })
      ),
    }),

    fleetName: () => ({
      content: uniq(tables?.Incidents?.map((a) => a?.fleetName))?.map(
        (status) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (tables?.Incidents?.filter((a) => a?.fleetName === status).length /
              tables?.Incidents?.length) *
            100
          )?.toFixed(1),
          showInfo: true,
          status: status,
          done: tables?.Incidents?.filter((a) => a?.fleetName === status)
            .length,
        })
      ),
    }),

    incidentStatus: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.Incidents, "incidentStatus")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Incidents?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    incidentCategory: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.Incidents, "incidentCategory")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Incidents?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    incidentName: () => ({
      content: Object?.entries(
        repeatedElementSum(tables?.Incidents, "incidentName")
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Incidents?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),

    projectName: () => ({
      content: Object?.entries(
        repeatedElementSum(
          tables?.Incidents?.map((a) => a?.incidentObject),
          "projectName"
        )
      ).map(([key, value]) => ({
        color: "#71CF48",
        unfilledColor: "#F5F5F5",
        percent: ((value / tables?.Incidents?.length) * 100).toFixed(1),
        showInfo: true,
        status: key,
        done: value,
      })),
    }),
  };
};
