import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { dateFormat, timeFormat } from "../utils/routeFields";

export function specificSelectionColumns({ selectedDate, projects }) {
  return [
    {
      headerName: "Address",
      field: "scheduleAddress",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    {
      headerName: "Schedule Name",
      field: "scheduleName",
    },
    {
      headerName: "Account Name",
      valueGetter({ data }) {
        const { projectId } = data;
        if (!projectId) {
          return "";
        }

        return (
          projects.find(({ projectId: id }) => id === projectId)?.accountName ||
          ""
        );
      },
    },
    {
      headerName: "Type of Work",
      field: "typeOfWork",
    },
    {
      headerName: "Start Time",
      cellRenderer({ data }) {
        let scheduleDay = data?.scheduleDays?.find(
          ({ startDate }) =>
            dayjsNY(startDate).format(dateFormat) ===
            dayjsNY(selectedDate).format(dateFormat)
        );
        return (
          <span>{dayjsNY(scheduleDay?.startDate).format(timeFormat)}</span>
        );
      },
    },
  ];
}
