import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CommunicationItemView from "./communication-item-view/CommunicationItemView";
import { Empty } from "antd";
import { getThisWeekTimestamps, getTodayTimestamps } from "../../utils/helpers";

import "./communication-tab-view.scss";

function CommunicationTabView({
  currentPage,
  searchValue,
  filteredEmails,
  setFilteredEmails,
}) {
  const { communication, filterBy } = useSelector(
    (state) => state.notifications
  );
  const { emails } = communication;

  useEffect(() => {
    const filteredBySearch = emails?.filter((email) => {
      const { from, to, subject } = email;
      const searchLower = searchValue.toLowerCase();

      return (
        from?.toLowerCase().includes(searchLower) ||
        to?.toLowerCase().includes(searchLower) ||
        subject?.toLowerCase().includes(searchLower)
      );
    });

    let filteredByEmails = filteredBySearch;

    if (filterBy) {
      filteredByEmails = filteredBySearch.filter((email) => {
        const { labels, timestampDate } = email;
        const timestamp = timestampDate;

        switch (filterBy) {
          case "today": {
            const { startOfToday, endOfToday } = getTodayTimestamps();
            return timestamp >= startOfToday && timestamp <= endOfToday;
          }
          case "thisWeek": {
            const { startOfWeek, endOfWeek } = getThisWeekTimestamps();
            return timestamp >= startOfWeek && timestamp <= endOfWeek;
          }
          case "important": {
            return labels.includes("IMPORTANT");
          }
          default:
            return labels.includes("UNREAD");
        }
      });
    }

    setFilteredEmails(filteredByEmails);
  }, [emails, filterBy, searchValue]);

  const paginatedEmails = useMemo(() => {
    const startIndex = (currentPage - 1) * 24;
    const endIndex = Math.min(startIndex + 24, filteredEmails.length);
    return filteredEmails.slice(startIndex, endIndex);
  }, [currentPage, filteredEmails]);

  if (filteredEmails.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="no-data-view" />
    );
  }

  return (
    <div className="communication-tab-view-container">
      {paginatedEmails.map((email, key) => (
        <CommunicationItemView key={key} email={email} />
      ))}
    </div>
  );
}

export default CommunicationTabView;
