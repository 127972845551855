import React from "react";
import {
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import { WarningIcon } from "../../../../../../../../assets";
import { XIcon } from "../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";

const ModalWarning = ({ isOpen, setIsOpen, onConfirm }) => {
  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onConfirm();
    }
  };

  return (
    <WarningModal
      visible={isOpen}
      setVisible={setIsOpen}
      title="Warning Message"
      closable={true}
      className="logout-warning-modal"
      onKeyPress={onEnterPress}
    >
      <div className="logout-modal-body">
        <span data-testid="warning-icon">
          <WarningIcon />
        </span>
        <p>Are you sure you want to cancel?</p>
        <div className="buttons">
          <MondayButton
            onClick={() => setIsOpen(false)}
            Icon={<XIcon />}
            className="mondayButtonRed"
          >
            No
          </MondayButton>
          <MondayButton onClick={onConfirm} Icon={<TickIcon />}>
            Yes
          </MondayButton>
        </div>
      </div>
    </WarningModal>
  );
};

export default ModalWarning;
