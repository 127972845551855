import { repeatedElementSum } from "../../../NewDashboardFunctions";

export const approvals = (IdHandler, updatedDynamicStates, reducedUsers) => ({
  userRequesting: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "userRequesting"
    );

    return {
      title: "Approvals User Requesting",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Approvals",
        field: "userRequesting",
        title: "Approvals User Requesting",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: reducedUsers?.[key]?.nameOfUser || key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    };
  },

  assignedTo: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "assignedTo"
    );

    return {
      title: "Approvals Assigned To",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Approvals",
        field: "assignedTo",
        title: "Approvals Assigned To",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: reducedUsers?.[key]?.nameOfUser || key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    };
  },

  category: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "category"
    );

    return {
      title: "Approvals Category",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Approvals",
        field: "category",
        title: "Approvals Category",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key?.capitalize() || key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    };
  },

  responseFrom: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "responseFrom"
    );

    return {
      title: "Approvals Response From",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Approvals",
        field: "responseFrom",
        title: "Approvals Response From",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: reducedUsers?.[key]?.nameOfUser || key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    };
  },

  requestType: () => {
    const data = repeatedElementSum(
      updatedDynamicStates?.[IdHandler] || [],
      "requestType"
    );

    return {
      title: "Approvals Request Type",
      BodyComponent: "StreightProgressBar",
      type: "StreightProgressBar",
      BodyComponentParams: {
        table: "Approvals",
        field: "requestType",
        title: "Approvals Request Type",
        subtitle: "",
        content: Object.entries(data)?.map(([key, value]) => ({
          color: "#71CF48",
          unfilledColor: "#F5F5F5",
          percent: (
            (value / updatedDynamicStates?.[IdHandler]?.length) *
            100
          ).toFixed(1),
          showInfo: true,
          status: key,
          done: value,
        })),
      },
      id: IdHandler,
      width: "390px",
    };
  },
});
