import React from "react";
import { AgGridReact } from "ag-grid-react";
// import { LicenseManager } from "ag-grid-enterprise";

// LicenseManager.setLicenseKey(
//   "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-27_September_2020_[v2]_MTYwMTE2MTIwMDAwMA==fa343a1979d9d3e95ba19ab21c69128e"
// );

const AgGrid = (props) => (
    <AgGridReact
      pivotPanelShow="always"
      allowDragFromColumnsToolPanel={true}
      enableRangeSelection={true}
      animateRows={true}
      suppressDragLeaveHidesColumns={true}
      rowGroupPanelShow="always"
      headerHeight={30}
      rowHeight={35}
      fontSize={18}
      pagination={true}
      suppressPropertyNamesCheck={true}
      {...props}
    />
);

export default AgGrid;
