import { groupBy } from "lodash";
import { useSelector } from "react-redux";
import { forwardRef, useContext, useImperativeHandle, useMemo } from "react";

import { useResponsive } from "src/hooks";
import { PersonLocationIcon } from "src/assets/icons";
import PayrollLiveContext from "../../PayrollLiveContext";
import { EmployeeReportType } from "../../payrollLiveTypes";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./MapStatusCards.scss";

const MapStatusCards = forwardRef((_, ref) => {
  const { filters, setFilters, employeesReport, mapRef } =
    useContext(PayrollLiveContext);

  const { width } = useResponsive();

  const liveStatusTypes = useMemo(() => {
    return groupBy(employeesReport, (el: EmployeeReportType) => el.liveStatus);
  }, [employeesReport]);

  const noMatchEmployees = useMemo(() => {
    return employeesReport.filter((el) => el?.color === "#e9c466");
  }, [employeesReport]);

  function liveStatusFilter(status: string) {
    let tmpLiveStatusFilters = filters.liveStatus;
    if (tmpLiveStatusFilters.includes(status)) {
      tmpLiveStatusFilters = tmpLiveStatusFilters.filter(
        (sts: string) => sts !== status
      );
    } else {
      tmpLiveStatusFilters = tmpLiveStatusFilters.concat(status);
    }
    let coordinateMarkers = [];
    for (let i = 0; i < employeesReport.length; i++) {
      const emp = employeesReport[i];
      if (
        tmpLiveStatusFilters.includes(emp.liveStatus) &&
        emp?.punchCoordinates?.lat
      ) {
        coordinateMarkers.push(emp.punchCoordinates);
      }
      if (
        status === "no-match" &&
        tmpLiveStatusFilters.includes(emp?.color) &&
        emp?.punchCoordinates?.lat
      ) {
        coordinateMarkers.push(emp.punchCoordinates);
      }
    }
    setFilters((prev) => ({
      ...prev,
      liveStatus: tmpLiveStatusFilters,
    }));
    if (mapRef.current) {
      mapRef.current.fitBoundsToMarkers(coordinateMarkers);
    }
  }

  useImperativeHandle(ref, () => {
    return {};
  });

  return (
    <section className="map-status-cards">
      {Object.keys(liveStatusTypes).map((key) => {
        return (
          <div
            className={`status-card ${key.replaceAll(" ", "")} ${
              filters.liveStatus.includes(key) ? "active" : ""
            }`}
            onClick={() => liveStatusFilter(key)}
          >
            <span style={{ fontWeight: 600 }}>
              {liveStatusTypes[key]?.length}
            </span>
            {` ${key}`}
            {width >= 1180 ? <PersonLocationIcon fill="#fff" /> : null}
          </div>
        );
      })}
      {noMatchEmployees?.length ? (
        <div
          className={`status-card no-match ${
            filters.liveStatus.includes("no-match") ? "active" : ""
          }`}
          onClick={() => liveStatusFilter("no-match")}
        >
          <span style={{ fontWeight: 600 }}>
            {noMatchEmployees?.length}/{liveStatusTypes["In"]?.length}
          </span>
          {` ${"Don't Match"}`}
          {width >= 1180 ? <PersonLocationIcon /> : null}
        </div>
      ) : null}
      <div className="total-employees-count">
        Total Employees on Site{" "}
        <span>
          {employeesReport.filter((el) => el.liveStatus !== "No Punch")?.length}
        </span>
      </div>
    </section>
  );
});
export default MapStatusCards;
