import React from "react";
import { Checkbox } from "antd";

export class CheckboxRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.node.data?.[props.column.colId],
    };
  }

  render() {
    if (this.props.node.group) {
      return <span>-</span>;
    } else {
      return (
        <>
          {this.state.checked ? "Approved" : "Not Approved"}
          {/* <Checkbox checked={this.state.checked} /> */}
        </>
      );
    }
  }
}
