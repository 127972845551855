import { useDispatchContext, useStateContext } from "../../../../../../Context/Context";
import { compleatedHandler, completeAllHandler } from "./compleateAllFunctions";
import "./CompleateAll.scss";

import { useSelector } from "react-redux";
import { Checkbox, message, Tooltip } from "antd";

const CompleateAll = ({ service }) => {
	const { isLoading } = useSelector((state) => state.applications);
	const { isWritable, fakeApplication } = useStateContext();
	const dispatchContext = useDispatchContext();

	//here we extract the value of the checkbox
	const { someChecked, allChecked, charged } =
		compleatedHandler({ fakeApplication, service }) || {};

	//is called user click on the checkbox
	const completeAll = () =>
		completeAllHandler({
			fakeApplication,
			dispatchContext,
			allChecked,
			service,
		});

	return (
		<div className="completeAllContainer">
			<Tooltip title={charged ? "All events are charged" : ""}>
				<Checkbox
					onClick={() => !isWritable && message.error("Please enable Edit Mode!")}
					className={!isWritable ? "checkBoxNotClickable" : ""}
					checked={someChecked}
					indeterminate={someChecked && !allChecked}
					//disabled={!!isLoading || !isWritable || charged}
					onChange={(e) => isWritable && completeAll(e)}
				/>
			</Tooltip>
		</div>
	);
};

export default CompleateAll;
