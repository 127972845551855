import { message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { API } from "aws-amplify";

export async function exportToXLSX(
  name,
  accountName,
  result,
  accessToken,
  folderId,
  customName,
  fakeApplication
) {
  console.log("name", name, "accountName", accountName, "result", result);
  let temp = result?.applicationData?.workSheet1DataExcel || [];
  let services = getServicesName(
    result?.applicationData?.workSheet2DataExcel?.map((item) => item?.label)
  );
  let fileName = !!temp?.applicationNo
    ? name.replace(/\W+/g, " ") +
      ` Req#${temp?.applicationNo} ${temp?.periodTo} ` +
      ".xlsx"
    : name + ".xlsx";
  const url =
    "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // console.log("temp", {
  // 	accountName: accountName,
  // 	name: name,
  // 	apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
  // 	...result,
  // });
  // const url = "http://localhost:8080/api/export/xlsx";
  // const url = "http://10.0.0.85:8080/api/export/xlsx";
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      accountName: accountName,
      name: name,
      apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
      ...result,
    }),
  })
    .then(async (response) => {
      hideLoading();
      console.log("response", response);
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
      // blob to 64
      if (!accessToken) return;
      const reader = new FileReader();
      reader.onload = async function () {
        var b64 = reader.result.replace(/^data:.+;base64,/, "");
        const driveRequest = driveApi({ accessToken });
        await driveRequest
          .uploadFile(
            b64,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            {
              name: customName,
              parents: [folderId],
              //   mimeType:
              mimeType: "application/vnd.google-apps.spreadsheet",
            },
            true
          )
          .then((response) => response.json());
      };
      attachDriveIdToRequisition(fakeApplication, folderId);

      reader.readAsDataURL(response.data);
    })
    .catch((err) => {
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
}

const getServicesName = (services) => {
  if (Array.isArray(services)) {
    console.log("services", services.join(", "));
    return services.join(", ");
  }
};

const attachDriveIdToRequisition = async (fakeApplication, driveId) => {
  console.log("fakeApplication", fakeApplication);
  // if (fakeApplication?.applicationNo === 1) {
  await API.put(
    "applications",
    `/applications/${fakeApplication?.applicationId}`,
    {
      body: {
        googleDriveLocationId: driveId,
      },
    }
  );
  // }
};
