import dayjs from "dayjs";

function getScheduleDateRange(schedules = []) {
  if (!schedules || !schedules?.length) return "-";
  const allDays = schedules
    ?.flatMap((el) => el?.days)
    ?.sort((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate));
  const firstDay = allDays?.[0]?.startDate;
  const lastDay = allDays?.[allDays?.length - 1]?.endDate;
  return `${dayjs(firstDay).format("MM/DD/YYYY")} - ${dayjs(lastDay).format(
    "MM/DD/YYYY"
  )}`;
}

export default getScheduleDateRange;
