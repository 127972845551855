import { formatCurrency } from "../../../../utils";
import {
  axesCount,
  axesTooltipWithCurrency as axesTooltip,
  pieCount,
  pieTooltipWithCurrency as pieTooltip,
} from "../../../../../../helpers/axesTooltip";

//holds custom tooltip rendering functions

const totalOnClient = {
  title: {
    text: "Total Invoice Amount",
  },
  subtitle: {
    text: "Based on Client",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "customer",
        yKey: ["amount"],
        yName: ["Invoice Amount"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "customer",
        angleKey: ["amount"],
        angleName: "Invoice Amount",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const totalOnProject = {
  title: {
    text: "Total Invoice Amount",
  },
  subtitle: {
    text: "Based on Project",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "project",
        yKey: ["amount"],
        yName: ["Invoice Amount"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "project",
        angleKey: ["amount"],
        angleName: "Invoice Amount",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const totalOnStatus = {
  title: {
    text: "Total Invoice Amount",
  },
  subtitle: {
    text: "Based on Project Status",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "invoiceStatus",
        yKey: ["amount"],
        yName: ["Invoice Amount"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "invoiceStatus",
        angleKey: ["amount"],
        angleName: "Invoice Amount",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const totalOnCategory = {
  title: {
    text: "Total Invoice Amount",
  },
  subtitle: {
    text: "Based on Category",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "categoryFrom",
        yKey: ["amount"],
        yName: ["Invoice Amount"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
      {
        type: "category",
        label: {
          formatter: (params) =>
            `${params.value.charAt(0).toUpperCase() + params.value.slice(1)}`,
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "categoryFrom",
        angleKey: ["amount"],
        angleName: "Invoice Amount",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },
};

const countOnDate = {
  title: {
    text: "Total Number of Records",
  },
  subtitle: {
    text: "Based on Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynamicTime",
        yKey: ["project"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesCount(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynamicTime",
        angleKey: ["project"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieCount(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show total invoice amount based on client",
    params: totalOnClient,
  },
  {
    optionTitle: "Show total invoice amount based on project",
    params: totalOnProject,
  },
  {
    optionTitle: "Show total invoice amount based on project status",
    params: totalOnStatus,
  },
  {
    optionTitle: "Show total invoice amount based of category",
    params: totalOnCategory,
  },
  { optionTitle: "Show total record count based on date", params: countOnDate },
  {
    dynamicKeys: [
      {
        key: "dynamicTime",
        keyFunction: "dateGetter",
        optionalParams: [["project", "quickBooksNumber"], "date"],
      },
    ],
  },
];

export default chartingOptions;
