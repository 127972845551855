import moment from "moment";

export function getTopics(data, priority) {
  const filteredResults = [];
  const uniqueCategories = new Set();

  data.forEach((obj) => {
    if (Array.isArray(obj.templates)) {
      const filteredTemplates = obj.templates.filter(
        (template) =>
          template.templateContent.priority.toLowerCase() === priority
      );

      filteredTemplates.forEach((template) => {
        if (
          template.templateCategory &&
          !uniqueCategories.has(template.templateCategory)
        ) {
          uniqueCategories.add(template.templateCategory);
          filteredResults.push({
            templateCategory: template.templateCategory,
          });
        }
      });
    }
  });

  return filteredResults;
}

export const getTodayTimestamps = () => {
  const startOfToday = new Date().setHours(0, 0, 0, 0);
  const endOfToday = new Date().setHours(23, 59, 59, 999);

  return { startOfToday, endOfToday };
};

export const getThisWeekTimestamps = () => {
  const startOfWeek = new Date();
  startOfWeek.setHours(0, 0, 0, 0);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  const endOfWeek = new Date(startOfWeek); // Start from the startOfWeek date
  endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to the end of the week
  endOfWeek.setHours(23, 59, 59, 999);

  return {
    startOfWeek: startOfWeek.getTime(),
    endOfWeek: endOfWeek.getTime(),
  };
};

export const generateFiltersForTopics = (topics, tab, sub) => {
  if (topics.length === 0) return [];

  const topicsArray = topics.map(({ templateCategory }) => templateCategory);

  if (tab === "all") {
    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "notificationObject.topic",
            value: topicsArray,
            formula: "is_one_of",
          },
          {
            operator: "AND",
            column: `cognitoUserId.${sub}.hasBeenHidden`,
            value: false,
            formula: "is",
          },
        ],
      },
    ];
  }

  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.topic",
          value: topicsArray,
          formula: "is_one_of",
        },
      ],
    },
  ];
};

export const generateFiltersForDate = (filterBy, tab, sub) => {
  if (filterBy === "today") {
    const { startOfToday, endOfToday } = getTodayTimestamps();
    if (tab === "all") {
      return [
        {
          conditions: [
            {
              operator: "AND",
              column: "createdAt",
              value: [startOfToday, endOfToday],
              formula: "is between",
            },
            {
              operator: "AND",
              column: `cognitoUserId.${sub}.hasBeenHidden`,
              value: false,
              formula: "is",
            },
          ],
        },
      ];
    }

    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "createdAt",
            value: [startOfToday, endOfToday],
            formula: "is between",
          },
        ],
      },
    ];
  } else {
    const { startOfWeek, endOfWeek } = getThisWeekTimestamps();
    if (tab === "all") {
      return [
        {
          conditions: [
            {
              operator: "AND",
              column: "createdAt",
              value: [startOfWeek, endOfWeek],
              formula: "is between",
            },
            {
              operator: "AND",
              column: `cognitoUserId.${sub}.hasBeenHidden`,
              value: false,
              formula: "is",
            },
          ],
        },
      ];
    }

    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "createdAt",
            value: [startOfWeek, endOfWeek],
            formula: "is between",
          },
        ],
      },
    ];
  }
};

export const generateFiltersForHidden = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenHidden`,
          value: true,
          formula: "is",
        },
      ],
    },
  ];
};

export const generateFiltersForUnread = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenSeen`,
          value: false,
          formula: "is",
        },
      ],
    },
  ];
};

export const generateFiltersForIWasMentioned = (data) => {
  const filteredResults = [];
  data.forEach((obj) => {
    if (Array.isArray(obj?.templates)) {
      const filteredTemplates = obj.templates?.filter(
        (template) =>
          template?.templateAction?.toLowerCase() === "oncommentmention"
      );
      filteredResults.push(...filteredTemplates);
    }
  });

  return generateFiltersForTopics(filteredResults) || [];
};

export const generateFiltersForAssignedToMe = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.action",
          value: "onTaskAssignation",
          formula: "is",
        },
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenHidden`,
          value: false,
          formula: "is",
        },
      ],
    },
  ];
};

export const generateFiltersForSessions = () => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.topic",
          value: "110",
          formula: "is",
        },
      ],
    },
  ];
};

export const combineObjectsAndCreateConditionsArrayFilters = (obj1, obj2) => {
  return [
    {
      conditions: [obj1, obj2],
    },
  ];
};

export function getFilters(data, filterBy, tab, sub) {
  const isTopic = ["important", "critical", "info"];
  const isDate = ["today", "thisweek"];

  const baseFilter = (column, value) => ({
    conditions: [
      {
        operator: "AND",
        column: `cognitoUserId.${sub}.${column}`,
        value,
        formula: "is",
      },
    ],
  });

  const combineFilters = (filters1, filters2) => {
    return combineObjectsAndCreateConditionsArrayFilters(
      filters1[0].conditions[0],
      filters2[0].conditions[0]
    );
  };

  if (filterBy === "") {
    if (tab === "all") return [baseFilter("hasBeenHidden", false)];
    if (tab === "hidden") return generateFiltersForHidden(sub);
    if (tab === "iwasmentioned") return generateFiltersForIWasMentioned(data);
    if (tab === "assignedtome") return generateFiltersForAssignedToMe(sub);
    if (tab === "sessions") return generateFiltersForSessions();
  }

  if (tab === "all") {
    if (isTopic.includes(filterBy)) {
      return (
        generateFiltersForTopics(getTopics(data, filterBy), tab, sub) || []
      );
    }
    if (isDate.includes(filterBy))
      return generateFiltersForDate(filterBy, tab, sub);

    if (filterBy === "unread") {
      return generateFiltersForUnread(sub);
    }
  }

  if (tab === "hidden") {
    const filtersForHidden = generateFiltersForHidden(sub);
    if (isTopic.includes(filterBy)) {
      return combineFilters(
        filtersForHidden,
        generateFiltersForTopics(getTopics(data, filterBy))
      );
    }
    if (filterBy === "unread") {
      return combineFilters(filtersForHidden, generateFiltersForUnread(sub));
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(filtersForHidden, generateFiltersForDate(filterBy));
    }
  }

  if (tab === "iwasmentioned") {
    const filtersForIWasMentioned = generateFiltersForIWasMentioned(data);
    if (isTopic.includes(filterBy)) {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForTopics(getTopics(data, filterBy))
      );
    }
    if (filterBy === "unread") {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForUnread(sub)
      );
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForDate(filterBy)
      );
    }
  }

  if (tab === "assignedtome") {
    const filtersForAssignedToMe = generateFiltersForAssignedToMe(data);
    if (isTopic.includes(filterBy)) {
      return filtersForAssignedToMe;
    }
    if (filterBy === "unread") {
      return combineFilters(
        filtersForAssignedToMe,
        generateFiltersForUnread(sub)
      );
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForAssignedToMe,
        generateFiltersForDate(filterBy)
      );
    }
  }

  if (tab === "sessions") {
    const filtersForSessions = generateFiltersForSessions();
    if (isTopic.includes(filterBy)) {
      return filtersForSessions;
    }
    if (filterBy === "unread") {
      return combineFilters(filtersForSessions, generateFiltersForUnread(sub));
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForSessions,
        generateFiltersForDate(filterBy)
      );
    }
  }
}

export function calculateTotalNotificationsUnreadCount(notifications) {
  let totalUnreadLength = 0;

  for (const categoryKey in notifications) {
    const category = notifications[categoryKey];

    if (category.unreadLength !== undefined) {
      totalUnreadLength += category.unreadLength;
    }
  }

  return totalUnreadLength;
}

export const getTabValue = (tab) => {
  if (tab?.toLowerCase() === "i was mentioned") {
    return "iWasMentioned";
  } else if (tab?.toLowerCase() === "assigned to me") {
    return "assignedToMe";
  } else {
    return tab?.toLowerCase();
  }
};

export const getFilterByValue = (filterBy) => {
  if (filterBy.toLowerCase() === "this week") {
    return "thisWeek";
  } else {
    return filterBy?.toLowerCase();
  }
};

export const filterEmails = (emails) => {
  const filters = {
    important: {
      notifications:
        emails.filter((email) => email.priority === "important") || [],
    },
    critical: {
      notifications:
        emails.filter((email) => email.priority === "critical") || [],
    },
    info: {
      notifications: emails.filter((email) => email.priority === "info") || [],
    },
    unread: {
      notifications: emails.filter((email) => !email.seen) || [],
    },
    today: {
      notifications:
        emails.filter((email) =>
          moment(email.createdAt).isSame(moment(), "day")
        ) || [],
    },
    thisWeek: {
      lastEvaluatedKey: "",
      notifications:
        emails.filter((email) =>
          moment(email.createdAt).isBetween(
            moment().startOf("week"),
            moment().endOf("week"),
            null,
            "[]"
          )
        ) || [],
    },
  };
  return filters;
};

export function updateNotificationStatus(notifications, ids, status) {
  return notifications.map((notification) => {
    if (ids.includes(notification.id)) {
      return { ...notification, seen: status };
    }
    return notification;
  });
}

export function updateAllNotificationsStatus(notifications, status) {
  return notifications.map((notification) => ({
    ...notification,
    seen: status,
  }));
}
