import createPDF, { PREVIEW } from "../../../../../../integrations/createPDF";
import { compareIncluding } from "../../../../utils";
import { documentDefinition } from "./documentDefinition";

export const exportBillToPdf = (billData, base64) => {
  const pdfTitle = `Bill #${billData.billNumber}`;
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  createPDF({
    action: PREVIEW,
    docDefinition: documentDefinition(pdfTitle, companyLogo, billData),
  });
};
