import { message } from "antd";

// Called when a cell editing has started.
export const cellEditingStarted = ({ params, isLoading, isWritable }) => {
  console.log({ params, isLoading, isWritable });
  // if the row is charged, we cant edit that row
  if (!!params?.data?.charged) {
    params.api.stopEditing(true);
    message.error("Already charged you cant make changes!!");
  }
  //if the write mode is off, this stops the editing in the agGrid
  if (!isWritable) {
    params.api.stopEditing(true);
    message.error("Please enable write mode");
  }
  //if loading is true automatically stops the editing in the agGrid
  if (isLoading) {
    params.api.stopEditing(true);
    message.error("Please wait until it finishes updating");
  }
};

//this function discard all the changes that are made in the modal
export const handleDiscard = ({
  setUnchangingRentalContext,
  setIsDetailsModalVisible,
  fakeRentals,
}) => {
  //sets the modal's rowData to the main rowData
  setUnchangingRentalContext(fakeRentals);
  //this closes the modal
  setIsDetailsModalVisible(false);
};

//this function modify fake rentals with the changes that we made on modal
export const handleOk = ({
  setIsDetailsModalVisible,
  setRentalContext,
  unchangingFakeRentals,
}) => {
  //this closes the modal
  setIsDetailsModalVisible(false);
  //sets the main rowData to the modals rowData
  setRentalContext(unchangingFakeRentals);
};

//this function is used to calculate the amount that is applied in the pli, in the days of that pli and the difference between those 2 values
export const appliedAmountCalculation = ({
  unchangingFakeRentals,
  selectedService,
  elevationData,
  allRow,
}) => {
  //this is the pli object in which details modal is in
  const pliData = unchangingFakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => allRow?.id === id);

  //here we extract the amount that is applied from that object that we extracted before
  const appliedToPli = pliData?.appliedAmount;

  //here we calculate how much amount we have applied in all days of that pli that we extracted before
  const appliedToDays = +pliData?.rentalDetails?.details
    ?.map(({ days }) => days?.map(({ dailyAmount }) => dailyAmount))
    ?.flat(2)
    ?.reduce((acc, curr) => acc + curr, 0)
    ?.toFixed(2);

  // here we calculate the diff between the amount that is in pli and the amount that we have disturbed in days modal
  const difference = +(appliedToPli - appliedToDays)?.toFixed(2);

  return {
    appliedToDays,
    appliedToPli,
    difference,
  };
};
