import { compareIncludingAccurate } from "../../../SidebarPages/utils";

export const generateParams = ({ title, base64 }) => {
  return {
    PDF_SELECTED_ROWS_ONLY: false,
    PDF_DOCUMENT_TITLE: title,
    PDF_LOGO: (base64 || [])?.find(({ fileName }) =>
      compareIncludingAccurate(fileName, "Core Logo Black")
    )?.base64,
  };
};
