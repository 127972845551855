import { useState } from "react";
import { CloseIconModal, TakeOffDescIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { SaveIcon } from "../../../../BasePage/src";
import RichText from "../Header/EstimationNoteModal/RichText";
import { Modal, message, Alert, Badge } from "antd";
import { useTakeOffContext } from "../../context";
import { useRedux } from "../../../../hooks";
import _ from "lodash";
import htmlParser from "../../../../../../utils/htmlParser";
import hasContent from "../../functional/hasContent";
function ElevationDescription(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [isWritable] = useRedux("estimationsIsWritable");
  const { agGridTheme } = useTakeOffContext();

  const getElevationDesc = () => {
    const copyGridData = _.cloneDeep(gridData);
    const myDesc =
      copyGridData[props.serviceIndex].serviceOptions[props.optionIndex][
        props.elevationIndex
      ];
    // check if object has key
    if (myDesc.hasOwnProperty("elevationDescription")) {
      setDescription(myDesc.elevationDescription);
    }
    console.log("myDesc", myDesc);
  };

  const modalSwitcher = (openModal) => {
    if (openModal) {
      getElevationDesc();
      setModalOpen(true);
    } else setModalOpen(false);
  };

  const onChangeHandler = (changes) => {
    setDescription(changes);
  };

  const onSubmitHandler = () => {
    const copyGridData = _.cloneDeep(gridData);
    copyGridData[props.serviceIndex].serviceOptions[props.optionIndex][
      props.elevationIndex
    ].elevationDescription = description;
    setTakeOffTableData(copyGridData);
    modalSwitcher(false);
    // props.updateStateAndSave(copyGridData);
    // props.api.refreshCells({ force: true });
  };

  console.log("my description", htmlParser(description));

  const onCleanUp = () => {
    modalSwitcher(false);
  };

  const hasElevationContent = () => {
    const copyGridData = _.cloneDeep(gridData);
    const myDesc =
      copyGridData[props.serviceIndex].serviceOptions[props.optionIndex][
        props.elevationIndex
      ];
    // check if object has key
    if (myDesc.hasOwnProperty("elevationDescription")) {
      return hasContent(myDesc.elevationDescription);
    }
    return false;
  };

  return (
    <>
      {" "}
      <Badge count={hasElevationContent() ? 1 : 0}>
        <button className="iconHolder" onClick={() => modalSwitcher(true)}>
          <TakeOffDescIcon />
        </button>{" "}
      </Badge>
      {isModalOpen && (
        <Modal
          title={`Elevation Description for: - ${props?.elevation?.elevationId} - ${props?.elevation?.elevationLabel}`}
          className={`est-description-modal__${agGridTheme}`}
          open={isModalOpen}
          width={550}
          onOk={onSubmitHandler}
          onCancel={onCleanUp}
          // wrapClassName={modalClassName}
          footer={
            <div className="est-description-modal__footer">
              <MondayButton
                Icon={<CloseIconModal />}
                onClick={onCleanUp}
                className="mondayButtonRed"
              >
                Cancel
              </MondayButton>{" "}
              <MondayButton
                Icon={<SaveIcon />}
                onClick={onSubmitHandler}
                className="mondayButtonGreen"
                disabled={!isWritable}
              >
                Save
              </MondayButton>
            </div>
          }
        >
          {!isWritable && (
            <Alert
              message="You do not have clearance to edit this."
              type="warning"
              showIcon
            />
          )}

          <RichText
            {...{
              readOnly: !isWritable,
              defaultValue: description || "",
              // value: note,
              wrapperClassName: "est-description-rich-text",
              placeholder: "You can add description for Elevation here...",
              setValue: onChangeHandler,
              disableTitleInput: () => {},
              enableTitleInput: () => {},
            }}
          />
        </Modal>
      )}
    </>
  );
}

export default ElevationDescription;
