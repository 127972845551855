import { Checkbox, Tooltip } from "antd";

import "./NewCheckBox.scss";

const NewCheckBox = ({
  checked,
  tooltip,
  className = "",
  style,
  onClick,
  disabled = false,
  indeterminate = false,
  children,
}) => {
  return (
    <div className={`new-checkbox-item ${className}`} style={style || {}}>
      <Tooltip
        title={tooltip && !disabled ? "" : tooltip}
        data-testid="tooltip-charge-checkbox"
      >
        <Checkbox
          data-testid="new-checkbox"
          indeterminate={indeterminate}
          disabled={disabled}
          checked={checked}
          className={disabled ? "ant-checkbox-disabled" : ""}
          onClick={onClick}
        >
          {children}
        </Checkbox>
      </Tooltip>
    </div>
  );
};

export default NewCheckBox;
