import {
  axesCount,
  axesTooltipWithCurrency as axesTooltip,
  pieCount,
  pieTooltipWithCurrency as pieTooltip,
} from "../../../../../../helpers/axesTooltip";
import { formatCurrency } from "../../../../utils";

//holds custom tooltip rendering functions

const relationsOnCharge = {
  title: {
    text: "Total Amount Relations",
  },
  subtitle: {
    text: "Based on Charge No",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "chargeNumber",
        yKey: ["chargeAmount", "appliedAmount", "remainingAmount"],
        yName: ["Charge Amount", "Applied Amount", "Remaining Amount"],
        tooltip: {
          renderer: (params) => {
            return {
              title: `Charge: ${params.datum[params.xKey]}`,
              content: `${params.yName}: ${formatCurrency(
                params.datum[params.yKey]
              )}`,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
      {
        type: "category",
        label: {
          formatter: (params) => `Charge: ${params?.value}`,
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "chargeNumber",
        angleKey: ["chargeAmount"],
        angleName: "Charge Amount",
        tooltip: {
          renderer: (params) => {
            let tmp = params["calloutLabelKey"];
            return {
              title: `Charge: ${params.datum[tmp]}`,
              content: `${params.angleName}: ${formatCurrency(
                params.datum[params.angleName.toLowerCase()]
              )}`,
            };
          },
        },
      },
    ],
  },

  area: {
    series: [
      {
        xKey: "chargeNumber",
        yKey: ["chargeAmount"],
        yName: ["Charge Amount"],
        grouped: false,
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
      {
        type: "category",
        label: {
          formatter: (params) => `Charge: ${params?.value}`,
        },
      },
    ],
  },
};

const relationsOnCategory = {
  title: {
    text: "Total Amount Relations",
  },
  subtitle: {
    text: "Based on Category",
  },
  aggFunc: "sum",

  bar: {
    series: [
      {
        xKey: "categoryFrom",
        yKey: ["chargeAmount", "appliedAmount", "remainingAmount"],
        yName: ["Charge Amount", "Applied Amount", "Remaining Amount"],
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
    ],
  },

  pie: {
    series: [
      {
        calloutLabelKey: "categoryFrom",
        angleKey: ["chargeAmount"],
        angleName: "Charge Amount",
        tooltip: {
          renderer: (params) => pieTooltip(params),
        },
      },
    ],
  },

  area: {
    series: [
      {
        xKey: "categoryFrom",
        yKey: ["chargeAmount"],
        yName: ["Charge Amount"],
        grouped: false,
        tooltip: {
          renderer: (params) => axesTooltip(params),
        },
      },
    ],
    axes: [
      {
        type: "number",
        label: {
          formatter: (params) => formatCurrency(params?.value),
        },
      },
      {
        type: "category",
        label: {
          formatter: (params) => `Charge: ${params?.value}`,
        },
      },
    ],
  },
};

const countOnDate = {
  title: {
    text: "Total Number of Charges",
  },
  subtitle: {
    text: "Based on Creation Date",
  },
  aggFunc: "count",
  sort: "date",
  bar: {
    series: [
      {
        xKey: "dynamicTime",
        yKey: ["taskTitle"],
        yName: ["Count"],
        tooltip: {
          renderer: (params) => axesCount(params),
        },
      },
    ],
  },
  pie: {
    series: [
      {
        calloutLabelKey: "dynamicTime",
        angleKey: ["taskTitle"],
        angleName: "Count",
        tooltip: {
          renderer: (params) => pieCount(params),
        },
      },
    ],
  },
};

const chartingOptions = [
  {
    optionTitle: "Show the amount relations based on charge number",
    params: relationsOnCharge,
  },
  {
    optionTitle: "Show the amount relations based on category",
    params: relationsOnCategory,
  },
  {
    optionTitle: "Show total charges count based on creation date",
    params: countOnDate,
  },
  {
    dynamicKeys: [
      {
        key: "dynamicTime",
        keyFunction: "dateGetter",
        optionalParams: [["chargeNumber"]],
      },
    ],
  },
];

export default chartingOptions;
