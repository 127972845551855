import React from "react";
import { Tooltip } from "antd";
import { useState } from "react";
import { HistoryOutlined } from "@ant-design/icons";

import {
  fetchAllData,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../utils";
import Warning from "../Warning/Warning";
import { getLogsFilters } from "./filters";
import MondayButton from "../../../MondayButton/MondayButton";
import { RightArrow } from "../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { AddIcon } from "../../../../Header/components/GoogleEvents/assets";
import { useUploadRecordsContext } from "../../context/UploadRecordsContext";
import MultiLevelTreeLogs from "../../../MultiLevelTreeLogs/MultiLevelTreeLogs";

const TOOLTIP_MESSAGE = "Resolve conflicts to continue";

const Footer = ({ currentStep, setCurrentStep, onCancel, rowData }) => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isLogVisible, setIsLogVisible] = useState(false);
  const [logsData, setLogsData] = useState([]);

  const { title, onSaveRecords, recordsConflicts, uploadedRecords } =
    useUploadRecordsContext();

  const isSubmitDisabled = !!recordsConflicts?.newRecords?.length;

  const isNextStepDisabled = !uploadedRecords.length;

  const onSave = async () => {
    setIsWarningOpen(false);
    await onSaveRecords();
  };

  const getLogs = async () => {
    showLoadingMsg({ content: `Loading ${title} logs. Please wait...` });
    const logsFilters = getLogsFilters(title);
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: { filters: JSON.stringify(logsFilters) },
    })
      .then((result) => {
        setLogsData(result);
        setIsLogVisible(true);
        showSuccessMsg({ content: "Logs loaded successfully." });
      })
      .catch((error) => showErrorMsg({ content: "Error loading logs" }));
  };

  return (
    <div className="upload-modal-footer">
      <MondayButton
        className="mondayButtonRed"
        Icon={<XIcon />}
        onClick={onCancel}
      >
        Cancel
      </MondayButton>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<HistoryOutlined />}
        onClick={getLogs}
      >
        Edit Logs
      </MondayButton>
      {currentStep === 0 ? (
        <MondayButton
          disabled={isNextStepDisabled}
          onClick={() => setCurrentStep(1)}
          className="mondayButtonBlue"
          Icon={<RightArrow />}
        >
          Next Step
        </MondayButton>
      ) : (
        <Tooltip title={isSubmitDisabled && TOOLTIP_MESSAGE}>
          <MondayButton
            disabled={isSubmitDisabled}
            onClick={() => setIsWarningOpen(true)}
            Icon={<AddIcon />}
          >
            Add Records
          </MondayButton>
        </Tooltip>
      )}

      {isWarningOpen && (
        <Warning
          isOpen={isWarningOpen}
          setIsOpen={setIsWarningOpen}
          onSubmit={onSave}
        />
      )}

      {isLogVisible && (
        <MultiLevelTreeLogs
          {...{
            title: `Uploaded ${title} Logs`,
            visible: isLogVisible,
            setVisible: setIsLogVisible,
            logsData,
          }}
        />
      )}
    </div>
  );
};

export default Footer;
