import { forceToNumber } from "../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";

export const totalPriceOfSOVHandler = ({ fakeSOV }) =>
  fakeSOV?.services?.reduce((acc, cur) => {
    if (!checkIfServiceIsHoist(cur) && cur?.amounts?.length > 0) {
      return acc + cur?.totalPrice;
    }
    if (checkIfServiceIsHoist(cur)) {
      const hoistPrice = cur?.serviceOptions?.[0]
        ?.filter((option) => option?.amounts?.length > 0)
        ?.reduce((a, c) => a + c?.totalPrice, 0);
      return acc + hoistPrice;
    } else return acc;
  }, 0);

export const retractChangerHandler = ({ dispatchContext, retracted }) => {
  dispatchContext({ type: "SET_RETRACTED", payload: !retracted });
};
export const customBalancePriceOfSOVHandler = ({ fakeSOV }) => {
  let balanceAmounts = {
    balanceAmount: 0,
    legacyPrice: 0,
    hasChanged: false,
    currPrice: 0,
  };
  // let temp = 0;

  fakeSOV?.services?.reduce((acc, curr) => {
    if (!checkIfServiceIsHoist(curr)) {
      acc.balanceAmount += forceToNumber(curr?.balance);
      acc.legacyPrice += curr?.hasOwnProperty("legacyPrice")
        ? forceToNumber(curr?.legacyPrice)
        : forceToNumber(curr?.totalPrice);

      return acc;
    } else {
      curr?.serviceOptions?.[0]?.forEach((option) => {
        acc.balanceAmount += forceToNumber(option?.balance);
        acc.legacyPrice += option.hasOwnProperty("legacyPrice")
          ? forceToNumber(option.legacyPrice)
          : forceToNumber(option.totalPrice);
      });
      return acc;
    }
  }, balanceAmounts);

  if (balanceAmounts?.balanceAmount !== 0) {
    balanceAmounts.balanceAmount = forceToNumber(
      balanceAmounts.balanceAmount.toFixed(2)
    );
    if (
      (balanceAmounts.balanceAmount < 0 &&
        balanceAmounts.balanceAmount > -0.01) ||
      (balanceAmounts.balanceAmount > 0 &&
        balanceAmounts.balanceAmount < 0.01) ||
      +balanceAmounts.balanceAmount === 0
    ) {
      balanceAmounts.balanceAmount = 0;
    }
  }

  return balanceAmounts;
};
