import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useLocalStorage } from "../../../../utils";
import { SESSION_KEY } from "../data/constants";
import { useSelector } from "react-redux";
import { useLoading } from "../../../../hooks";
import axios from "axios";
import { trimDataIfLargerThan5mb } from "../utils/trimDataIfLargerThan5mb";

const BASIC_API_URL = "https://leadmanager-sockets.ue.r.appspot.com/api";
// "http://localhost:8080/api";

export const useOpenAiAssistant = ({ setStatus }) => {
  const [threadId, setThreadId] = useLocalStorage("chatThreadId", null);
  const [isError, setIsError] = useState(false);
  const [chatSession] = useLocalStorage(SESSION_KEY);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [messages, setMessages] = useState(
    chatSession?.messages ? chatSession.messages : []
  );

  const { isLoading, startLoading, stopLoading } = useLoading();

  const createThread = async () => {
    if (!threadId)
      try {
        setStatus("BotConnecting");
        const apiUrl = `${BASIC_API_URL}/thread`;
        const { data: response } = await axios.get(apiUrl);
        setThreadId(response.data.threadId);
        setStatus("Online");
      } catch (error) {
        setIsError(true);
        console.error("Error creating thread:", error);
        setStatus("Error");
      }
  };

  useEffect(() => {
    createThread().then(() => {
      setStatus("Online");
    });
  }, []);

  const sendMessage = async (message, retry = false) => {
    if (!message.trim() || !threadId) return;
    startLoading();

    const userMessage = {
      messageId: uuidv4(),
      attachments: [],
      botMessage: false,
      createdAt: Date.now(),
      messageRetried: false,
      messageStatus: "sent",
      parentFolderId: "",
      roomId: threadId,
      roomName: "",
      senderId: userConfiguration.identityId,
      senderName: userConfiguration.nameOfUser,
      text: message,
    };

    const botMessage = {
      messageId: uuidv4(),
      attachments: [],
      botMessage: false,
      createdAt: Date.now(),
      messageRetried: false,
      messageStatus: "typing",
      parentFolderId: "",
      roomId: threadId,
      roomName: "",
      senderId: "chatbot",
      senderName: "Core Scaffold Systems Inc.",
      text: "",
    };

    if (!retry) {
      setMessages((prevMessages) => [...prevMessages, userMessage, botMessage]);
    }

    try {
      const apiUrl = `${BASIC_API_URL}/message`;

      const requestBody = {
        message,
        threadId,
        userType: "core",
      };

      const { data: response } = await axios.post(apiUrl, requestBody);

      const botResponse = response.data.messages[0][0].text.value;

      setMessages((prevMessages) =>
        prevMessages.map((oldMessage, index) =>
          index === prevMessages.length - 1
            ? {
                ...oldMessage,
                text: botResponse || "",
                messageStatus: "completed",
              }
            : oldMessage
        )
      );

      // updateVirtualAssistantFields("1", [
      //   ...prompts,
      //   {
      //     id: uuidv4(),
      //     role: "user",
      //     content: message,
      //   },
      // ]);

      const trimmedMessages = trimDataIfLargerThan5mb([
        ...messages,
        userMessage,
        { ...botMessage, text: botResponse || "", messageStatus: "completed" },
      ]);

      return trimmedMessages;
    } catch (error) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.slice(0, -1);

        if (updatedMessages.length > 0) {
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            messageStatus: "error",
          };
        }

        return updatedMessages;
      });
    } finally {
      stopLoading();
    }
  };

  const retryMessage = async (messageId) => {
    const messageToRetry = messages.find(
      (message) => message.messageId === messageId
    );

    if (messageToRetry && threadId) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((message) => {
          if (message.messageId === messageId) {
            return {
              ...message,
              messageStatus: "sent",
              messageRetried: true,
            };
          }
          return message;
        });

        return [
          ...updatedMessages,
          {
            messageId: uuidv4(),
            attachments: [],
            botMessage: false,
            createdAt: Date.now(),
            messageRetried: false,
            messageStatus: "typing",
            parentFolderId: "",
            roomId: threadId,
            roomName: "",
            senderId: "chatbot",
            senderName: "Core Scaffold Systems Inc.",
            text: "",
          },
        ];
      });

      await sendMessage(messageToRetry.text, true);
    }
  };

  const closeSession = () => {
    setThreadId(null);
    localStorage.removeItem("chatThreadId");
    localStorage.removeItem("chatSession");
    setMessages([]);
    setIsError(false);
  };

  return {
    messages,
    setMessages,
    sendMessage,
    isLoading,
    isError,
    createThread,
    retryMessage,
    closeSession,
  };
};
