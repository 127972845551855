import { Modal, Tooltip, Tour, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useUploadedFiles } from "../../../../hooks";
import {
  SPECIAL_REQUESTS,
  cancelRequestFilesHandler,
  disableRequestFields,
  requestTourSteps,
} from "./helpers";
import { useRequestModalContext } from "./store";
import CustomModalHeader from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { updateDocumentTitle } from "../../../../utils";
import RequestFormSection from "./components/RequestFormSection/RequestFormSection";
import { LoadableComp } from "../../../SidebarPages/XComponents";
import { useMediaQuery } from "react-responsive";
import { Stepper } from "../../../commonComponents";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import RequestFooter from "./components/RequestFooter/RequestFooter";
import { SettingIcon } from "../../subcomponents";

import "./RequestModal.scss";

/**
 * NewRequestModal component represents a modal for creating a new request.
 *
 * @component
 * @param {boolean} [visible=false] - Determines whether the modal is visible or not.
 * @param {boolean} [globalDisabled=false] - Determines whether all fields in the modal are disabled or not.
 * @returns {JSX.Element} The NewRequestModal component.
 */
const NewRequestModal = ({ visible = false, globalDisabled = false }) => {
  const {
    requestData,
    setVisible = () => {},
    originalRequestForm = null,
    requestType = "",
    statuses,
    from = "",
  } = useRequestModalContext();

  const [uploadHandlers, deleteHandlers] = useUploadedFiles(
    originalRequestForm?.requestObject?.gDriveUploads || []
  );
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;

  const [
    { programFields = [] },
    { accessToken },
    { userConfiguration },
    { isDarkMode },
  ] = useSelector((state) => [
    state.programFields,
    state.accessToken,
    state.userConfig,
    state.darkMode,
  ]);

  const [form] = useForm();

  const { setFieldsValue /*getFieldsValue, resetFields, getFieldValue*/ } =
    form;

  const [currentStep, setCurrentStep] = useState(
    statuses.findIndex(
      (el) => el?.statusName === originalRequestForm?.requestObject?.status
    ) || 0
  );
  const [changes, setChanges] = useState(false);

  const [tourOpen, setTourOpen] = useState(false);

  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const allFieldsDisabled = useMemo(() => {
    return disableRequestFields({
      userConfiguration,
      requestForm: originalRequestForm,
      selectedTeam: requestData?.selectedTeam,
      from,
    });
  }, [userConfiguration, originalRequestForm, requestData?.selectedTeam]);

  const openTour = () => {
    requestTourSteps({
      programFields,
      requestType,
      originalRequestForm,
    })?.length
      ? setTourOpen(true)
      : message.info("No guide available for this form");
  };

  const onCancel = async () => {
    cancelRequestFilesHandler({
      requestId: originalRequestForm?.requestId,
      accessToken,
      uploadedFiles,
      gDriveUploads: originalRequestForm?.gDriveUploads || [],
      filesToBeDeleted,
    });

    setVisible(false);
  };

  return (
    <Fragment>
      <LoadableComp
        loading={!Object.values(requestData?.fetchedData || {})?.length}
      >
        <Modal
          {...{
            closable: true,
            open: visible,
            onCancel,
            title: (
              <CustomModalHeader
                {...{
                  title: `${requestType} Request Form`,
                  onClick: openTour,
                  headerInfo: () =>
                    (userConfiguration?.groupName === "Admin" ||
                      userConfiguration?.groupName === "Super Admin") && (
                      <Tooltip title="Add New Fields">
                        <SettingIcon
                          className="settingsIcon"
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/settings/approvalsDynamicForms`
                            )
                          }
                        />
                      </Tooltip>
                    ),
                }}
              />
            ),
            afterOpenChange: (event) => {
              event &&
                updateDocumentTitle({
                  newTitle: `${requestType} Request Form`,
                });
            },
            footer: null,
            className: `requestModal ${
              !originalRequestForm ? "newRequestFormModal" : ""
            } ${
              !SPECIAL_REQUESTS.includes(requestType)
                ? "schedulingRequestFormModal"
                : ""
            } ${isDarkMode && "requestModalDark"}`,
            closeIcon: <XIcon />,
            centered: true,
          }}
        >
          {!isMobileView ? (
            <div className="stepper-section">
              <Stepper
                {...{
                  currentStep,
                  setCurrentStep(e) {
                    setFieldsValue({ stepper: e });
                    setCurrentStep(e);
                    setChanges(true);
                  },
                  stepperClassName: "formStepper",
                  steps: (statuses || [])?.map(({ statusName }) => ({
                    title: statusName,
                    disabled: globalDisabled || allFieldsDisabled,
                  })),
                  stepRenderer: false,
                  statusesColors: statuses,
                }}
              />
            </div>
          ) : (
            <div className="stepper-section-mobile">
              <InputComponent
                {...{
                  label: "Status",
                  type: "select",
                  dropdownClassName: isDarkMode && "darkDropDown",
                  formItemName: "stepper",
                  customOptions: (statuses || [])?.map(
                    ({ statusName, statusId }, index) => ({
                      value: statusId,
                      label: statusName,
                      index,
                    })
                  ),
                  onSelect: (_, option) => setCurrentStep(option.index),
                  defaultValue: currentStep,
                  disabled: globalDisabled || allFieldsDisabled,
                  form,
                }}
              />
            </div>
          )}

          <RequestFormSection
            {...{
              form,
              allFieldsDisabled,
              uploadedFiles,
              onPickerSuccess,
              onDelete,
              setChanges,
            }}
          />

          <RequestFooter
            {...{
              form,
              changes,
              onCancel,
              currentStep,
              uploadedFiles,
              formattedFiles,
              filesToBeDeleted,
              allFieldsDisabled,
              userConfiguration,
            }}
          />
        </Modal>
      </LoadableComp>

      {!!tourOpen && (
        <Tour
          {...{
            open: tourOpen,
            onClose: () => setTourOpen(false),
            steps: requestTourSteps({
              programFields,
              requestType,
              originalRequestForm,
            }),
            mask: { color: "#2a2b3a71" },
          }}
        />
      )}
    </Fragment>
  );
};

export default NewRequestModal;
