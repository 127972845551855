const fieldTableDefaultColDefs = {
  resizable: true,
  enablePivot: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  flex: 1,
  filter: true,
  suppressSizeToFit: true,
};
export default fieldTableDefaultColDefs;
