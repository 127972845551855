/**Antd Form Validator
 *
 * @param {Object} form The form instance.
 * @param {Function} [onSuccessFunc] Callback function.
 * @param {Function} [onErrorFunc] Callback function on error.
 * @param {Array} [fieldNames] Form.Item names of the inputs you want to validate.
 */
export const validateForm = async (
  form,
  onSuccessFunc = () => {},
  onErrorFunc = () => {},
  fieldNames,
  validationType = ""
) => {
  form
    .validateFields(fieldNames?.length ? fieldNames : undefined)
    .then(async () => await onSuccessFunc())
    .catch((e) => {
      console.log("validateForm error", e);

      if (validationType === "all") {
        let skip = false;
        e?.errorFields?.forEach((err) => {
          if (err.name[0].includes("arrivingTime#1")) {
            skip = true;
          }
        });
        if (skip) return onSuccessFunc();
      } else onErrorFunc();
      try {
        const el = document.getElementById(e?.errorFields?.[0]?.name?.[0]);
        if (el) {
          el.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      } catch (err) {
        console.error(err);
      }
    });
};
