import uniq from "lodash/uniq";

export const incidents = ({ tables, programFields }) => {
  const incidentStatuses = programFields?.find(
    (a) => a?.fieldName === "Statuses Of Categories"
  )?.fieldOptions?.Incidents;

  return {
    driverName: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.fleetName,
          value: a?.driverName,
          color: "#ECDE7D",
        };
      }),
    }),

    fleetName: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.driverName,
          value: a?.fleetName,
          color: "#ECDE7D",
        };
      }),
    }),

    incidentStatus: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.incidentName,
          value: a?.incidentStatus,
          color: incidentStatuses?.find(
            (e) => e.statusName === a?.incidentStatus
          )?.statusColor,
          date: a?.createdAt,
          id: a?.incidentId,
        };
      }),
    }),

    incidentCategory: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.incidentName,
          value: a?.incidentCategory,
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.incidentId,
        };
      }),
    }),

    incidentName: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.incidentName,
          value: a?.incidentStatus,
          color: incidentStatuses?.find(
            (e) => e.statusName === a?.incidentStatus
          )?.statusColor,
          date: a?.createdAt,
          id: a?.incidentId,
        };
      }),
    }),

    projectName: () => ({
      statusData: tables?.Incidents?.map((a) => {
        return {
          label: a?.incidentObject?.projectName,
          value: a?.incidentName,
          color: "#ECDE7D",
          date: a?.createdAt,
          id: a?.incidentId,
        };
      }),
    }),
  };
};
