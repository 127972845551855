import {
  assignedToObject,
  projectManagerSelect,
  defaultSafetyCategories,
  teamSelect,
  selectSafetyCategory,
} from "..";

import sortFields from "../sortFields";

/**
 * Generates an array of new fields based on the input fields and other parameters.
 *
 * @param {Array} teams - The array of teams.
 * @param {Array} safety - The array of safety options.
 * @param {Array} inputFields - The array of input fields.
 * @param {Array} safetyOptions - The array of safety options.
 * @param {boolean} isDarkMode - Indicates whether dark mode is enabled.
 * @param {Array} projectManagers - The array of project managers.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {boolean} globalDisabled - Indicates whether all fields are globally disabled.
 * @param {Function} onTeamSelect - The function to handle team selection.
 * @param {boolean} allFieldsDisabled - Indicates whether all fields are disabled.
 * @param {Function} setFieldsValue - The function to set field values.
 * @param {Function} onMainFieldSelect - The function to handle main field selection.
 * @param {Function} dispatchRequestData - The function to dispatch request data.
 * @returns {Array} The sorted array of new fields.
 */
const claimRequestFields = ({
  teams = [],
  safety = [],
  inputFields = [],
  safetyOptions = [],
  isDarkMode = false,
  projectManagers = [],
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  setFieldsValue = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const newFields = [];

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    const { type = "", formItemName = "" } = field;
    // delete field?.defaultField;
    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "claimCategory") {
      Object.assign(field, {
        options: defaultSafetyCategories || field?.options,
        style: { minWidth: 170, width: 200 },
        onSelect: (category) => {
          selectSafetyCategory({
            safety,
            category,
            setFieldsValue,
            dispatchRequestData,
          });
        },
        showSearch: true,
        allowClear: true,
        onClear: () =>
          selectSafetyCategory({
            clear: true,
            setFieldsValue,
            dispatchRequestData,
          }),
      });
      // delete field?.options;
    }

    if (formItemName === "safetyReport") {
      Object.assign(field, {
        customOptions: (!!safetyOptions?.length
          ? safetyOptions
          : field?.options || []
        )?.map((el, key) => ({
          key,
          label: el?.safetyName || el,
          value: el?.safetyApplicationId || el,
        })),

        style: { minWidth: 170, width: 200 },

        onSelect: (val, opt) =>
          onMainFieldSelect({
            val,
            opt,
            setFieldsValue,
          }),

        onClear: () => onMainFieldSelect({ clear: true, setFieldsValue }),

        showSearch: true,
        allowClear: true,
      });

      delete field?.options;
    }

    if (formItemName === "assignedTo") {
      delete field?.options;

      Object.assign(field, {
        ...assignedToObject({
          title: "Claims",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((e) => e?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ onTeamSelect, teams, isDarkMode }),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default claimRequestFields;
