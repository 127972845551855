import { useEffect, useState } from "react";
import { Folders, UploadObject } from "./helpers";
import { scheduleDay } from "../../../../../SidebarPages/Scheduling/ScheduleChangesModal/Helpers/Types";
import { Badge, Button } from "antd";
import { FolderFileIcon, InfoIcon } from "../../../../../../assets";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import dayjs from "dayjs";
import moment from "moment";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils";
import { createFolder, useGooglePicker } from "./utils/utils";
import CarouselImages from "./CarouselImages";
import FolderView from "./FolderView";
import { removeDuplicates } from "../../../../../SidebarPages/Projects/Tabs/ScheduleView/AgGridData/utils/functions";

export interface Props {
  serviceFolders: Folders;
  serviceScheduleDays: scheduleDay[] | [];
  updateGoogleDriveFolders: (folders: Folders, label: string) => void;
  label: string;
  setAbleToSave?: (ableToSave: boolean) => void;
  disabled: boolean;
}

interface State {
  accessToken: {
    accessToken: string;
  };
  [key: string]: any;
}

export function ServiceDocumentationView(props: Props) {
  const {
    serviceFolders,
    serviceScheduleDays,
    updateGoogleDriveFolders,
    label,
    setAbleToSave,
    disabled,
  } = props;

  console.log("serviceScheduleDays ", { serviceScheduleDays });

  const { accessToken } = useSelector((state: State) => state.accessToken);
  //* Set first date as default selected folder
  const [selectedFolder, setSelectedFolder] = useState<string>(
    dayjsNY(serviceScheduleDays?.[0]?.endDate).format("MM/DD/YYYY")
  );

  //* Set the folders for the service according to the day
  const [dayDriveFolders, setDayDriveFolders] = useState({});

  const driveRequest = driveApi({ accessToken });

  //* This is a custom hook that opens the google picker and returns the uploaded files
  const { openGooglePicker } = useGooglePicker();

  const gridSpanClassName =
    serviceScheduleDays?.length > 3 ? "gridSpan1" : "gridSpan3";

  //* This functions saves the files that are inside a service in the state depending in the action
  function setDriveFilesByDay(
    docs: UploadObject[],
    selectedFolder: string,
    action: "add" | "new"
  ): void {
    const filteredDocs =
      docs?.filter(({ mimeType = "" }) => mimeType.includes("image")) || [];
    if (action === "add") {
      const prevDayDriveFolders = dayDriveFolders?.[selectedFolder] || [];

      setDayDriveFolders((prev) => ({
        ...prev,
        [selectedFolder]: removeDuplicates(
          prevDayDriveFolders.concat(filteredDocs),
          "id"
        ),
      }));
    } else if (action === "new") {
      setDayDriveFolders((prev) => ({
        ...prev,
        [selectedFolder]: filteredDocs,
      }));
    }
  }

  //* Delete a file from the state and from the drive
  function deleteFile(fileId: string, selectedFolder: string): void {
    driveRequest.deleteDriveItem(fileId).then(() => {
      showSuccessMsg({
        content: "File deleted successfully",
      });

      // setAbleToSave?.(true);

      setDayDriveFolders({
        ...dayDriveFolders,
        [selectedFolder]: dayDriveFolders?.[selectedFolder]?.filter(
          ({ id = "" }) => id !== fileId
        ),
      });
    });
  }

  //* Get the image thumbnail from drive for every image
  const uploadedFileWithThumbnail = async (
    docs: UploadObject[],
    formattedDate: string
  ) => {
    Promise.all(
      docs?.map?.(({ id }) =>
        driveRequest.getFileThumbnail(id).then(async (res) => await res.json())
      )
    ).then((res) => {
      const newArray = docs?.map((file, index) => ({
        ...file,
        thumbnailLink: res?.[index]?.thumbnailLink,
      }));
      setDriveFilesByDay(newArray, formattedDate, "add");
    });
  };

  const createDriveFolders = async (formattedDate: string) => {
    setAbleToSave?.(true);
    //* If the folder doesn't exist, create it and then save it to state
    if (!serviceFolders?.[formattedDate]) {
      createFolder(
        formattedDate,
        serviceFolders,
        driveRequest.getFolderIdOrCreate
      ).then((folders) => {
        setAbleToSave?.(true);
        updateGoogleDriveFolders(
          {
            ...serviceFolders,
            [formattedDate]: folders,
          },
          label
        );
        openGooglePicker({
          parentId: folders,
        }).then(async (docs) => {
          // setDriveFilesByDay(docs, formattedDate);
          uploadedFileWithThumbnail(docs, formattedDate);
        });
      });
    } else {
      openGooglePicker({
        parentId: serviceFolders?.[formattedDate],
      }).then((docs) => {
        uploadedFileWithThumbnail(docs, formattedDate);

        // setDriveFilesByDay(docs, formattedDate);
      });
    }
  };

  useEffect(() => {
    setSelectedFolder(
      dayjsNY(serviceScheduleDays?.[0]?.endDate).format("MM/DD/YYYY")
    );
    if (!serviceScheduleDays?.length) {
      setDayDriveFolders({});
    }
  }, [serviceScheduleDays]);

  return (
    <div className="serviceDocumentationContainer">
      {!!disabled && (
        <span className="serviceDocumentationInfo">
          <InfoIcon />
          &nbsp; You cannot upload when write mode isn't enabled!
        </span>
      )}
      <div className="serviceDocumentationView">
        <div className={`folderSection ${gridSpanClassName}`}>
          {serviceScheduleDays.map(({ id = "", label = "", endDate, day }) => {
            const formattedDate = dayjs(endDate).format("MM/DD/YYYY");
            return (
              <FolderView
                {...{
                  selectedFolder,
                  formattedDate,
                  setSelectedFolder,
                  id,
                  day,
                  createDriveFolders,
                  serviceScheduleDays,
                  driveRequest,
                  serviceFolders,
                  dayDriveFolders: dayDriveFolders?.[formattedDate] || [],
                  setDriveFilesByDay,
                  disabled,
                }}
              />
            );
          })}
        </div>
        <CarouselImages
          {...{
            className: gridSpanClassName,
            selectedFolder,
            dayFolder: serviceFolders?.selectedFolder || {},
            dayDriveFolders: dayDriveFolders?.[selectedFolder] || [],
            driveRequest,
            deleteFile,
            disabled,
          }}
        />
      </div>
    </div>
  );
}
