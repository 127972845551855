import { debounce, uniqBy } from "lodash";
import {
  handlePopupScroll,
  searchFetchInput,
} from "../../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../../../SidebarPages/Dashboard/NewDashboardFunctions";
import { openInNewTab } from "../../../../../../utils";
import {
  REQUEST_API_KEYS,
  assignedToObject,
  binObject,
  projectManagerSelect,
  teamSelect,
} from "..";
import IndividualLoader from "../../../../../IndividualLoader";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for the estimation request form.
 *
 * @param {Array} BIN - The BIN array.
 * @param {Array} teams - The teams array.
 * @param {Array} projects - The projects array.
 * @param {Array} accounts - The accounts array.
 * @param {Array} inputFields - The input fields array.
 * @param {Array} opportunities - The opportunities array.
 * @param {boolean} isDarkMode - Indicates if the dark mode is enabled.
 * @param {Array} projectManagers - The project managers array.
 * @param {Object} currentPageKey - The current page key object.
 * @param {boolean} recordsLoading - Indicates if the records are loading.
 * @param {Array} projectExecutives - The project executives array.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {Function} onTeamSelect - The onTeamSelect function.
 * @param {Object} selectedMainField - The selected main field object.
 * @param {Function} setFieldValue - The setFieldValue function.
 * @param {Function} getFieldsValue - The getFieldsValue function.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} setRecordsLoading - The setRecordsLoading function.
 * @param {Function} onMainFieldSelect - The onMainFieldSelect function.
 * @param {Function} dispatchRequestData - The dispatchRequestData function.
 * @returns {Array} - The sorted array of fields for the estimation request form.
 */
const estimationRequestFields = ({
  BIN = [],
  teams = [],
  projects = [],
  accounts = [],
  inputFields = [],
  opportunities = [],
  isDarkMode = false,
  projectManagers = [],
  currentPageKey = null,
  recordsLoading = false,
  projectExecutives = [],
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  selectedMainField = null,
  setFieldValue = () => {},
  getFieldsValue = () => {},
  allFieldsDisabled = false,
  setRecordsLoading = () => {},
  onMainFieldSelect = () => {},
  dispatchRequestData = () => {},
}) => {
  const onSearch = debounce(async (searchValue) => {
    return await searchFetchInput({
      table: ["projects", "opportunities"],
      idKey: ["projectId", "opportunityId"],
      arrayKey: ["projects", "opportunities"],
      searchKey: ["projectName", "opportunityAddress"],
      keysToInclude: {
        projects: REQUEST_API_KEYS.Estimation.projects,
        opportunities: REQUEST_API_KEYS.Estimation.opportunities,
      },
      searchValue,
      setLoading: setRecordsLoading,
      setRecords: (res) => {
        const resToUse = res?.reduce(
          (acc, curr) => ({
            ...acc,
            ...curr?.value,
          }),
          {}
        );

        const payload = {
          projects: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(projects || []), ...(resToUse?.projects || [])],
              "projectId"
            )
          ),
          opportunities: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(opportunities || []), ...(resToUse?.opportunities || [])],
              "opportunityId"
            )
          ),
        };

        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload,
        });
      },
      userConfiguration,
      setCurrentPageKey: (val) => {
        currentPageKey.current = val;
      },
      customSetRecords: true,
    });
  }, 500);

  const onPopupScroll = async (e) =>
    await handlePopupScroll({
      e,
      table: ["projects", "opportunities"],
      idKey: ["projectId", "opportunityId"],
      currentPageKey: currentPageKey.current,
      setLoading: setRecordsLoading,
      setRecords: (res) => {
        const payload = {
          projects: userAccessRecords(
            userConfiguration,
            uniqBy([...(projects || []), ...res?.projects], "projectId")
          ),
          opportunities: userAccessRecords(
            userConfiguration,
            uniqBy(
              [...(opportunities || []), ...res?.opportunities],
              "opportunityId"
            )
          ),
        };

        dispatchRequestData({
          type: "SEARCH_FETCH",
          payload,
        });
      },
      userConfiguration,
      keysToInclude: REQUEST_API_KEYS.Estimation,
      setCurrentPageKey: (val) => {
        currentPageKey.current = {
          projects: val?.projects?.projectId,
          opportunities: val?.opportunities?.opportunityId,
        };
      },
      customSetRecords: true,
    });

  const dropdownRender = (menu) => (
    <>
      {menu}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        {recordsLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <IndividualLoader />
          </div>
        ) : null}
      </div>
    </>
  );

  const newFields = [];

  // const reducedAccounts = (accounts || [])?.reduce(
  //   (acc, curr) => ({ ...acc, [curr?.accountId]: curr }),
  //   {}
  // );

  for (let i = 0; i < inputFields.length; i++) {
    let field = structuredClone(inputFields[i]);

    // delete field?.defaultField;
    const { type = "", formItemName = "" } = field;

    field.className = "defaultInputComponent";

    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "jobsiteAddress") {
      delete field?.options;

      Object.assign(field, {
        customOptions: [
          {
            label: "Projects",
            options: (projects || [])?.map((el) => ({
              label: `${el?.projectName} ― Client : ${
                el?.accountName || "N/A"
              }`,
              value: el?.projectId,
              category: "projects",
              key: el?.projectId,
              recordName: el?.projectName,
            })),
          },
          {
            label: "Opportunities",
            options: (opportunities || [])
              ?.filter(
                (el) => !el?.isChangeOrder && el?.opportunityStage !== "Awarded"
              )
              ?.map((el) => ({
                label: `${el?.opportunityAddress} ― Client : ${
                  el?.accountName || "N/A"
                }`,
                value: el?.opportunityId,
                category: "opportunities",
                key: el?.opportunityId,
                recordName: el?.opportunityAddress,
              })),
          },
        ],

        style: { minWidth: 300 },
        showSearch: true,
        allowClear: true,

        onSelect: (val, opt) => onMainFieldSelect({ val, opt }),

        onClear: () => onMainFieldSelect({ val: null, opt: null, clear: true }),

        onSearch,

        onPopupScroll,

        dropdownRender,
      });
    }

    if (formItemName === "bin") {
      Object.assign(field, {
        ...binObject({
          BIN,
          setBIN: (payload) => {
            dispatchRequestData({
              type: "BIN",
              payload,
            });
          },
          selectedProject: selectedMainField,
          setFieldValue,
          allFieldsDisabled,
          onVerifyBinClick() {
            const { bin } = getFieldsValue();

            bin &&
              openInNewTab(
                `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${bin}`
              );
          },
        }),
      });
    }

    if (formItemName === "projectExecutive") {
      delete field?.options;

      Object.assign(field, {
        options: projectExecutives?.map(
          (el) => `${el?.firstName} ${el?.lastName}`
        ),
        disabled:
          !!allFieldsDisabled ||
          globalDisabled ||
          !!selectedMainField?.projectExecutive,
      });
    }

    if (formItemName === "assignedTo") {
      Object.assign(field, {
        ...assignedToObject({
          title: "Estimations",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "projectManager") {
      delete field?.options;

      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    if (formItemName === "team") {
      delete field?.options;

      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "client") {
      delete field?.options;

      Object.assign(field, {
        disabled: true,
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
      });
    }

    newFields.push(field);
  }

  return sortFields(newFields);
};

export default estimationRequestFields;
